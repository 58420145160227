import React from 'react';
import { Subscription } from 'src/types/Subscription';
import { SUBSCRIPTION_STATUS } from 'src/constants/subscription';
import { Box } from '@radix-ui/themes';
import { FreePlanDowngrade } from './FreePlanDowngrade';
import { StarterPlanCheckout } from './StarterPlanCheckout';
import { TeamsPlanCheckout } from './TeamsPlanCheckout';
import { SuccessfulPaymentCallbackArgs } from '../types';
import { PLANS } from 'src/constants/plans';
import { hasSubscription } from 'src/utils/hasSubscription';
import { StarterPlanDowngrade } from './StarterPlanDowngrade';
import { TeamsPlanUpgrade } from './TeamsPlanUpgrade';
import { StarterPlanBasicCard } from './StarterPlanBasicCard';
import { TeamsPlanBasicCard } from './TeamsPlanBasicCard';

type CardListProps = {
  selectedWorkspace: string;
  subscription?: Subscription;
  onSuccessfulPayment: SuccessfulPaymentCallbackArgs;
};

export function CardList({
  selectedWorkspace,
  subscription,
  onSuccessfulPayment,
}: CardListProps) {
  if (!subscription) {
    return null;
  }
  if (
    hasSubscription({
      subscription,
      allowedStatuses: [
        SUBSCRIPTION_STATUS.TRIAL,
        SUBSCRIPTION_STATUS.CANCELED,
      ],
    })
  ) {
    return (
      <>
        <Box maxWidth="340px">
          <FreePlanDowngrade
            workspaceId={selectedWorkspace}
            subscription={subscription}
            onSuccessfulPayment={onSuccessfulPayment}
          />
        </Box>
        <Box maxWidth="340px">
          <StarterPlanCheckout
            workspaceId={selectedWorkspace}
            subscription={subscription}
            onSuccessfulPayment={onSuccessfulPayment}
          />
        </Box>
        <Box maxWidth="340px">
          <TeamsPlanCheckout
            workspaceId={selectedWorkspace}
            subscription={subscription}
            onSuccessfulPayment={onSuccessfulPayment}
          />
        </Box>
      </>
    );
  }

  if (
    hasSubscription({
      subscription,
      plan: [PLANS.FREE],
      allowedStatuses: [SUBSCRIPTION_STATUS.ACTIVE],
    })
  ) {
    return (
      <>
        <Box maxWidth="340px">
          <FreePlanDowngrade
            workspaceId={selectedWorkspace}
            subscription={subscription}
            onSuccessfulPayment={onSuccessfulPayment}
          />
        </Box>
        <Box maxWidth="340px">
          <StarterPlanCheckout
            workspaceId={selectedWorkspace}
            subscription={subscription}
            onSuccessfulPayment={onSuccessfulPayment}
          />
        </Box>
        <Box maxWidth="340px">
          <TeamsPlanCheckout
            workspaceId={selectedWorkspace}
            subscription={subscription}
            onSuccessfulPayment={onSuccessfulPayment}
          />
        </Box>
      </>
    );
  }

  if (
    hasSubscription({
      subscription,
      plan: [PLANS.STARTER, PLANS.TEAMS],
      allowedStatuses: [SUBSCRIPTION_STATUS.ACTIVE],
    })
  ) {
    return (
      <>
        <Box maxWidth="340px">
          <FreePlanDowngrade
            workspaceId={selectedWorkspace}
            subscription={subscription}
            onSuccessfulPayment={onSuccessfulPayment}
          />
        </Box>
        <Box maxWidth="340px">
          <StarterPlanDowngrade
            workspaceId={selectedWorkspace}
            subscription={subscription}
            onSuccessfulPayment={onSuccessfulPayment}
          />
        </Box>
        <Box maxWidth="340px">
          <TeamsPlanUpgrade
            workspaceId={selectedWorkspace}
            subscription={subscription}
            onSuccessfulPayment={onSuccessfulPayment}
          />
        </Box>
      </>
    );
  }

  if (
    hasSubscription({
      subscription,
      plan: [PLANS.STARTER],
      allowedStatuses: [
        SUBSCRIPTION_STATUS.CANCEL_REQUESTED,
        SUBSCRIPTION_STATUS.PAST_DUE,
      ],
    })
  ) {
    return (
      <Box maxWidth="340px">
        <StarterPlanBasicCard subscription={subscription} />
      </Box>
    );
  }

  if (
    hasSubscription({
      subscription,
      plan: [PLANS.TEAMS],
      allowedStatuses: [
        SUBSCRIPTION_STATUS.CANCEL_REQUESTED,
        SUBSCRIPTION_STATUS.PAST_DUE,
      ],
    })
  ) {
    return (
      <Box maxWidth="340px">
        <TeamsPlanBasicCard subscription={subscription} />
      </Box>
    );
  }

  return null;
}
