import React from 'react';
import { Button, Flex, IconButton, Popover } from '@radix-ui/themes';
import { Input } from 'src/components/Input';
import { Pencil1Icon } from '@radix-ui/react-icons';

type RenameImageProps = {
  id: string;
  name: string;
  onChange: (props: { id: string; value: string }) => void;
};

export function RenameImage({ id, onChange, name }: RenameImageProps) {
  const [isOpen, setIsOpen] = React.useState(false);
  const [value, setValue] = React.useState(name);

  const handleOpen = (open: boolean) => {
    if (!value) {
      return;
    }
    setIsOpen(open);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };

  const handleSubmit = () => {
    onChange({ id, value });
  };

  return (
    <Popover.Root open={isOpen} onOpenChange={handleOpen}>
      <Popover.Trigger>
        <IconButton variant="ghost" color="gold" title="Rename item">
          <Pencil1Icon />
        </IconButton>
      </Popover.Trigger>
      <Popover.Content width="360px">
        <form onSubmit={handleSubmit}>
          <Input
            required
            value={value}
            onChange={handleChange}
            label="Item name"
            placeholder="Enter item name"
            errorMessage={value ? '' : 'required field'}
          />

          <Flex justify="end" mt="2">
            <Button type="submit" variant="soft" color="plum">
              Save changes
            </Button>
          </Flex>
        </form>
      </Popover.Content>
    </Popover.Root>
  );
}
