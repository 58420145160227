import React from 'react';
import type { ColumnDef, Row } from '@tanstack/react-table';
import { Button, Text } from '@radix-ui/themes';
import { Link as RouterLink } from 'react-router-dom';
import { appRoutes } from 'src/utils/routePaths';
import { FIELD_TYPE } from 'src/constants/components';
import { ResponseItemField } from 'src/types/Item';
import { TreeNode } from 'src/types/Template';
import { ImageCell } from '../components/ImageCell';
import { DataType } from '../index';
import { getItemMap } from './getItemMap';

type GetColumnsArgs = {
  templateId: string;
  tree: TreeNode[];
  instanceId: number;
  itemsLabelsMap: Map<string, Map<number, string>>;
};

export const getColumns = ({
  templateId,
  tree,
  instanceId,
  itemsLabelsMap,
}: GetColumnsArgs) => {
  const columns: ColumnDef<DataType>[] = [];

  // add id column
  columns.push({
    accessorKey: 'id',
    enableHiding: false,
    header: 'ID',
    meta: {
      className: 'items-table__column_sticky',
    },
    cell: ({ row }) => (
      <div>
        <Button asChild color="indigo" variant="ghost">
          <RouterLink
            to={appRoutes.itemEdit({
              instanceId,
              templateId,
              id: row.original.id,
            })}
          >
            {row.getValue('id')}
          </RouterLink>
        </Button>
      </div>
    ),
  });

  // add Item name column
  columns.push({
    accessorKey: 'internalLabel',
    enableHiding: false,
    header: 'Label',
    cell: ({ row }) => (
      <div>
        <Button asChild color="indigo" variant="ghost">
          <RouterLink
            to={appRoutes.itemEdit({
              instanceId,
              templateId,
              id: row.original.id,
            })}
          >
            {row.getValue('internalLabel')}
          </RouterLink>
        </Button>
      </div>
    ),
  });

  tree.forEach((treeNode) => {
    const column = {
      accessorKey: treeNode.id,
      header:
        treeNode.settings.type === FIELD_TYPE.DYNAMIC_GROUP_ROW
          ? ''
          : treeNode.settings.label,
      cell: ({ row }: { row: Row<DataType> }) => {
        const rowValue: { item: ResponseItemField[]; node: TreeNode } =
          row.getValue(treeNode.id);

        if (!rowValue) {
          return null;
        }
        const { node, item } = rowValue;
        const itemMap = getItemMap({ data: item });

        if (node.settings.type === FIELD_TYPE.IMAGE) {
          const fieldValue = itemMap.get(node.id) as string;
          return (
            <ImageCell
              instanceId={instanceId}
              fieldName={node.settings.name}
              fieldValue={fieldValue}
            />
          );
        }

        if (node.settings.type === FIELD_TYPE.DYNAMIC_GROUP) {
          // return <TableList data={node.children} itemMap={itemMap} />;
          return null;
        }

        if (node.settings.type === FIELD_TYPE.GROUP) {
          return null;
        }

        const fieldValue = itemMap.get(node.id);

        if (node.settings.type === FIELD_TYPE.BOOLEAN) {
          return <span>{fieldValue ? 'True' : 'False'}</span>;
        }

        if (node.settings.type === FIELD_TYPE.ITEM_LINK) {
          const itemLabelsValuesMap = itemsLabelsMap.get(
            node.settings.templateId
          );

          return (
            <span>
              {itemLabelsValuesMap?.get(fieldValue as number)}{' '}
              <Text color="gray">[{fieldValue}]</Text>
            </span>
          );
        }

        if (node.settings.type === FIELD_TYPE.ITEM_LINK_LIST) {
          const itemLabelsValuesMap = itemsLabelsMap.get(
            node.settings.templateId
          );

          if (!itemLabelsValuesMap) {
            return null;
          }
          const linkListValues = (fieldValue as number[]) || [];
          return (
            <>
              {linkListValues.map((item, index) => (
                <span key={index}>
                  {itemLabelsValuesMap.get(item)}{' '}
                  <Text color="gray">[{item}]</Text>
                  {index < linkListValues.length - 1 ? ', ' : ''}
                </span>
              ))}
            </>
          );
        }

        return <div>{fieldValue || '-'}</div>;
      },
    };

    columns.push(column);
  });

  return columns;
};
