import type { ApiItemResponse } from 'src/types/Item';
import { TreeNode } from 'src/types/Template';
import { DataType } from '../index';

type GetDataArgs = {
  items: ApiItemResponse[];
  templateTree: TreeNode[];
};
export const getData = ({ items, templateTree }: GetDataArgs) => {
  const tableData: Array<DataType> = [];

  items.forEach((item) => {
    const row: DataType = {
      id: item.id,
      internalLabel: item.header.internal_label,
    } as DataType;
    templateTree.forEach((node: TreeNode) => {
      row[node.id] = { node, item: item.fields };
    });
    tableData.push(row);
  });

  return tableData;
};
