import React from 'react';
import { Box, Card, DataList, Text } from '@radix-ui/themes';
import { ErrorMessage } from 'src/components/ErrorMessage';
import { PLANS } from 'src/constants/plans';
import { useActiveSubscription } from 'src/hooks/useActiveSubscription';
import {
  ErrorsMapType,
  ImageDropZone,
  ImageType,
} from './components/ImageDropZone';
import { getErrorsText } from './utils/getErrorsText';
import { MAX_SIZE_MB } from './utils/maxSize';
import { Callout } from 'src/components/Callout';

type ImageUploadMultipleProps = {
  onChange: (value: ImageType[]) => void;
};

export function ImageUploadMultiple({ onChange }: ImageUploadMultipleProps) {
  const { subscription } = useActiveSubscription({});
  const [errorsMap, setErrorsMap] = React.useState<ErrorsMapType>(new Map());
  const subscriptionType = subscription?.type || PLANS.FREE;
  const maxSizeMB = MAX_SIZE_MB[subscriptionType];

  const handleError = (errorsMap: Map<string, string[]>) => {
    setErrorsMap(errorsMap);
  };

  const errorsMessages = getErrorsText({ maxSizeMB });
  const isInvalid = !!Array.from(errorsMap.entries()).length;

  return (
    <div className="image-upload">
      {isInvalid && (
        <Box mb="6">
          <Callout color="tomato">Some images could not be uploaded</Callout>
          <Card mt="4">
            <DataList.Root>
              {Array.from(errorsMap.entries()).map(
                ([fileName, errors], index) => (
                  <DataList.Item key={index}>
                    <DataList.Label>
                      <Text>{fileName}</Text>
                    </DataList.Label>
                    <DataList.Value>
                      <Box>
                        {errors.map((error, errorIndex) => {
                          const errorText = errorsMessages[error]
                            ? errorsMessages[error]
                            : error;

                          return (
                            <ErrorMessage key={errorIndex}>
                              {errorText}
                            </ErrorMessage>
                          );
                        })}
                      </Box>
                    </DataList.Value>
                  </DataList.Item>
                )
              )}
            </DataList.Root>
          </Card>
        </Box>
      )}

      <div className="image-upload__label">
        Upload multiple images to get started
      </div>

      <ImageDropZone
        multiple={true}
        onChange={onChange}
        onError={handleError}
        maxSizeMB={maxSizeMB}
        invalid={isInvalid}
      />
    </div>
  );
}
