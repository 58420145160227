import { PlanType } from 'src/constants/plans';
import { Subscription } from 'src/types/Subscription';

type HasAccessSubscriptionArgs = {
  plan?: PlanType[];
  allowedStatuses?: string[];
  subscription: Subscription;
};

export function hasSubscription({
  plan,
  allowedStatuses = [],
  subscription,
}: HasAccessSubscriptionArgs) {
  return (
    (!plan || plan.includes(subscription?.type)) &&
    (allowedStatuses.length === 0 ||
      allowedStatuses.includes(subscription?.status))
  );
}
