import React, { Fragment } from 'react';
import { NavLink as RouterNavLink } from 'react-router-dom';
import { Box, Link, Button } from '@radix-ui/themes';
import {
  ArrowLeftIcon,
  ChevronDownIcon,
  ChevronRightIcon,
} from '@radix-ui/react-icons';
import { useLogout } from 'src/hooks/api/useLogout';
import { appRoutes } from 'src/utils/routePaths';
import './Nav__Settings.css';
import { useWorkspacesList } from 'src/hooks/api/useWorkspacesList';
import { Disclosure } from '@headlessui/react';
import { Nav__TextOverflowed } from '../NavTree';
import { WORKSPACES_ROLES } from 'src/constants/workspaceRoles';
import { useUserContext } from 'src/providers/UserProvider';
import { ErrorApi } from 'src/components/ErrorApi';
import { AlertDialog } from 'src/components/AlertDialog';
import { ScrollArea } from 'src/components/ScrollArea';

export function Nav__Settings() {
  const { workspacesRoleMap } = useUserContext();
  const { data: workspacesData } = useWorkspacesList();

  const isWorkspaceSettingsAvailable = (workspaceId: number) => {
    const role = workspacesRoleMap.get(workspaceId);
    return role === WORKSPACES_ROLES.OWNER || role === WORKSPACES_ROLES.ADMIN;
  };

  const isWorkspaceBillingPageAvailable = workspacesData?.workspaces?.some(
    (item) => {
      const role = workspacesRoleMap.get(item.id);
      return role === WORKSPACES_ROLES.OWNER;
    }
  );

  return (
    <ScrollArea mods={{ type: 'secondary' }}>
      <Box pr="3">
        <ul className="nav__settings">
          <li>
            <Button
              className="nav__settings-button"
              asChild
              color="gray"
              variant="ghost"
              size="3"
            >
              <RouterNavLink to="/">
                <ArrowLeftIcon /> Settings
              </RouterNavLink>
            </Button>
          </li>
          {/*<li>*/}
          {/*  <div className="nav__group-headline">Integrations</div>*/}
          {/*</li>*/}

          {workspacesData?.workspaces?.map((workspaceItem) => (
            <Fragment key={workspaceItem.id}>
              {isWorkspaceSettingsAvailable(workspaceItem.id) && (
                <>
                  <li className="nav__group">
                    <div className="nav__group-headline">
                      {workspaceItem.name}
                    </div>
                    <ul>
                      <li>
                        <Link asChild color="gray" className="nav__item-link">
                          <RouterNavLink
                            to={appRoutes.settingsWorkspace({
                              workspaceId: workspaceItem.id,
                            })}
                            end
                          >
                            Overview
                          </RouterNavLink>
                        </Link>
                      </li>

                      <li className="nav__item">
                        <Link asChild color="gray" className="nav__item-link">
                          <RouterNavLink
                            to={appRoutes.settingsWorkspaceMembers({
                              workspaceId: workspaceItem.id,
                            })}
                            end
                          >
                            Workspace members
                          </RouterNavLink>
                        </Link>
                      </li>

                      <li className="nav__item">
                        <Link asChild color="gray" className="nav__item-link">
                          <RouterNavLink
                            to={appRoutes.settingsWorkspaceRoles({
                              workspaceId: workspaceItem.id,
                            })}
                          >
                            Roles for all projects
                          </RouterNavLink>
                        </Link>
                      </li>

                      {!!workspaceItem?.projects?.length && (
                        <li className="nav__item">
                          <Disclosure defaultOpen={true}>
                            {({ open }) => (
                              <>
                                <Disclosure.Button className="nav__item-group">
                                  <>
                                    <Nav__TextOverflowed text="Projects" />

                                    {open ? (
                                      <ChevronDownIcon className="nav__item-arrow-icon" />
                                    ) : (
                                      <ChevronRightIcon className="nav__item-arrow-icon" />
                                    )}
                                  </>
                                </Disclosure.Button>
                                <Disclosure.Panel className="text-gray-500">
                                  <ul>
                                    {workspaceItem?.projects?.map((item) => (
                                      <li className="nav__item" key={item.id}>
                                        <Link
                                          asChild
                                          color="gray"
                                          className="nav__item-link"
                                        >
                                          <RouterNavLink
                                            to={appRoutes.settingsProject({
                                              projectId: item.id,
                                              workspaceId: workspaceItem.id,
                                            })}
                                          >
                                            {item.name}
                                          </RouterNavLink>
                                        </Link>
                                      </li>
                                    ))}
                                  </ul>
                                </Disclosure.Panel>
                              </>
                            )}
                          </Disclosure>
                        </li>
                      )}
                    </ul>
                  </li>
                </>
              )}
            </Fragment>
          ))}

          <li className="nav__group">
            <div className="nav__group-headline">My account</div>
            <ul>
              <li className="nav__item">
                <Link asChild color="gray" className="nav__item-link">
                  <RouterNavLink to={appRoutes.settingsAccount()}>
                    Profile
                  </RouterNavLink>
                </Link>
              </li>
              {isWorkspaceBillingPageAvailable && (
                <li className="nav__item">
                  <Link asChild color="gray" className="nav__item-link">
                    <RouterNavLink to={appRoutes.settingsBilling()}>
                      Billing
                    </RouterNavLink>
                  </Link>
                </li>
              )}
              <li className="nav__item">
                <LogoutLink />
              </li>
            </ul>
          </li>
        </ul>
      </Box>
    </ScrollArea>
  );
}

// move to separate component to avoid nav rerender on route change due to deps on useNavigate inside useLogout
function LogoutLink() {
  const [isAlertOpen, setIsAlertOpen] = React.useState(false);
  const { mutate: logout, error, isMutating } = useLogout();

  return (
    <AlertDialog
      open={isAlertOpen}
      loading={isMutating}
      color="tomato"
      trigger={
        <Link
          color="gray"
          className="nav__item-link"
          role="button"
          onClick={() => setIsAlertOpen(true)}
        >
          Log out
        </Link>
      }
      actionText="Yes, log out"
      cancelText="No, stay in the app"
      onCancel={() => setIsAlertOpen(false)}
      onAction={() => logout()}
    >
      {!!error && (
        <Box mb="4">
          <ErrorApi error={error} />
        </Box>
      )}
      Are you sure you want to log out?
    </AlertDialog>
  );
}
