import type { TreeNode } from 'src/types/Template';
import { walk } from '@nosferatu500/react-sortable-tree';
import { getNodeKey } from 'src/utils/getNodeKey';
import { FilterFieldType } from 'src/types/Filter';
import { FIELD_TYPE } from 'src/constants/components';
import { v4 as uuidv4 } from 'uuid';

export type FieldType = {
  internalId: string;
  fieldId: string;
  label: string;
  labelPath: string;
  type: FilterFieldType;
  templateId: string;
};

type LabelsMapType = Map<string, string>;

type GetFieldsListArgs = {
  tree: TreeNode[];
};

export function getFieldsList({ tree }: GetFieldsListArgs) {
  const fields: FieldType[] = [];
  const labelsMap: LabelsMapType = new Map();

  walk({
    treeData: tree,
    getNodeKey,
    callback: (treeNode: { node: TreeNode; path: string[] }) => {
      const { node, path } = treeNode;
      if (node.settings.type !== FIELD_TYPE.DYNAMIC_GROUP_ROW) {
        labelsMap.set(node.id, node.settings.label);
      }

      const labelPath =
        path.length > 1 ? getLabelFromPath({ path, labelsMap }) : '';

      if (
        node.settings.type === FIELD_TYPE.TEXT ||
        node.settings.type === FIELD_TYPE.INTEGER ||
        node.settings.type === FIELD_TYPE.FLOAT ||
        node.settings.type === FIELD_TYPE.BOOLEAN
      ) {
        fields.push({
          internalId: uuidv4(),
          fieldId: node.id,
          label: node.settings.label,
          labelPath,
          type: node.settings.type,
          templateId: '',
        });
      }

      if (
        node.settings.type === FIELD_TYPE.ITEM_LINK ||
        node.settings.type === FIELD_TYPE.ITEM_LINK_LIST
      ) {
        fields.push({
          internalId: uuidv4(),
          fieldId: node.id,
          label: node.settings.label,
          labelPath,
          type: node.settings.type,
          templateId: node.settings.templateId,
        });
      }
    },
    ignoreCollapsed: false,
  });

  return fields;
}

type GetLabelFromPathArgs = {
  path: string[];
  labelsMap: LabelsMapType;
};

function getLabelFromPath({ path, labelsMap }: GetLabelFromPathArgs) {
  const labels: string[] = [];

  path.forEach((item) => {
    const label = labelsMap.get(item);
    if (label) {
      labels.push(label);
    }
  });

  return labels.join('.');
}
