import React from 'react';
import { Page__Header, Page__Content } from 'src/components/Page';
import { Text, Flex, Box, Badge, Skeleton } from '@radix-ui/themes';
import {
  DashboardIcon,
  DownloadIcon,
  MagicWandIcon,
  MixerVerticalIcon,
  UpdateIcon,
} from '@radix-ui/react-icons';
import { ProjectCreation } from 'src/components/ProjectCreation';
import { useInstanceContext } from 'src/providers/InstanceProvider';
import { useWorkspacesList } from 'src/hooks/api/useWorkspacesList';
import { getProjectByInstanceId } from 'src/utils/getProjectByInstanceId';
import { hasProjects } from 'src/utils/hasProjects';
import { useUserContext } from 'src/providers/UserProvider';
import { canCreateProject } from './utils/canCreateProject';
import { ROLES_LIST, WORKSPACES_ROLES } from 'src/constants/workspaceRoles';
import { Callout } from 'src/components/Callout';
import { ErrorApi } from 'src/components/ErrorApi';
import { PERMISSIONS } from 'src/constants/permissions';
import { appRoutes } from 'src/utils/routePaths';
import { Access } from 'src/components/Access';
import { CardLink } from 'src/components/CardLink';
import { getInstanceById } from 'src/utils/getInstanceById';
import { INSTANCE_BADGE_COLOR } from 'src/constants/instance';
import { PLANS } from 'src/constants/plans';
import { AccessSubscription } from 'src/components/AccessSubscription';
import { SendFeedbackPopup } from 'src/components/Nav/SendFeedbackPopup';
import { Onboarding } from './components/Onboarding';
import { useMenu } from 'src/hooks/api/useMenu';

const DEFAULT_PAGE_TITLE = 'Dashboard';

export default function MainPage() {
  const { instanceId } = useInstanceContext();
  const { data: menuData } = useMenu();
  const { workspacesRoleMap } = useUserContext();
  const {
    data: workspacesData,
    error: workspacesError,
    isLoading: isWorkspacesLoading,
  } = useWorkspacesList();

  const activeProject = getProjectByInstanceId({
    workspaces: workspacesData?.workspaces || [],
    instanceId: instanceId,
  });

  const activeInstanceData = getInstanceById({
    workspaces: workspacesData?.workspaces || [],
    instanceId,
  });

  const rolesList = [...workspacesRoleMap.values()];
  const isMember =
    rolesList.length &&
    rolesList.every((role) => role === WORKSPACES_ROLES.MEMBER);

  if (isWorkspacesLoading) {
    return (
      <>
        <Page__Header title={DEFAULT_PAGE_TITLE} />

        <Page__Content>
          <CardLink
            isLoading={true}
            title="Quick start demo"
            to={appRoutes.settingsInstanceSync()}
            href="https://docs.lootfabriq.io/start-here/demo/"
            rel="noopener noreferrer"
            target="_blank"
            icon={<MagicWandIcon />}
          >
            <Skeleton loading={true}>
              Learn how to create your first template and item. Perfect for new
              users
            </Skeleton>
          </CardLink>
        </Page__Content>
      </>
    );
  }

  if (workspacesError) {
    return (
      <>
        <Page__Header title={DEFAULT_PAGE_TITLE} />

        <Page__Content>
          <ErrorApi error={workspacesError} />
        </Page__Content>
      </>
    );
  }

  const badgeColor = INSTANCE_BADGE_COLOR[activeInstanceData?.env || 'Dev'];
  const hasWorkspaces = !!workspacesData?.workspaces?.length;

  return (
    <>
      <Page__Header
        title={activeProject?.name || DEFAULT_PAGE_TITLE}
        dataTestId="dashboard-header"
        headingSuffix={
          <>
            {canCreateProject({ workspacesRoleMap }) && (
              <AccessSubscription plan={[PLANS.STARTER, PLANS.TEAMS]}>
                <ProjectCreation />
              </AccessSubscription>
            )}
          </>
        }
      >
        <SendFeedbackPopup />
      </Page__Header>

      <Page__Content>
        {!!isMember && !hasProjects(workspacesData?.workspaces) && (
          <Box maxWidth="600px" mb="4">
            <Callout color="tomato">
              <Text>
                You haven&rsquo;t been added to&nbsp;any projects yet. Please
                contact your admin for access.
              </Text>
            </Callout>
          </Box>
        )}

        <Flex gap="4" wrap="wrap">
          <Onboarding
            workspacesData={workspacesData}
            isMember={!!isMember}
            hasProjects={hasProjects(workspacesData?.workspaces)}
            hasWorkspaces={hasWorkspaces}
            hasTemplates={!!menuData && menuData.menu.length > 1}
          />

          {hasWorkspaces && (
            <CardLink
              title="Quick start demo"
              to={appRoutes.settingsInstanceSync()}
              href="https://docs.lootfabriq.io/start-here/demo/"
              rel="noopener noreferrer"
              target="_blank"
              icon={<MagicWandIcon />}
            >
              Learn how to create your first template and item. Perfect for new
              users
            </CardLink>
          )}

          <Access roles={ROLES_LIST} permissionsGroups={[[PERMISSIONS.EXPORT]]}>
            <CardLink
              title="Exported data"
              to={appRoutes.settingsExportedData()}
              icon={<DownloadIcon />}
              badge={
                <Badge color={badgeColor}>
                  {activeInstanceData?.env?.toLowerCase()}
                </Badge>
              }
            >
              View, download, and check the status of your exported JSON data
              files
            </CardLink>
          </Access>

          <Access roles={ROLES_LIST} permissionsGroups={[[PERMISSIONS.READ]]}>
            <CardLink
              title="Navigation setup"
              to={appRoutes.settingsNavigationSetup()}
              icon={<DashboardIcon />}
              badge={
                <Badge color={badgeColor}>
                  {activeInstanceData?.env?.toLowerCase()}
                </Badge>
              }
            >
              Organize your templates for better workflow management in complex
              projects
            </CardLink>
          </Access>

          <Access
            roles={ROLES_LIST}
            permissionsGroups={[
              [PERMISSIONS.INSTANCE_CHANGES],
              [PERMISSIONS.EXPORT],
            ]}
          >
            <AccessSubscription plan={[PLANS.TEAMS]}>
              <Access
                roles={ROLES_LIST}
                permissionsGroups={[[PERMISSIONS.INSTANCE_CHANGES]]}
              >
                <CardLink
                  title="Sync instances"
                  to={appRoutes.settingsInstanceSync()}
                  icon={<UpdateIcon />}
                  badge={
                    <Badge color={badgeColor}>
                      {activeInstanceData?.env?.toLowerCase()}
                    </Badge>
                  }
                >
                  Transfer data between development, staging, and production
                  environments
                </CardLink>
              </Access>
            </AccessSubscription>

            <Access
              roles={ROLES_LIST}
              permissionsGroups={[[PERMISSIONS.EXPORT]]}
            >
              <CardLink
                title="Export settings"
                to={appRoutes.settingsExport()}
                icon={<MixerVerticalIcon />}
              >
                Generate a token to securely download your JSON data via API
                access
              </CardLink>
            </Access>
          </Access>
        </Flex>
      </Page__Content>
    </>
  );
}
