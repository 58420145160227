import React from 'react';
import b, { type Mods, type Mix } from 'bem-react-helper';
import { Box, Card, Flex, Text, Button, Skeleton } from '@radix-ui/themes';
import { CheckCircledIcon } from '@radix-ui/react-icons';
import './PlanCard.css';
import { PlanCardBadge } from './PlanCardBadge';
import { Subscription } from 'src/types/Subscription';
import { PlanType } from 'src/constants/plans';
import { isPlanSelected } from '../utils/isPlanSelected';
import { isPlanDisabled } from '../utils/isPlanDisabled';
import { getPlanCardButtonText } from '../utils/getPlanCardButtonText';
import { getSubscriptionState } from '../utils/getSubscriptionState';

type PlanCardProps = {
  features: string[];
  featuresDescription?: string;
  name: string;
  description: string;
  price: string | React.ReactNode;
  onPlanSelect?: () => void;
  mods?: Mods;
  mix?: Mix;
  subscription?: Subscription;
  plan: PlanType;
  isLoading?: boolean;
  hideTaxInfo?: boolean;
  hideProjectPrice?: boolean;
};

export function PlanCard({
  features,
  featuresDescription,
  name,
  description,
  price,
  onPlanSelect,
  subscription,
  plan,
  isLoading,
  hideTaxInfo,
  hideProjectPrice,
  ...rest
}: PlanCardProps) {
  const activePlan = subscription?.type;
  const subscriptionState = getSubscriptionState({
    subscription: subscription,
  });

  const buttonText = getPlanCardButtonText({
    subscriptionState,
    activePlan,
    plan,
  });

  return (
    <Card
      className={b('billing__plan-card', rest, {
        selected: isPlanSelected({ subscription, plan }),
      })}
      variant="surface"
      size="3"
      style={{ height: '100%' }}
    >
      <Flex direction="column" justify="between" height="100%">
        <div>
          <Flex mb="2" justify="between">
            <Text as="div" size="4" weight="bold">
              {name}
            </Text>

            <PlanCardBadge subscription={subscription} plan={plan} />
          </Flex>
          <Text as="div" color="gray" size="3">
            {description}
          </Text>

          <Box my="3">
            <Flex gap="2" align="center">
              <Text as="div" size="7" weight="bold">
                {price}
              </Text>
              {!hideTaxInfo && (
                <Text size="2" color="gray">
                  / month, per member + tax
                </Text>
              )}
            </Flex>
          </Box>

          <Box mb="2">
            <Text size="2" color="gray">
              {featuresDescription}
            </Text>
          </Box>

          {features.map((item, index) => (
            <Flex gap="2" key={index} mb="1">
              <Box flexShrink="0">
                <CheckCircledIcon />
              </Box>
              <Text size="2" color="gray">
                {item}
              </Text>
            </Flex>
          ))}
        </div>

        <div>
          {!hideProjectPrice && (
            <Flex mt="4" align="center" justify="between">
              <Text color="gray" size="2">
                Each additional project
              </Text>
              <Text color="gray" size="2">
                +$4/month
              </Text>
            </Flex>
          )}

          <Flex align="center" justify="center" mt="4">
            <Skeleton loading={!!isLoading}>
              <Button
                style={{ width: '100%' }}
                color="plum"
                variant="soft"
                size="3"
                onClick={onPlanSelect}
                disabled={isPlanDisabled({ subscription, plan })}
              >
                {buttonText}
              </Button>
            </Skeleton>
          </Flex>
        </div>
      </Flex>
    </Card>
  );
}
