import React from 'react';
import { Cross1Icon } from '@radix-ui/react-icons';
import { IconButton, Text, Box } from '@radix-ui/themes';
import { Callout as RadixCallout } from '@radix-ui/themes';
import { toast } from 'sonner';

const variants = {
  info: 'gold',
  error: 'tomato',
  success: 'grass',
} as const;

export type ToastCardProps = {
  children: React.ReactNode;
  onClose: () => void;
  variant?: 'info' | 'error' | 'success';
};

export function ToastCard({
  children,
  onClose,
  variant = 'success',
}: ToastCardProps) {
  return (
    <span className="toast">
      <RadixCallout.Root
        className="toast__root"
        color={variants[variant]}
        size="1"
        variant="soft"
        role="alert"
      >
        <Text as="div" size="2">
          <div className="toast__body">
            {children}{' '}
            {onClose && (
              <IconButton variant="ghost" size="1" onClick={onClose}>
                <Cross1Icon />
              </IconButton>
            )}
          </div>
        </Text>
      </RadixCallout.Root>
    </span>
  );
}

type ToastProps = {
  text: React.ReactNode;
  variant?: 'info' | 'error' | 'success';
  eventId?: string;
  title?: string;
};

export function Toast({ text, variant, eventId, title }: ToastProps) {
  return toast.custom((t) => (
    <ToastCard variant={variant} onClose={() => toast.dismiss(t)}>
      <div>
        {title && (
          <Box mb="2">
            <Text weight="medium">{title}</Text>
          </Box>
        )}
        {text}
        {!text && variant === 'error' && (
          <Text>
            Something went wrong.
            <br /> Please try again later
          </Text>
        )}

        {eventId && (
          <>
            <br />
            <Text wrap="nowrap">{eventId}</Text>
          </>
        )}
      </div>
    </ToastCard>
  ));
}
