import React from 'react';
import { Button, Flex, Grid, Heading, Popover, Text } from '@radix-ui/themes';
import {
  Controller,
  SubmitHandler,
  useForm,
  FormProvider,
} from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { SelectField } from 'src/components/SelectField';
import {
  COMPONENTS_FILTER_OPERATORS,
  CUSTOM_FILTER_OPTIONS,
} from 'src/constants/filter';
import { validationSchema } from './utils/validationSchema';
import { FormData } from './types';
import { ValueField } from './components/ValueField';
import { useItemsTableContext } from '../../providers/ItemsTableProvider';
import { getCustomFieldFilterById } from '../../utils/customFieldFilter';
import _ from 'lodash';

type FilterPopupProps = {
  trigger: React.ReactNode;
  onSubmit: (data: FormData) => void;
  defaultValues?: FormData;
  isEdit?: boolean;
};

export function FilterPopup({
  trigger,
  onSubmit,
  defaultValues,
  isEdit,
}: FilterPopupProps) {
  const { templateFields: fields } = useItemsTableContext();
  const [isPopoverOpen, setIsPopoverOpen] = React.useState(false);

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    context: { fields },
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    defaultValues,
  });

  const {
    formState: { errors },
    control,
    handleSubmit,
    watch,
    setValue,
  } = methods;

  const selectedFieldId = watch('fieldId');

  React.useEffect(() => {
    setValue('conditionId', '');
  }, [selectedFieldId, setValue]);

  const handleInstanceUpdate: SubmitHandler<FormData> = async (
    data: FormData,
    e?: React.BaseSyntheticEvent
  ) => {
    e?.preventDefault();
    onSubmit(data);
    setIsPopoverOpen(false);
  };

  const orderedFieldsOptions = _.sortBy(fields, 'label');

  const fieldsOptions = orderedFieldsOptions.map((item) => ({
    id: item.fieldId,
    label: (
      <>
        {item.label}{' '}
        {!!item.labelPath && <Text color="gray">[{item.labelPath}]</Text>}
      </>
    ),
  }));

  const isCustomField = !!getCustomFieldFilterById({
    fieldId: selectedFieldId,
  });
  const selectedField = isCustomField
    ? { type: selectedFieldId, templateId: '' }
    : fields.find((item) => item.fieldId === selectedFieldId);
  const conditionOptions = selectedField
    ? COMPONENTS_FILTER_OPERATORS[selectedField.type]
    : [];

  return (
    <FormProvider {...methods}>
      <Popover.Root open={isPopoverOpen} onOpenChange={setIsPopoverOpen}>
        <Popover.Trigger>{trigger}</Popover.Trigger>
        <Popover.Content width="500px">
          <Heading mb="3" size="3">
            {isEdit ? 'Edit filter' : 'Add filter'}
          </Heading>
          <form onSubmit={handleSubmit(handleInstanceUpdate)}>
            <Controller
              control={control}
              name="fieldId"
              render={({ field: { value, onChange } }) => {
                return (
                  <SelectField
                    errorMessage={errors.fieldId?.message}
                    options={[...CUSTOM_FILTER_OPTIONS, ...fieldsOptions]}
                    value={value}
                    onChange={onChange}
                    label="Field"
                    placeholder="Select field"
                  />
                );
              }}
            />

            {!!selectedField && (
              <Grid gap="4" mt="3" align="start" columns="1fr 1fr">
                <Controller
                  control={control}
                  name="conditionId"
                  key={selectedFieldId} // rerender when selectedFieldId changes
                  render={({ field: { value, onChange } }) => {
                    return (
                      <SelectField
                        disabled={!selectedFieldId}
                        errorMessage={errors.conditionId?.message}
                        options={conditionOptions}
                        value={value}
                        onChange={onChange}
                        label="Condition"
                        placeholder="Select condition"
                      />
                    );
                  }}
                />

                <ValueField
                  fieldType={selectedField.type}
                  templateId={selectedField.templateId}
                />
              </Grid>
            )}

            <Flex justify="end" mt="4">
              <Button variant="soft" type="submit" color="plum">
                {isEdit ? 'Update filter' : 'Apply filter'}
              </Button>
            </Flex>
          </form>
        </Popover.Content>
      </Popover.Root>
    </FormProvider>
  );
}
