import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { fetcher } from 'src/utils/fetcher';
import { ApiItemListResponse } from 'src/types/Item';
import { useInstanceContext } from 'src/providers/InstanceProvider';

const ITEMS_LIST_KEY = 'useItemsList';

type FilterItem = {
  template_field_id: string;
  op: number;
  value: string;
};

type GetItemsListArgs = {
  instanceId: number;
  templateId: string;
  filters: FilterItem[];
};

const getItemsList = (arg: GetItemsListArgs): Promise<ApiItemListResponse> => {
  const { instanceId, templateId, ...rest } = arg;
  return fetcher({
    url: `/editor/${instanceId}/items/${templateId}`,
    options: {
      method: 'POST',
      body: JSON.stringify(rest),
    },
  });
};

type UseItemsListArgs = {
  templateId: string | undefined;
  filters: FilterItem[];
  options?: Omit<
    UseQueryOptions<ApiItemListResponse | null>,
    'queryKey' | 'queryFn'
  >;
};

export function useItemsList({
  templateId,
  filters,
  options,
}: UseItemsListArgs) {
  const { instanceId } = useInstanceContext();
  const { data, error, isPending, isError, refetch, isFetching } = useQuery({
    queryKey: [ITEMS_LIST_KEY, templateId, instanceId],
    queryFn: () =>
      templateId
        ? getItemsList({ templateId, instanceId, filters })
        : Promise.resolve(null),
    enabled: !!templateId && instanceId !== -1,
    meta: {
      name: 'Items list',
    },
    ...options,
  });

  return {
    data,
    isLoading: isPending,
    isFetching,
    error: isError ? error : null,
    refetch,
  };
}
