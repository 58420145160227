import React from 'react';
import { Box, Button } from '@radix-ui/themes';
import { ErrorApi } from 'src/components/ErrorApi';
import { AlertDialog } from 'src/components/AlertDialog';
import { Toast } from 'src/components/Toast';
import { useSubscriptionCancel } from 'src/hooks/api/useSubscriptionCancel';
import { Subscription } from 'src/types/Subscription';
import { SuccessfulPaymentCallbackArgs } from '../types';
import { SUBSCRIPTION_STATUS } from 'src/constants/subscription';

type SubscriptionCancellationProps = {
  workspaceId: string;
  onSuccessfulPayment: SuccessfulPaymentCallbackArgs;
  subscription: Subscription | undefined;
};

export function SubscriptionCancellation({
  workspaceId,
  onSuccessfulPayment,
  subscription,
}: SubscriptionCancellationProps) {
  const [isAlertOpen, setAlertOpen] = React.useState(false);
  const {
    mutate: cancelSubscription,
    error: cancelSubscriptionError,
    isMutating: isSubscriptionCancelling,
  } = useSubscriptionCancel({
    onSuccess: () => {
      onSuccessfulPayment({
        plan: subscription?.type,
        status: SUBSCRIPTION_STATUS.CANCEL_REQUESTED,
      });
      setAlertOpen(false);
      Toast({
        variant: 'success',
        text: 'Your subscription has been canceled. Access will continue until the next billing date',
      });
    },
  });

  return (
    <AlertDialog
      title="Cancel subscription"
      onAction={() => cancelSubscription({ workspaceId })}
      onCancel={() => setAlertOpen(false)}
      open={isAlertOpen}
      color="tomato"
      actionText="Yes, cancel subscription"
      cancelText="No, keep subscription"
      loading={isSubscriptionCancelling}
      trigger={
        <Button
          color="tomato"
          variant="soft"
          onClick={() => setAlertOpen(true)}
          loading={isSubscriptionCancelling}
        >
          Cancel subscription
        </Button>
      }
    >
      {!!cancelSubscriptionError && (
        <Box mb="4">
          <ErrorApi error={cancelSubscriptionError} />
        </Box>
      )}
      You're about to cancel your subscription.
      <Box mt="2">
        Your access will continue until the next billing date. After that, you
        will only be able to view your data, delete projects, and delete
        members. Your data will be stored for six months from the end of your
        subscription.
      </Box>
      <Box mt="2">Are you sure you want to proceed?</Box>
    </AlertDialog>
  );
}
