import { CUSTOM_FILTER_OPTIONS } from 'src/constants/filter';

type CustomFieldFilterArgs = {
  fieldId: string;
};

export const getCustomFieldFilterById = ({
  fieldId,
}: CustomFieldFilterArgs) => {
  return CUSTOM_FILTER_OPTIONS.find((option) => fieldId === option.id);
};
