import React from 'react';
import { Callout } from 'src/components/Callout';
import type { ApiError } from 'src/types/Api';
import { ERROR_STATUS_CODE } from 'src/constants/errorStatusCode';
import { Heading } from '@radix-ui/themes';

type ErrorApiProps = {
  error: ApiError;
  title?: string;
};

export function ErrorApi({ error, title }: ErrorApiProps) {
  const statusCode = error?.statusCode;
  const statusCodeMessage = statusCode ? ERROR_STATUS_CODE[statusCode] : '';
  const message = statusCodeMessage || error?.error || error?.message;

  if (!message) {
    return null;
  }

  return (
    <Callout color="tomato">
      {!!title && (
        <Heading size="2" mb="2">
          {title}
        </Heading>
      )}
      {message}
    </Callout>
  );
}
