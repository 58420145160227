import { CardDnD } from 'src/components/CardDnD';
import React from 'react';
import { DragSource, ConnectDragSource, DragSourceCollector } from 'react-dnd';
import { fields } from 'src/fields';
import { Grid } from '@radix-ui/themes';
import { COMPONENTS_HEADLINES, FIELD_TYPE } from 'src/constants/components';
import { TreeNode as TreeNodeType } from 'src/types/Template';

export const DRAGGABLE_CARD_NODE_TYPE = 'card-component';

const COMPONENTS_TYPES = [
  {
    title: COMPONENTS_HEADLINES[FIELD_TYPE.TEXT].title,
    settings: fields[FIELD_TYPE.TEXT].getDefaultValues(),
  },
  {
    title: COMPONENTS_HEADLINES[FIELD_TYPE.INTEGER].title,
    settings: fields[FIELD_TYPE.INTEGER].getDefaultValues(),
  },
  {
    title: COMPONENTS_HEADLINES[FIELD_TYPE.FLOAT].title,
    settings: fields[FIELD_TYPE.FLOAT].getDefaultValues(),
  },
  {
    title: COMPONENTS_HEADLINES[FIELD_TYPE.BOOLEAN].title,
    settings: fields[FIELD_TYPE.BOOLEAN].getDefaultValues(),
  },
  {
    title: COMPONENTS_HEADLINES[FIELD_TYPE.ITEM_LINK].title,
    settings: fields[FIELD_TYPE.ITEM_LINK].getDefaultValues(),
  },
  {
    title: COMPONENTS_HEADLINES[FIELD_TYPE.ITEM_LINK_LIST].title,
    settings: fields[FIELD_TYPE.ITEM_LINK_LIST].getDefaultValues(),
  },
  {
    title: COMPONENTS_HEADLINES[FIELD_TYPE.GROUP].title,
    settings: fields[FIELD_TYPE.GROUP].getDefaultValues(),
  },
  {
    title: COMPONENTS_HEADLINES[FIELD_TYPE.DYNAMIC_GROUP].title,
    settings: fields[FIELD_TYPE.DYNAMIC_GROUP].getDefaultValues(),
  },
];

const getVirtuosoList = () =>
  document.querySelector('[data-testid="virtuoso-item-list"]');

const externalNodeSpec = {
  // This needs to return an object with a property `node` in it.
  // Object rest spread is recommended to avoid side effects of
  // referencing the same object in different trees.
  beginDrag: (componentProps: { node: TreeNodeType }) => {
    const virtuosoList = getVirtuosoList();

    if (virtuosoList) {
      virtuosoList.classList.add('template-tree__droppable');
    }

    return {
      node: { ...componentProps.node },
    };
  },
  endDrag: () => {
    const virtuosoList = getVirtuosoList();
    if (virtuosoList) {
      virtuosoList.classList.remove('template-tree__droppable');
    }
  },
};

const externalNodeCollect: DragSourceCollector<
  object,
  { connectDragSource: ConnectDragSource; node: TreeNodeType }
> = (connect) => ({
  connectDragSource: connect.dragSource(),
});

type ExternalNodeProps = {
  connectDragSource: ConnectDragSource;
  node: TreeNodeType;
};

class externalNodeBaseComponent extends React.Component<ExternalNodeProps> {
  render() {
    const { connectDragSource, node } = this.props;

    return connectDragSource(
      <div
        className="p-template-component-list__item"
        data-testid={`${node.settings.type}-dnd-field`}
      >
        <CardDnD>{node.title}</CardDnD>
      </div>,
      { dropEffect: 'copy' }
    );
  }
}

export const DraggableCard = DragSource(
  DRAGGABLE_CARD_NODE_TYPE,
  externalNodeSpec,
  externalNodeCollect
)(externalNodeBaseComponent);

export function ComponentsList() {
  return (
    <Grid gap="3" asChild>
      <ul className="p-template-component-list">
        {COMPONENTS_TYPES.map((item, index) => (
          <li key={index}>
            <DraggableCard
              node={{
                title: item.title,
                settings: item.settings,
              }}
            />
          </li>
        ))}
      </ul>
    </Grid>
  );
}
