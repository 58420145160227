import React from 'react';
import { ApiItemListResponse } from 'src/types/Item';
import { extractItemLinkAndItemLinkListValues } from '../utils/extractItemLinkAndItemLinkListValues';
import { useItemsLabelsByIds } from 'src/hooks/api/useItemsLabelsByIds';

export type ItemsLabelsMapType = Map<string, Map<number, string>>;

type UseItemsLabelsArgs = {
  itemsList?: ApiItemListResponse | null;
  templateItemLinksMap: Map<string, string>;
};
export function useItemsLabels({
  itemsList,
  templateItemLinksMap,
}: UseItemsLabelsArgs) {
  const { mutateAsync: getItemsLabelsByIds, error: itemsLabelsByIdsError } =
    useItemsLabelsByIds();
  const [itemsLabelsMap, setItemsLabelsMap] =
    React.useState<ItemsLabelsMapType>(new Map());

  React.useEffect(() => {
    const items = itemsList?.items;
    if (!items) {
      return;
    }
    setItemsLabels(itemsList);

    async function setItemsLabels(itemsList: ApiItemListResponse) {
      const itemLinkAndItemLinkListValues =
        extractItemLinkAndItemLinkListValues({
          itemsList: itemsList,
          templateFieldsMap: templateItemLinksMap,
        });

      if (!itemLinkAndItemLinkListValues.size) {
        return;
      }
      const newItemsLabelsMap = new Map();

      const promises = Array.from(itemLinkAndItemLinkListValues.entries()).map(
        async ([templateId, idsSet]) => {
          const ids = Array.from(idsSet);
          const response = await getItemsLabelsByIds({
            templateId,
            ids,
          });

          const labelsMap = new Map<number, string>();
          response.items?.forEach((item) => {
            labelsMap.set(item.id, item.internal_label);
          });

          newItemsLabelsMap.set(templateId, labelsMap);
        }
      );

      await Promise.all(promises);
      setItemsLabelsMap(newItemsLabelsMap);
    }
  }, [getItemsLabelsByIds, itemsList, templateItemLinksMap]);

  return { itemsLabelsMap, error: itemsLabelsByIdsError };
}
