import React from 'react';
import { Page__Content, Page__Header } from 'src/components/Page';
import { appRoutes } from 'src/utils/routePaths';
import { useInstanceContext } from 'src/providers/InstanceProvider';
import { ImageUploadMultiple } from 'src/components/ImageUpload';
import { Box, Button, Flex, Heading, Progress, Text } from '@radix-ui/themes';
import { ImagesListTable } from './components/ImagesListTable';
import { v4 as uuidv4 } from 'uuid';
import { useCreateItem } from 'src/hooks/api/useCreateItem';
import type { ItemField } from 'src/types/Item';
import { FIELD_TYPE } from 'src/constants/components';
import { ApiError } from 'src/types/Api';
import { AlertDialog } from 'src/components/AlertDialog';
import { Toast } from 'src/components/Toast';
import {
  TEMPLATE_IMAGE_FIELD_ID,
  TEMPLATE_IMAGE_ID,
} from 'src/constants/template';
import { useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';

type ImageType = {
  src: string;
  name: string;
  id: string;
};

const PAGE_TITLE = 'Upload and create images in bulk';

export function ItemImageUpload() {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [isAlertOpen, setIsAlertOpen] = React.useState(false);
  const [errors, setErrors] = React.useState<Map<string, ApiError>>(new Map());
  const [uploadProgress, setUploadProgress] = React.useState(0);
  const { mutateAsync: createItem, isMutating: isItemCreating } =
    useCreateItem();
  const { instanceId } = useInstanceContext();
  const [uploadedImages, setUploadedImages] = React.useState<ImageType[]>([]);
  const [isMutating, setIsMutating] = React.useState(false);

  const handleImageUpload = (images: Omit<ImageType, 'id'>[]) => {
    const imagesList = images.map((item) => {
      return {
        ...item,
        id: uuidv4(),
      };
    });

    setUploadedImages([...uploadedImages, ...imagesList]);
  };

  const handleRename = React.useCallback(
    ({ id, value }: { id: string; value: string }) => {
      const updatedImages = [...uploadedImages];
      updatedImages.forEach((image) => {
        if (image.id === id) {
          image.name = value;
        }
      });

      setUploadedImages(updatedImages);
    },
    [uploadedImages]
  );

  const handleRemove = React.useCallback(
    (id: string) => {
      const updatedImages = uploadedImages.filter((image) => image.id !== id);
      setUploadedImages(updatedImages);
    },
    [uploadedImages]
  );

  const getImageItemData = ({
    name,
    base64,
  }: {
    name: string;
    base64: string;
  }) => {
    const imageData = base64.replace(/^data:image\/[a-zA-Z]+;base64,/, '');
    const imageField = {
      id: uuidv4(),
      template_field_id: TEMPLATE_IMAGE_FIELD_ID,
      value: imageData,
      template_field_type: FIELD_TYPE.IMAGE,
      template_group_id: '',
      template_group_path: null,
      status: 1,
    } as ItemField;

    const imageItem = {
      template_id: TEMPLATE_IMAGE_ID,
      internal_label: name,
      fields: [imageField],
    };

    return imageItem;
  };

  const createItems = async () => {
    const CONCURRENT_LIMIT = 3;
    const erroredImages: ImageType[] = [];
    let remainingImages = [...uploadedImages];
    let totalRequests = 0;
    const totalImages = uploadedImages.length;

    setUploadProgress(0);
    setErrors(new Map());
    setIsMutating(true);

    const processUploads = async (image: ImageType) => {
      const args = getImageItemData({
        name: image.name,
        base64: image.src,
      });

      try {
        await createItem(args);
      } catch (error) {
        erroredImages.push(image);
        setErrors((prevErrors) => {
          const updatedErrors = new Map(prevErrors);
          // @ts-expect-error: will fix it later
          updatedErrors.set(image.id, error);

          return updatedErrors;
        });
      } finally {
        totalRequests++;
        setUploadProgress((prev) => prev + 1);
      }
    };

    while (totalRequests < totalImages) {
      if (
        queryClient.isMutating() < CONCURRENT_LIMIT &&
        remainingImages.length > 0
      ) {
        const [image, ...rest] = remainingImages;
        remainingImages = rest;
        processUploads(image);
      }

      // add short delay to allow react-query to update mutation counts
      await new Promise((resolve) =>
        setTimeout(resolve, queryClient.isMutating() > 0 ? 50 : 0)
      );
    }

    setIsMutating(false);

    if (erroredImages.length > 0) {
      setUploadedImages(erroredImages);
      setIsAlertOpen(false);
    } else {
      setUploadedImages([]);
      navigate(appRoutes.itemImages({ instanceId }));
      Toast({
        variant: 'success',
        text: 'Images were successfully created',
      });
    }
  };

  const openDialog = () => {
    if (!uploadedImages.length) {
      Toast({
        variant: 'info',
        text: 'No images selected. Please upload images before creating items.',
      });

      return;
    }

    setIsAlertOpen(true);
  };

  const showProgressBar = uploadProgress < uploadedImages.length && isMutating;

  return (
    <>
      <Page__Header
        title={PAGE_TITLE}
        backButtonUrl={appRoutes.itemImages({ instanceId })}
      >
        <AlertDialog
          title="Items creation"
          onAction={createItems}
          onCancel={() => setIsAlertOpen(false)}
          open={isAlertOpen}
          actionText="Yes, create items"
          cancelText="No, go back"
          loading={isItemCreating}
          trigger={
            <Button
              onClick={openDialog}
              type="button"
              variant="soft"
              color="plum"
              loading={isItemCreating}
            >
              Create images
            </Button>
          }
        >
          {showProgressBar && (
            <Box mb="4">
              <Heading size="3" mb="2">
                Processing... {uploadProgress} of {uploadedImages.length} images
                processed.
              </Heading>
              <Progress
                color="grass"
                variant="soft"
                value={Math.round(
                  (uploadProgress / uploadedImages.length) * 100
                )}
              />
            </Box>
          )}

          {!showProgressBar && (
            <> Are you sure you want to create items for all reviewed images?</>
          )}
        </AlertDialog>
      </Page__Header>

      <Page__Content>
        <Box pr="4">
          <Flex mb="6" maxWidth="900px" justify="center" m="auto">
            <Box width="100%">
              <ImageUploadMultiple onChange={handleImageUpload} />
            </Box>
          </Flex>

          {!!uploadedImages.length && (
            <>
              <Heading size="3" mb="2">
                Review and finalize your images
              </Heading>
              <Text>
                Once you've reviewed them, click 'Create images' to complete the
                process.
              </Text>
            </>
          )}

          {!!uploadedImages.length && (
            <Box mt="4">
              <ImagesListTable
                data={uploadedImages}
                onRename={handleRename}
                onRemove={handleRemove}
                errorsMap={errors}
              />
            </Box>
          )}
        </Box>
      </Page__Content>
    </>
  );
}
