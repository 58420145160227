import React from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { Box, DropdownMenu, IconButton, Strong } from '@radix-ui/themes';
import { DotsHorizontalIcon } from '@radix-ui/react-icons';
import { appRoutes } from 'src/utils/routePaths';
import { Link as RouterLink } from 'react-router-dom';
import { useProjectRemoveMember } from 'src/hooks/api/useProjectRemoveMember';
import { Toast } from 'src/components/Toast';
import { GET_PROJECT_MEMBERS_KEY } from 'src/hooks/api/useProjectMembers';
import { ErrorApi } from 'src/components/ErrorApi';
import { AlertDialog } from 'src/components/AlertDialog';

type TableDropdownProps = {
  workspaceId: number;
  memberId: number;
  projectId: number;
  memberName: string;
};

export function TableDropdown({
  workspaceId,
  memberId,
  projectId,
  memberName,
}: TableDropdownProps) {
  const [isDialogOpen, setIsDialogOpen] = React.useState(false);
  const queryClient = useQueryClient();
  const {
    mutate: removeMember,
    isMutating: isMemberRemoving,
    error: removeMemberError,
  } = useProjectRemoveMember({
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [GET_PROJECT_MEMBERS_KEY],
      });
      Toast({
        text: 'Member successfully removed',
        variant: 'success',
      });
      setIsDialogOpen(false);
    },
  });

  return (
    <>
      <DropdownMenu.Root>
        <DropdownMenu.Trigger>
          <IconButton variant="ghost" title="Edit member">
            <DotsHorizontalIcon />
          </IconButton>
        </DropdownMenu.Trigger>
        <DropdownMenu.Content variant="soft">
          <DropdownMenu.Item asChild>
            <RouterLink
              to={appRoutes.settingsProjectEditMember({
                projectId,
                workspaceId,
                memberId,
              })}
            >
              Update role
            </RouterLink>
          </DropdownMenu.Item>
          <DropdownMenu.Item color="red" onClick={() => setIsDialogOpen(true)}>
            Remove member
          </DropdownMenu.Item>
        </DropdownMenu.Content>
      </DropdownMenu.Root>

      <AlertDialog
        title="Remove member from workspace"
        color="tomato"
        open={isDialogOpen}
        actionText="Yes, remove member"
        cancelText="No, keep member"
        onCancel={() => setIsDialogOpen(false)}
        onAction={() => removeMember({ projectId, memberId })}
        loading={isMemberRemoving}
      >
        {!!removeMemberError && (
          <Box mb="4">
            <ErrorApi error={removeMemberError} />
          </Box>
        )}
        Are you sure you want to remove <Strong>{memberName}</Strong> from
        workspace?
        <Box mt="2">
          This will revoke their access to all projects within the workspace and
          cannot be undone.
        </Box>
      </AlertDialog>
    </>
  );
}
