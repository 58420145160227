import { ObjectValues } from 'src/types/Common';

export const PLANS = {
  FREE: 'Free',
  STARTER: 'Starter',
  TEAMS: 'Teams',
} as const;

export type PlanType = ObjectValues<typeof PLANS>;
export type PlanTypePaid = ObjectValues<Omit<typeof PLANS, 'FREE'>>;

export const PRICES = {
  [PLANS.STARTER]: 16,
  [PLANS.TEAMS]: 29,
  PROJECT: 4,
};

export const FREE_FEATURES = [
  'Get started with 1 project',
  'Create templates and up to 800 items',
  'Download JSON from the app or via API',
  'Upload images up to 1 MB',
];

export const TEAMS_FEATURES = [
  'Staging, and production instances',
  'Easily sync data between instances',
  'Custom roles with specific permissions',
  'Upload images up to 4 MB',
];

export const STARTER_FEATURES = [
  'No item limits',
  'Invite members and assign admin roles',
  'Includes 3 projects',
  'Upload images up to 2 MB',
];
