import React from 'react';
import * as Sentry from '@sentry/react';
import { getTokenData } from 'src/utils/token';

type SendFeedbackArgs = {
  message: string;
  email?: string;
  name?: string;
};

type UseSendFeedbackArgs = {
  onSuccess: () => void;
};

export function useSendFeedback({ onSuccess }: UseSendFeedbackArgs) {
  const [isLoading, setIsLoading] = React.useState(false);
  const [error, setError] = React.useState('');

  const sendFeedback = ({ message, email, name }: SendFeedbackArgs) => {
    setIsLoading(true);
    setError('');
    const tokenData = getTokenData() as { uid?: number };

    Sentry.sendFeedback({
      message,
      email,
      name,
      tags: { uid: tokenData?.uid?.toString() || '' },
    })
      .then(() => {
        onSuccess();
      })
      .catch((reason: unknown) => {
        if (typeof reason === 'string') {
          setError(reason);
        } else {
          setError(JSON.stringify(reason));
        }
      })
      .finally(() => setIsLoading(false));
  };

  return {
    sendFeedback,
    isLoading,
    error,
  };
}
