import React from 'react';
import { fillItemLinkAndItemLinkListTemplateIdMap } from '../utils/fillItemLinkAndItemLinkListTemplateIdMap';
import { ApiTemplateResponse } from 'src/types/Template';

type UseTemplateItemLinksArgs = {
  template?: ApiTemplateResponse;
};
export function useTemplateItemLinks({ template }: UseTemplateItemLinksArgs) {
  const [templateItemLinksMap, setTemplateItemLinksMap] = React.useState<
    Map<string, string>
  >(new Map());

  React.useEffect(() => {
    if (!template) {
      return;
    }

    setTemplateItemLinksMap(fillItemLinkAndItemLinkListTemplateIdMap(template));
  }, [template]);

  return { templateItemLinksMap };
}
