import { FIELD_TYPE } from './components';
import { ObjectValues } from 'src/types/Common';

export const FILTER_OPERATOR = {
  LESS: '1',
  LESS_OR_EQUAL: '2',
  MORE: '3',
  MORE_OR_EQUAL: '4',
  EQUAL: '5',
  CONTAINS: '6',
};

export const CUSTOM_FIELDS_FILTER = {
  ID: 'id',
  LABEL: 'label',
};

export const CUSTOM_FILTER_OPTIONS = [
  {
    id: CUSTOM_FIELDS_FILTER.ID,
    label: 'ID',
  },
  {
    id: CUSTOM_FIELDS_FILTER.LABEL,
    label: 'Label',
  },
];

export type FilterOperatorsType = ObjectValues<typeof FILTER_OPERATOR>;

export const FILTER_OPERATOR_LABEL = {
  [FILTER_OPERATOR.LESS]: 'less than',
  [FILTER_OPERATOR.LESS_OR_EQUAL]: 'less than or equal',
  [FILTER_OPERATOR.MORE]: 'more than',
  [FILTER_OPERATOR.MORE_OR_EQUAL]: 'more than or equal',
  [FILTER_OPERATOR.EQUAL]: 'equal',
  [FILTER_OPERATOR.CONTAINS]: 'contains',
};

const TEXT_FIELD_OPERATORS = [
  {
    id: FILTER_OPERATOR.CONTAINS,
    label: FILTER_OPERATOR_LABEL[FILTER_OPERATOR.CONTAINS],
  },
  {
    id: FILTER_OPERATOR.EQUAL,
    label: FILTER_OPERATOR_LABEL[FILTER_OPERATOR.EQUAL],
  },
];

const INTEGER_FIELD_OPERATORS = [
  {
    id: FILTER_OPERATOR.EQUAL,
    label: FILTER_OPERATOR_LABEL[FILTER_OPERATOR.EQUAL],
  },
  {
    id: FILTER_OPERATOR.LESS,
    label: FILTER_OPERATOR_LABEL[FILTER_OPERATOR.LESS],
  },
  {
    id: FILTER_OPERATOR.LESS_OR_EQUAL,
    label: FILTER_OPERATOR_LABEL[FILTER_OPERATOR.LESS_OR_EQUAL],
  },
  {
    id: FILTER_OPERATOR.MORE,
    label: FILTER_OPERATOR_LABEL[FILTER_OPERATOR.MORE],
  },
  {
    id: FILTER_OPERATOR.MORE_OR_EQUAL,
    label: FILTER_OPERATOR_LABEL[FILTER_OPERATOR.MORE_OR_EQUAL],
  },
];

const ITEM_LINK_FIELD_OPERATORS = [
  {
    id: FILTER_OPERATOR.EQUAL,
    label: FILTER_OPERATOR_LABEL[FILTER_OPERATOR.EQUAL],
  },
];

const ITEM_LINK_LIST_FIELD_OPERATORS = [
  {
    id: FILTER_OPERATOR.CONTAINS,
    label: FILTER_OPERATOR_LABEL[FILTER_OPERATOR.CONTAINS],
  },
];

const BOOLEAN_FIELD_OPERATORS = [
  {
    id: FILTER_OPERATOR.EQUAL,
    label: FILTER_OPERATOR_LABEL[FILTER_OPERATOR.EQUAL],
  },
];

const ID_FIELD_OPERATORS = INTEGER_FIELD_OPERATORS;

const LABEL_FIELD_OPERATORS = [
  {
    id: FILTER_OPERATOR.CONTAINS,
    label: FILTER_OPERATOR_LABEL[FILTER_OPERATOR.CONTAINS],
  },
  {
    id: FILTER_OPERATOR.EQUAL,
    label: FILTER_OPERATOR_LABEL[FILTER_OPERATOR.EQUAL],
  },
];

export const COMPONENTS_FILTER_OPERATORS = {
  [FIELD_TYPE.TEXT]: TEXT_FIELD_OPERATORS,
  [FIELD_TYPE.INTEGER]: INTEGER_FIELD_OPERATORS,
  [FIELD_TYPE.FLOAT]: INTEGER_FIELD_OPERATORS,
  [FIELD_TYPE.ITEM_LINK]: ITEM_LINK_FIELD_OPERATORS,
  [FIELD_TYPE.ITEM_LINK_LIST]: ITEM_LINK_LIST_FIELD_OPERATORS,
  [FIELD_TYPE.BOOLEAN]: BOOLEAN_FIELD_OPERATORS,
  [CUSTOM_FIELDS_FILTER.ID]: ID_FIELD_OPERATORS,
  [CUSTOM_FIELDS_FILTER.LABEL]: LABEL_FIELD_OPERATORS,
};
