import React from 'react';
import { useTheme } from 'src/providers/ThemeProvider';
import { isRetryableError } from '../utils/isRetryableError';
import * as Sentry from '@sentry/react';

export const TURNSTILE_WIDGET_ID = 'turnstile-widget';
const TURNSTILE_SITE_KEY = import.meta.env.VITE_TURNSTILE_SITE_KEY;

export function useTurnstile() {
  const { theme } = useTheme();
  const [isChallenging, setIsChallenging] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [isPreClearanceDone, setPreClearanceDone] = React.useState(false);
  const retries = React.useRef(5);

  const runTurnstileChallenge = React.useCallback(() => {
    return new Promise((resolve, reject) => {
      setIsChallenging(true);

      // @ts-expect-error: will fix it one day
      window.turnstile.render(`#${TURNSTILE_WIDGET_ID}`, {
        sitekey: TURNSTILE_SITE_KEY,
        theme: theme === 'light' ? 'light' : 'dark',
        retry: 'never',
        callback: (token: string, preClearanceObtained: boolean) => {
          if (preClearanceObtained) {
            console.log(`preClearance obtained`);
            setIsChallenging(false);
            setPreClearanceDone(true);
            resolve(token);
            return;
          }

          if (!isPreClearanceDone) {
            console.error(`token ${token}`);
            console.error(`preClearance obtained error`);
            setIsChallenging(false);
            setError(true);
            reject(error);
          }
        },
        'error-callback': (error: unknown) => {
          if (
            typeof error === 'string' &&
            isRetryableError(error) &&
            retries.current > 0
          ) {
            console.error(`preClearance error ${error}`);
            retries.current = retries.current - 1;
            // @ts-expect-error: will fix it one day
            window.turnstile.reset(`#${TURNSTILE_WIDGET_ID}`);
          } else {
            setIsChallenging(false);
            setError(true);
            Sentry.captureException({
              error,
              params: 'Turnstile challenge failed',
            });
            reject(error);
          }
        },
      });
    });
  }, [error, isPreClearanceDone, theme]);

  return { runTurnstileChallenge, isChallenging, error };
}
