import React from 'react';
import { Page__Header, Page__Content } from 'src/components/Page';
import { GET_MENU_KEY, useMenu } from 'src/hooks/api/useMenu';
import { getMenuTreeFromFlatData } from 'src/components/Nav/utils/getMenuTreeFromFlatData';
import { Flex, Box, Button, Skeleton } from '@radix-ui/themes';
import { GroupCreation } from './components/GroupCreation';
import { ItemCreation } from './components/ItemCreation';
import { MenuTree } from './components/MenuTree';
import { useNavigate } from 'react-router-dom';
import { Toast } from 'src/components/Toast';
import { useMenuUpdate } from 'src/hooks/api/useMenuUpdate';
import { getChangedItems } from './utils/getChangedItems';
import { getFlatDataFromMenuTree } from './utils/getFlatDataFromMenuTree';
import { AlertDialog } from 'src/components/AlertDialog';
import { MenuNode } from 'src/types/Menu';
import { useQueryClient } from '@tanstack/react-query';
import './index.css';
import { Access } from 'src/components/Access';
import { ROLES_LIST } from 'src/constants/workspaceRoles';
import { PERMISSIONS } from 'src/constants/permissions';
import { ErrorApi } from 'src/components/ErrorApi';
import { Callout } from 'src/components/Callout';
import { useTemplatesList } from 'src/hooks/api/useTemplatesList';
import { addChildrenOrder } from 'src/utils/addChildrenOrder';
import { sortChildrenByOrder } from 'src/utils/sortChildrenByOrder';

const PAGE_TITLE = 'Navigation setup';

export function NavigationSetup() {
  const [isSaveAlertOpen, setSaveAlertOpen] = React.useState(false);
  const {
    data: templatesList,
    error: templatesListError,
    isLoading: isTemplatesListLoading,
  } = useTemplatesList();
  const {
    data: menuData,
    error: menuError,
    isLoading: isMenuLoading,
  } = useMenu({
    options: {
      gcTime: 0, // don't need to cache for menu edit
    },
  });
  const [tree, setTree] = React.useState<MenuNode[]>();
  const [initialTree, setInitialTree] = React.useState<MenuNode[]>();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const {
    mutate: updateMenu,
    error: updateMenuError,
    isMutating: isMenuUpdating,
  } = useMenuUpdate({
    onSuccess: () => {
      Toast({ text: 'Navigation updated', variant: 'success' });
      queryClient.invalidateQueries({ queryKey: [GET_MENU_KEY] });
      navigate('/');
    },
  });

  React.useEffect(() => {
    const menuList = menuData?.menu;

    if (!menuList) {
      return;
    }

    if (!tree) {
      const initialTree = getMenuTreeFromFlatData({ data: menuList });
      const treeWithOrderedChildren = sortChildrenByOrder({
        treeData: initialTree,
      });
      setTree(treeWithOrderedChildren);
      setInitialTree(treeWithOrderedChildren);
    }
  }, [menuData, tree]);

  const handleTreeUpdate = (updatedTree: MenuNode[]) => {
    setTree(updatedTree);
  };

  const getUpdatedItems = () => {
    if (!initialTree || !tree) {
      return;
    }
    const treeWithOrderedChildren = addChildrenOrder({
      treeData: tree,
    });
    const flatInitialData = getFlatDataFromMenuTree({ treeData: initialTree });
    const flatTree = getFlatDataFromMenuTree({
      treeData: treeWithOrderedChildren,
    });
    const changedItems = getChangedItems({
      initialData: flatInitialData,
      updatedData: flatTree,
    });

    return changedItems;
  };

  const handleSave = () => {
    const changedItems = getUpdatedItems();

    if (!changedItems) {
      return;
    }

    updateMenu({ menu: changedItems });
  };

  if (isMenuLoading || isTemplatesListLoading) {
    return (
      <>
        <Page__Header title={PAGE_TITLE} backButtonUrl="/" />

        <Page__Content>
          <Skeleton width="430px" height="38px" mb="2" />
          <Skeleton width="430px" height="38px" mb="2" />
          <Skeleton width="430px" height="38px" mb="2" />
        </Page__Content>
      </>
    );
  }

  if (menuError || templatesListError) {
    return (
      <>
        <Page__Header title={PAGE_TITLE} backButtonUrl="/" />

        <Page__Content>
          {!!menuError && <ErrorApi error={menuError} />}

          {!!templatesListError && <ErrorApi error={templatesListError} />}
        </Page__Content>
      </>
    );
  }

  return (
    <>
      <Page__Header
        title={PAGE_TITLE}
        backButtonUrl="/"
        headingSuffix={
          <>
            {!!templatesList?.templates.length && (
              <Access
                roles={ROLES_LIST}
                permissionsGroups={[[PERMISSIONS.MENU_MANAGEMENT]]}
              >
                <Flex align="center" gap="3" wrap="wrap">
                  {!!tree && (
                    <>
                      <GroupCreation
                        treeData={tree}
                        onCreate={handleTreeUpdate}
                      />

                      <ItemCreation
                        treeData={tree}
                        onCreate={handleTreeUpdate}
                      />
                    </>
                  )}
                </Flex>
              </Access>
            )}
          </>
        }
      >
        {!!templatesList?.templates.length && (
          <Access
            roles={ROLES_LIST}
            permissionsGroups={[[PERMISSIONS.MENU_MANAGEMENT]]}
          >
            <AlertDialog
              open={isSaveAlertOpen}
              title="Update navigation"
              trigger={
                <Button
                  variant="soft"
                  color="plum"
                  onClick={() => setSaveAlertOpen(true)}
                  loading={isMenuUpdating}
                >
                  Save changes
                </Button>
              }
              actionText="Yes, save changes"
              cancelText="No, keep editing"
              onCancel={() => setSaveAlertOpen(false)}
              onAction={handleSave}
            >
              Are you sure you want to save the changes to the navigation?
            </AlertDialog>
          </Access>
        )}
      </Page__Header>

      <Page__Content hasScrollArea={false}>
        <div className="p-navigation-setup__content">
          {!templatesList?.templates.length && (
            <Box my="2" maxWidth="700px">
              <Callout color="gold">
                You haven't created any templates yet. Start by creating one to
                set up your navigation.
              </Callout>
            </Box>
          )}

          {updateMenuError && (
            <Box my="2">
              <ErrorApi error={updateMenuError} />
            </Box>
          )}

          {tree && <MenuTree tree={tree} onChange={handleTreeUpdate} />}
        </div>
      </Page__Content>
    </>
  );
}
