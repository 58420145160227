import React, { FormEvent } from 'react';
import pluralize from 'pluralize';
import {
  Flex,
  Text,
  Card,
  Box,
  Progress,
  IconButton,
  Button,
} from '@radix-ui/themes';
import * as Label from '@radix-ui/react-label';
import { Input } from 'src/components/Input';
import { MinusIcon, PlusIcon } from '@radix-ui/react-icons';
import { PRICES } from 'src/constants/plans';
import { ProjectPricingDialog } from './ProjectPricingDialog';
import { useSubscriptionProjectsUpdatePreview } from 'src/hooks/api/useSubscriptionProjectsUpdatePreview';
import { SuccessfulPaymentCallbackArgs } from '../types';

export const PROJECTS_INCLUDED_IN_PLAN = 3;

type ProjectPricingProps = {
  paidProjects: number;
  usedProjects: number;
  workspaceId: string;
  onSuccessfulPayment: SuccessfulPaymentCallbackArgs;
};

export function ProjectPricing({
  usedProjects,
  paidProjects,
  workspaceId,
  onSuccessfulPayment,
}: ProjectPricingProps) {
  const [isDialogOpen, setIsDialogOpen] = React.useState(false);
  const paidAdditionalSeats = Math.min(
    paidProjects,
    paidProjects - PROJECTS_INCLUDED_IN_PLAN
  );
  const [newAdditionalProjects, setNewAdditionalProjects] =
    React.useState(paidAdditionalSeats);

  const {
    mutate: projectsPricePreview,
    data: projectsPricePreviewData,
    isMutating: isProjectsPricePreviewLoading,
    error: projectsPricePreviewError,
  } = useSubscriptionProjectsUpdatePreview();

  const handleIncreaseProjects = () => {
    setNewAdditionalProjects(newAdditionalProjects + 1);
  };

  const handleDecreaseProjects = () => {
    setNewAdditionalProjects(Math.max(newAdditionalProjects - 1, 0));
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    projectsPricePreview({
      new_value: newAdditionalProjects,
      workspaceId,
    });
    setIsDialogOpen(true);
  };

  const handleSuccessfulUpdate = (projectsValue: number) => {
    onSuccessfulPayment({ projects: projectsValue });
  };

  const projectsDiff = newAdditionalProjects - paidAdditionalSeats;
  const planPrice = PRICES.PROJECT;

  return (
    <>
      <ProjectPricingDialog
        isLoading={isProjectsPricePreviewLoading}
        pricePreview={projectsPricePreviewData}
        workspaceId={workspaceId}
        projectsDiff={projectsDiff}
        isDialogOpen={isDialogOpen}
        setIsDialogOpen={setIsDialogOpen}
        newAdditionalProjects={newAdditionalProjects}
        subscriptionPreviewError={projectsPricePreviewError}
        onSuccessfulUpdate={handleSuccessfulUpdate}
      />
      <Card variant="surface" size="3">
        <form onSubmit={handleSubmit} data-testid="paid-projects-submit-form">
          <Flex mb="2" justify="between">
            <Text as="div" size="4" weight="bold">
              Projects{' '}
              <Text color="gray" weight="regular" size="2">
                (3 included in plan)
              </Text>
            </Text>
          </Flex>

          <Box mt="4">
            <Text color="gray">
              You are using {usedProjects} out of {paidProjects}{' '}
              {pluralize('project', paidProjects)}
            </Text>

            <Progress
              mt="2"
              variant="soft"
              value={Math.round((usedProjects / paidProjects) * 100)}
            />
          </Box>

          <Flex mt="5" align="center" justify="between" gap="2">
            <Label.Root htmlFor="paidProjects">
              <Text>Paid additional projects</Text>
            </Label.Root>

            <Box maxWidth="100px">
              <Input
                mods={{ align: 'center' }}
                value={newAdditionalProjects.toString()}
                onChange={(e) =>
                  setNewAdditionalProjects(Number(e.target.value))
                }
                type="number"
                id="paidProjects"
                data-testid="paid-projects-input"
                leftSlot={
                  <IconButton
                    onClick={handleDecreaseProjects}
                    variant="ghost"
                    type="button"
                    data-testid="paid-projects-decrease"
                  >
                    {<MinusIcon />}
                  </IconButton>
                }
                rightSlot={
                  <IconButton
                    onClick={handleIncreaseProjects}
                    variant="ghost"
                    type="button"
                    data-testid="paid-projects-increase"
                  >
                    {<PlusIcon />}
                  </IconButton>
                }
              />
            </Box>
          </Flex>

          {projectsDiff === 0 ? (
            <Box mt="2" height="24px" />
          ) : (
            <Box mt="2">
              <Text size="3">
                <Flex align="center" justify="between">
                  {projectsDiff > 0 ? (
                    <>
                      <span>
                        Bill increase{' '}
                        <Text size="1" color="gray">
                          (taxes may apply)
                        </Text>
                      </span>
                      <Text color="plum">+${projectsDiff * planPrice}</Text>
                    </>
                  ) : (
                    <>
                      <span>
                        Bill decrease{' '}
                        <Text size="1" color="gray">
                          (taxes may apply)
                        </Text>
                      </span>{' '}
                      <Text color="plum">
                        -${Math.abs(projectsDiff) * planPrice}
                      </Text>
                    </>
                  )}
                </Flex>
              </Text>
            </Box>
          )}

          <Flex align="center" justify="center" mt="6">
            <Button
              type="submit"
              style={{ width: '100%' }}
              color="plum"
              variant="soft"
              size="3"
              disabled={projectsDiff === 0}
            >
              Update projects
            </Button>
          </Flex>
        </form>
      </Card>
    </>
  );
}
