import React from 'react';
import { Page__Content, Page__Header } from 'src/components/Page';
import { Token } from './components/Token';
import { Box } from '@radix-ui/themes';
import { CurrentInstanceCard } from 'src/components/CurrentInstanceCard';
import { useInstanceContext } from 'src/providers/InstanceProvider';

export function ExportSettings() {
  const { instanceId } = useInstanceContext();
  return (
    <>
      <Page__Header title="Export settings" backButtonUrl="/" />

      <Page__Content>
        <Box maxWidth="600px">
          <Token />
        </Box>

        {instanceId > 0 && (
          <Box width="100%" mt="6" maxWidth="300px">
            <CurrentInstanceCard instanceId={instanceId} />
          </Box>
        )}
      </Page__Content>
    </>
  );
}
