import React from 'react';
import * as Sentry from '@sentry/react';
import { BrowserRouter, Routes, Route, useNavigate } from 'react-router-dom';
import './App.css';
import { history } from 'src/utils/history';
import { ErrorGlobal } from 'src/components/ErrorGlobal';
import { ProtectedRoute } from './routes/ProtectedRoute';
import { InstanceRoute } from './routes/InstanceRoute';
import * as ROUTES_PATH from './utils/routePaths';
import { PrimaryLayoutRoute } from './routes/PrimaryLayoutRoute';
import { Nav, Nav__Settings, Nav__Templates } from './components/Nav';
import { RoleBasedRoute } from './routes/RoleBasedRoute';
import { ROLES_LIST, WORKSPACES_ROLES } from './constants/workspaceRoles';
import { PERMISSIONS } from './constants/permissions';
import { TemplateEdit } from './pages/template/edit';
import { TemplateNew } from './pages/template/new';
import { TemplateCopy } from './pages/template/copy';

import Items from './pages/items';
import { ItemEdit } from './pages/item/edit';
import { ItemNew } from './pages/item/new';
import { ItemImageUpload } from './pages/item-image-upload';

import IndexPage from './pages/main';
import { NavigationSetup } from './pages/settings/navigation-setup';
import { InstanceSync } from './pages/settings/sync-and-export/instance-sync';
import { ExportedData } from './pages/settings/sync-and-export/exported-data';
import { ExportSettings } from './pages/settings/sync-and-export/export-settings';
import { Account } from './pages/settings/account';
import { Billing } from './pages/settings/billing';
import { SettingsRoleBasedRoute } from './routes/SettingsRoleBasedRoute';
import { WorkspaceOverview } from './pages/settings/workspace/overview';
import { WorkspaceMembers } from './pages/settings/workspace/members';
import { WorkspaceRoles } from './pages/settings/workspace/roles';
import { Project } from './pages/settings/workspace/project';
import {
  ProjectRoleEdit,
  ProjectRoleNew,
} from './pages/settings/workspace/workspace-role';
import {
  ProjectAddMember,
  ProjectEditMember,
} from './pages/settings/workspace/project-member';
import RegistrationConfirmation from './pages/registration/confirmation';
import RegistrationHelp from './pages/registration/help';
import Registration from './pages/registration';
import PasswordReset from './pages/password-reset';
import PasswordResetConfirmation from './pages/password-reset/confirmation';
import Login from './pages/login';
import { ProjectRemovalCancellation } from './pages/project-removal-cancellation';
import { EmailUpdateConfirmation } from './pages/email-update-confirmation';
import { ErrorAccessDenied } from './components/ErrorAccessDenied';
import { Error } from './pages/error';
import { ErrorNotFound } from './components/ErrorNotFound';
import { AccessSubscription } from './components/AccessSubscription';
import { PLANS } from './constants/plans';
import { AccessInstance } from './components/AccessInstance';
import { INSTANCE } from './constants/instance';
import { ErrorActionNotAllowedForInstance } from './components/ErrorActionNotAllowedForInstance';

// wrap each route in StrictMode as react-sortable-tree doesn't support strict mode
const wrapWithStrictMode = (component: React.ReactNode) => {
  return <React.StrictMode>{component}</React.StrictMode>;
};

function AppRoutes() {
  const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes); // https://github.com/getsentry/sentry-javascript/issues/5309
  history.navigate = useNavigate();

  return (
    <SentryRoutes>
      {/* Private routes */}
      <Route element={<ProtectedRoute />} path="">
        <Route element={<InstanceRoute />} path={ROUTES_PATH.INSTANCE}>
          <Route
            element={
              <PrimaryLayoutRoute
                nav={
                  <Nav>
                    <Nav__Templates />
                  </Nav>
                }
              />
            }
            path=""
          >
            {/* Template */}
            <Route
              element={
                <RoleBasedRoute
                  roles={ROLES_LIST}
                  permissionsGroups={[[PERMISSIONS.READ]]}
                />
              }
            >
              <Route
                path={ROUTES_PATH.TEMPLATE_EDIT}
                element={<TemplateEdit />}
              />
            </Route>

            <Route
              element={
                <RoleBasedRoute
                  roles={ROLES_LIST}
                  permissionsGroups={[[PERMISSIONS.CREATE_TEMPLATE]]}
                />
              }
            >
              <Route
                path={ROUTES_PATH.TEMPLATE_NEW}
                element={
                  <AccessInstance
                    allowedInstances={[INSTANCE.DEV]}
                    redirectIfFailed
                  >
                    <TemplateNew />
                  </AccessInstance>
                }
              />

              <Route
                path={ROUTES_PATH.TEMPLATE_COPY}
                element={<TemplateCopy />}
              />
            </Route>

            {/* Item */}
            <Route
              element={
                <RoleBasedRoute
                  roles={ROLES_LIST}
                  permissionsGroups={[[PERMISSIONS.READ]]}
                />
              }
            >
              <Route
                path={ROUTES_PATH.ITEMS}
                element={wrapWithStrictMode(<Items />)}
              />
            </Route>

            <Route
              element={
                <RoleBasedRoute
                  roles={ROLES_LIST}
                  permissionsGroups={[[PERMISSIONS.READ]]}
                />
              }
            >
              <Route
                path={ROUTES_PATH.ITEM_EDIT}
                element={wrapWithStrictMode(<ItemEdit />)}
              />
            </Route>

            <Route
              element={
                <RoleBasedRoute
                  roles={ROLES_LIST}
                  permissionsGroups={[[PERMISSIONS.CREATE_ITEM]]}
                />
              }
            >
              <Route
                path={ROUTES_PATH.ITEM_NEW}
                element={wrapWithStrictMode(
                  <AccessInstance
                    allowedInstances={[INSTANCE.DEV]}
                    redirectIfFailed
                  >
                    <ItemNew />
                  </AccessInstance>
                )}
              />
            </Route>

            <Route
              element={
                <RoleBasedRoute
                  roles={ROLES_LIST}
                  permissionsGroups={[[PERMISSIONS.CREATE_ITEM]]}
                />
              }
            >
              <Route
                path={ROUTES_PATH.ITEMS_IMAGE_UPLOAD}
                element={wrapWithStrictMode(
                  <AccessInstance
                    allowedInstances={[INSTANCE.DEV]}
                    redirectIfFailed
                  >
                    <ItemImageUpload />
                  </AccessInstance>
                )}
              />
            </Route>
          </Route>
        </Route>

        <Route
          element={
            <PrimaryLayoutRoute
              nav={
                <Nav>
                  <Nav__Templates />
                </Nav>
              }
            />
          }
          path=""
        >
          <Route path="/" element={wrapWithStrictMode(<IndexPage />)} />

          {/* Settings navigation */}
          <Route
            element={
              <RoleBasedRoute
                roles={ROLES_LIST}
                permissionsGroups={[[PERMISSIONS.READ]]}
              />
            }
          >
            <Route
              path={ROUTES_PATH.SETTINGS_NAVIGATION_SETUP}
              element={<NavigationSetup />}
            />
          </Route>

          {/* Settings exported data */}
          <Route
            element={
              <RoleBasedRoute
                roles={ROLES_LIST}
                permissionsGroups={[[PERMISSIONS.EXPORT]]}
              />
            }
          >
            <Route
              path={ROUTES_PATH.SETTINGS_EXPORTED_DATA}
              element={wrapWithStrictMode(<ExportedData />)}
            />
          </Route>

          <Route
            element={
              <RoleBasedRoute
                roles={ROLES_LIST}
                permissionsGroups={[[PERMISSIONS.EXPORT]]}
              />
            }
          >
            <Route
              path={ROUTES_PATH.SETTINGS_EXPORT}
              element={wrapWithStrictMode(<ExportSettings />)}
            />
          </Route>

          {/* Settings instance sync */}
          <Route
            element={
              <RoleBasedRoute
                roles={ROLES_LIST}
                permissionsGroups={[[PERMISSIONS.INSTANCE_CHANGES]]}
              />
            }
          >
            <Route
              path={ROUTES_PATH.SETTINGS_INSTANCE_SYNC}
              element={
                <AccessSubscription plan={[PLANS.TEAMS]} redirectIfFailed>
                  <InstanceSync />
                </AccessSubscription>
              }
            />
          </Route>
        </Route>

        <Route
          element={
            <PrimaryLayoutRoute
              nav={
                <Nav>
                  <Nav__Settings />
                </Nav>
              }
            />
          }
          path=""
        >
          <Route path={ROUTES_PATH.SETTINGS_ACCOUNT} element={<Account />} />
          <Route path={ROUTES_PATH.SETTINGS_BILLING} element={<Billing />} />

          {/* Settings workspace */}
          <Route
            path={ROUTES_PATH.SETTINGS_WORKSPACE}
            element={
              <SettingsRoleBasedRoute
                roles={[WORKSPACES_ROLES.OWNER, WORKSPACES_ROLES.ADMIN]}
                ignorePermissions
              >
                <WorkspaceOverview />
              </SettingsRoleBasedRoute>
            }
          />

          <Route
            path={ROUTES_PATH.SETTINGS_WORKSPACE_MEMBERS}
            element={
              <SettingsRoleBasedRoute
                roles={[WORKSPACES_ROLES.OWNER, WORKSPACES_ROLES.ADMIN]}
                ignorePermissions
              >
                <WorkspaceMembers />
              </SettingsRoleBasedRoute>
            }
          />

          <Route
            path={ROUTES_PATH.SETTINGS_WORKSPACE_ROLES}
            element={
              <SettingsRoleBasedRoute
                roles={[WORKSPACES_ROLES.OWNER, WORKSPACES_ROLES.ADMIN]}
                ignorePermissions
              >
                <WorkspaceRoles />
              </SettingsRoleBasedRoute>
            }
          />

          {/* Settings project */}
          <Route
            path={ROUTES_PATH.SETTINGS_PROJECT}
            element={
              <SettingsRoleBasedRoute
                roles={[WORKSPACES_ROLES.OWNER, WORKSPACES_ROLES.ADMIN]}
                ignorePermissions
              >
                <Project />
              </SettingsRoleBasedRoute>
            }
          />

          {/* Settings project role */}
          <Route
            path={ROUTES_PATH.SETTINGS_PROJECT_ROLE_NEW}
            element={
              <SettingsRoleBasedRoute
                roles={[WORKSPACES_ROLES.OWNER, WORKSPACES_ROLES.ADMIN]}
                ignorePermissions
              >
                <AccessSubscription plan={[PLANS.TEAMS]} redirectIfFailed>
                  <ProjectRoleNew />
                </AccessSubscription>
              </SettingsRoleBasedRoute>
            }
          />

          <Route
            path={ROUTES_PATH.SETTINGS_PROJECT_ROLE_EDIT}
            element={
              <SettingsRoleBasedRoute
                roles={[WORKSPACES_ROLES.OWNER, WORKSPACES_ROLES.ADMIN]}
                ignorePermissions
              >
                <ProjectRoleEdit />
              </SettingsRoleBasedRoute>
            }
          />

          {/* Settings project member */}
          <Route
            path={ROUTES_PATH.SETTINGS_PROJECT_MEMBER_ADD}
            element={
              <SettingsRoleBasedRoute
                roles={[WORKSPACES_ROLES.OWNER, WORKSPACES_ROLES.ADMIN]}
                ignorePermissions
              >
                <ProjectAddMember />
              </SettingsRoleBasedRoute>
            }
          />

          <Route
            path={ROUTES_PATH.SETTINGS_PROJECT_MEMBER_EDIT}
            element={
              <SettingsRoleBasedRoute
                roles={[WORKSPACES_ROLES.OWNER, WORKSPACES_ROLES.ADMIN]}
                ignorePermissions
              >
                <ProjectEditMember />
              </SettingsRoleBasedRoute>
            }
          />
        </Route>
      </Route>

      {/* Public routes */}
      <Route
        path={ROUTES_PATH.REGISTRATION_CONFIRM}
        element={wrapWithStrictMode(<RegistrationConfirmation />)}
      />
      <Route
        path={ROUTES_PATH.REGISTRATION_HELP}
        element={wrapWithStrictMode(<RegistrationHelp />)}
      />

      <Route
        path={ROUTES_PATH.REGISTRATION}
        element={wrapWithStrictMode(<Registration />)}
      />
      <Route
        path={ROUTES_PATH.PASSWORD_RESET}
        element={wrapWithStrictMode(<PasswordReset />)}
      />
      <Route
        path={ROUTES_PATH.PASSWORD_RESET_CONFIRM}
        element={wrapWithStrictMode(<PasswordResetConfirmation />)}
      />
      <Route path={ROUTES_PATH.LOGIN} element={wrapWithStrictMode(<Login />)} />

      <Route
        path={ROUTES_PATH.PROJECT_REMOVAL_CANCELLATION}
        element={wrapWithStrictMode(<ProjectRemovalCancellation />)}
      />

      <Route
        path={ROUTES_PATH.EMAIL_UPDATE_CONFIRMATION}
        element={wrapWithStrictMode(<EmailUpdateConfirmation />)}
      />

      {/* Errors */}
      <Route
        path={ROUTES_PATH.ACCESS_DENIED}
        element={wrapWithStrictMode(<ErrorAccessDenied />)}
      />
      <Route
        path={ROUTES_PATH.ACTION_NOT_ALLOWED_FOR_INSTANCE}
        element={wrapWithStrictMode(<ErrorActionNotAllowedForInstance />)}
      />
      <Route path={ROUTES_PATH.ERROR} element={wrapWithStrictMode(<Error />)} />

      <Route path="*" element={wrapWithStrictMode(<ErrorNotFound />)} />
    </SentryRoutes>
  );
}

type FallbackComponentProps = {
  eventId: string;
};

function FallbackComponent({ eventId }: FallbackComponentProps) {
  return <ErrorGlobal eventId={eventId} />;
}

function App() {
  return (
    <BrowserRouter>
      <Sentry.ErrorBoundary
        fallback={({ eventId }) => FallbackComponent({ eventId })}
      >
        <AppRoutes />
      </Sentry.ErrorBoundary>
    </BrowserRouter>
  );
}

export default Sentry.withProfiler(App);
