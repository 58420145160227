import React from 'react';
import { PLANS, STARTER_FEATURES } from 'src/constants/plans';
import { PlanCard } from './PlanCard';
import { Subscription } from 'src/types/Subscription';

type StarterPlanBasicCardProps = {
  subscription?: Subscription;
  onPlanSelect?: () => void;
  isLoading?: boolean;
};

export function StarterPlanBasicCard({
  subscription,
  onPlanSelect,
  isLoading,
}: StarterPlanBasicCardProps) {
  return (
    <PlanCard
      plan={PLANS.STARTER}
      subscription={subscription}
      featuresDescription="Everything in Free, plus:"
      features={STARTER_FEATURES}
      name="Starter plan"
      description="Optimal for individuals and small teams"
      price="$16"
      onPlanSelect={onPlanSelect}
      isLoading={isLoading}
    />
  );
}
