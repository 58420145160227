import React from 'react';
import { Badge, Flex, IconButton, Reset, Text } from '@radix-ui/themes';
import { Cross1Icon } from '@radix-ui/react-icons';
import _ from 'lodash';
import { FilterPopup } from '../FilterPopup';
import { FormData } from '../FilterPopup/types';
import {
  FILTER_OPERATOR_LABEL,
  FilterOperatorsType,
} from 'src/constants/filter';
import { useItemsTableContext } from '../../providers/ItemsTableProvider';
import { FIELD_TYPE } from 'src/constants/components';

type FilterTagProps = {
  internalId: string;
  fieldId: string;
  label: string;
  value: string;
  condition: FilterOperatorsType;
  onRemove: (value: string) => void;
};

export function FilterTag({
  internalId,
  fieldId,
  label,
  value,
  condition,
  onRemove,
}: FilterTagProps) {
  const { filter, setFilter, itemLabelsMap, templateFields } =
    useItemsTableContext();

  const handleSubmit = (data: FormData) => {
    const updatedFilter = filter.map((item) => {
      if (item.internalId === internalId) {
        return {
          ...item,
          condition: data.conditionId,
          value: data.value,
        };
      }
      return item;
    });

    setFilter([...updatedFilter]);
  };

  const templateField = templateFields.find((item) => item.fieldId === fieldId);
  const isItemLink =
    templateField?.type === FIELD_TYPE.ITEM_LINK ||
    templateField?.type === FIELD_TYPE.ITEM_LINK_LIST;
  const itemLabel = isItemLink ? itemLabelsMap.get(value) : '';

  return (
    <Flex className="filter-tag">
      <Badge
        className="filter-tag__title"
        color="gray"
        radius="full"
        size="2"
        variant="soft"
      >
        <IconButton
          title="Remove filter"
          variant="ghost"
          size="1"
          color="gold"
          onClick={() => onRemove(internalId)}
        >
          <Cross1Icon width="12px" />
        </IconButton>
        <Text>{_.capitalize(label)}</Text>
      </Badge>
      <FilterPopup
        isEdit={true}
        defaultValues={{ fieldId, conditionId: condition, value }}
        onSubmit={handleSubmit}
        trigger={
          <Badge className="filter-tag__content" radius="full" size="2">
            <Reset>
              <button className="filter__button" type="button">
                {FILTER_OPERATOR_LABEL[condition]}
              </button>
            </Reset>
            {itemLabel ? `${itemLabel} [${value}]` : value}
          </Badge>
        }
      />
    </Flex>
  );
}
