import React from 'react';
import { Box, Button } from '@radix-ui/themes';
import { AlertDialog } from 'src/components/AlertDialog';
import { Toast } from 'src/components/Toast';
import { useExportItems } from 'src/hooks/api/useExportItems';
import { ErrorApi } from 'src/components/ErrorApi';
import { useQueryClient } from '@tanstack/react-query';
import { GET_REPORTS_LIST_KEY } from 'src/hooks/api/useExportReportsList';

export function ExportItems() {
  const queryClient = useQueryClient();
  const {
    mutate: exportItems,
    isMutating: isExportingItems,
    error: exportItemsError,
    reset: resetMutation,
  } = useExportItems({
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [GET_REPORTS_LIST_KEY] });
      setIsDialogOpen(false);
      Toast({
        text: 'Export initiated successfully',
        variant: 'success',
      });
    },
  });

  const handleDialogCancel = () => {
    setIsDialogOpen(false);
    resetMutation();
  };

  const [isDialogOpen, setIsDialogOpen] = React.useState(false);

  return (
    <AlertDialog
      onAction={() => exportItems(undefined)} // ts issue https://github.com/TanStack/query/issues/1077
      onCancel={handleDialogCancel}
      open={isDialogOpen}
      color="tomato"
      actionText="Yes, export items"
      cancelText="No, cancel"
      trigger={
        <Button
          onClick={() => setIsDialogOpen(true)}
          variant="soft"
          color="gold"
          loading={isExportingItems}
        >
          Export items
        </Button>
      }
    >
      {!!exportItemsError && (
        <Box mb="4">
          <ErrorApi error={exportItemsError} />
        </Box>
      )}
      Are you sure you want to export? This process may take some time. <br />{' '}
      <Box mt="2">
        You can view and download the data later in the Dashboard -{'>'}{' '}
        Exported data.
      </Box>
    </AlertDialog>
  );
}
