import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useQueryClient } from '@tanstack/react-query';
import { addStatusesToTemplateFields } from 'src/pages/template/utils/addStatusesToTemplateFields';
import { Template } from './components/Template';
import { useSaveTemplate } from 'src/hooks/api/useSaveTemplate';
import {
  TEMPLATE_BY_ID_KEY,
  useTemplateById,
} from 'src/hooks/api/useTemplateById';
import {
  TemplateTreeProvider,
  useTemplateTreeContext,
} from 'src/providers/TemplateTreeProvider';
import { useInstanceContext } from 'src/providers/InstanceProvider';
import { validationSchema } from './utils/editTemplateValidationSchema';
import { GET_MENU_KEY } from 'src/hooks/api/useMenu';
import { Page__Content, Page__Header } from 'src/components/Page';
import { getOrderedTreeFromFlatData } from 'src/utils/getOrderedTreeFromFlatData';
import * as Sentry from '@sentry/react';
import { Toast } from 'src/components/Toast';
import { convertTreeToTemplateFields } from 'src/utils/convertTreeToTemplateFields';
import { ErrorApi } from 'src/components/ErrorApi';
import { appRoutes } from 'src/utils/routePaths';
import { TabsSkeleton } from './components/TabsSkeleton';

function Edit() {
  const methods = useForm({
    criteriaMode: 'all',
    delayError: 250,
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
    reValidateMode: 'onChange',
  });
  const queryClient = useQueryClient();
  const { instanceId } = useInstanceContext();
  const params = useParams();
  const navigate = useNavigate();
  const [isAlertOpen, setAlertOpen] = React.useState(false);
  const {
    mutate: updateTemplate,
    error: templateSaveError,
    isMutating: isTemplateSaving,
  } = useSaveTemplate({
    onSuccess: () => {
      navigateToTemplateManagementPage();
      queryClient.invalidateQueries({
        queryKey: [TEMPLATE_BY_ID_KEY],
      });
    },
  });
  const {
    data: initialTemplate,
    error: templateError,
    isLoading: isTemplateLoading,
  } = useTemplateById({
    id: params?.templateId,
    options: {
      gcTime: 0, // disable cache by default to avoid problems in form edit
      staleTime: 0, // disable cache by default to avoid problems in form edit
    },
  });
  const { templateTree, setTemplateTree } = useTemplateTreeContext();
  const [isTemplateInit, setIsTemplateInit] = React.useState(false);

  React.useEffect(() => {
    if (initialTemplate && !isTemplateInit) {
      const orderedTree = getOrderedTreeFromFlatData({
        fields: initialTemplate.fields || [],
      });

      setTemplateTree(orderedTree);
      setIsTemplateInit(true);
    }
  }, [initialTemplate, isTemplateInit, setTemplateTree]);

  React.useEffect(() => {
    const templateId = methods.getValues('templateId');
    if (initialTemplate && !templateId) {
      methods.setValue('templateId', initialTemplate.id);
      methods.setValue('templateLabel', initialTemplate.header.label);
    }
  }, [initialTemplate, methods]);

  const handleSaveTemplate = async () => {
    setAlertOpen(false);
    const templateData = convertTreeToTemplateFields({ templateTree });

    const fields = addStatusesToTemplateFields({
      initialData: initialTemplate?.fields || [],
      currentData: templateData,
    });

    const id = params.templateId;

    if (!id) {
      Sentry.captureException({
        error: 'there is no params.templateId in template edit',
        params: JSON.stringify(params),
      });

      Toast({
        text: 'Template “id” not found. Please try again later.',
        variant: 'error',
      });
      return;
    }

    const label = methods.getValues('templateLabel');

    updateTemplate({
      fields,
      label,
      id,
      version: initialTemplate?.header.version || 0,
    });
  };

  function navigateToTemplateManagementPage() {
    // update navigation menu
    queryClient.invalidateQueries({ queryKey: [GET_MENU_KEY] });

    const url = appRoutes.items({
      instanceId,
      templateId: params.templateId || '',
    });
    navigate(url);
  }

  if (templateError) {
    return (
      <>
        <Page__Header title="Template edit" />

        <Page__Content>
          <ErrorApi error={templateError} />
        </Page__Content>
      </>
    );
  }

  if (isTemplateLoading) {
    return (
      <>
        <Page__Header
          title="Edit template"
          backButtonUrl={appRoutes.items({
            instanceId,
            templateId: params?.templateId || '',
          })}
        />

        <Page__Content>
          <TabsSkeleton />
        </Page__Content>
      </>
    );
  }

  return (
    <FormProvider {...methods}>
      <Template
        isNew={false}
        isAlertOpen={isAlertOpen}
        setAlertOpen={setAlertOpen}
        onSaveTemplate={handleSaveTemplate}
        error={templateSaveError}
        isSaving={isTemplateSaving}
        initialTemplateFields={initialTemplate?.fields || []}
        template={initialTemplate}
      />
    </FormProvider>
  );
}

export function TemplateEdit() {
  return (
    <TemplateTreeProvider>
      <Edit />
    </TemplateTreeProvider>
  );
}
