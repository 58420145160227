import React from 'react';
import { Subscription } from 'src/types/Subscription';
import { Box } from '@radix-ui/themes';
import { ErrorApi } from 'src/components/ErrorApi';
import { AlertDialog } from 'src/components/AlertDialog';
import { SuccessfulPaymentCallbackArgs } from '../types';
import { SUBSCRIPTION_STATUS } from 'src/constants/subscription';
import { FreePlanBasicCard } from './FreePlanBasicCard';
import { useSubscriptionSwitchToFree } from 'src/hooks/api/useSubscriptionSwitchToFree';

type FreePlanDowngradeProps = {
  workspaceId: string;
  subscription?: Subscription;
  onSuccessfulPayment: SuccessfulPaymentCallbackArgs;
};

export function FreePlanDowngrade({
  workspaceId,
  onSuccessfulPayment,
  subscription,
}: FreePlanDowngradeProps) {
  const [isDialogOpen, setIsDialogOpen] = React.useState(false);
  const {
    mutate: downgradeSubscription,
    isMutating: isDowngrading,
    error: downgradeError,
  } = useSubscriptionSwitchToFree({
    onSuccess: () => {
      onSuccessfulPayment({
        status: SUBSCRIPTION_STATUS.CANCEL_REQUESTED,
      });
      setIsDialogOpen(false);
    },
  });

  const handleDowngrade = () => {
    downgradeSubscription({ workspaceId });
  };

  return (
    <>
      <FreePlanBasicCard
        subscription={subscription}
        onPlanSelect={() => setIsDialogOpen(true)}
      />

      <AlertDialog
        title="Downgrade to the Free plan"
        onAction={handleDowngrade}
        onCancel={() => setIsDialogOpen(false)}
        open={isDialogOpen}
        color="tomato"
        actionText="Yes, downgrade my plan"
        cancelText="No, keep my current plan"
        loading={isDowngrading}
      >
        {!!downgradeError && (
          <Box mb="4">
            <ErrorApi error={downgradeError} />
          </Box>
        )}
        This change will limit your access to 1 project, restrict you to 800
        items, and remove access to multiple instances, custom roles, and member
        invites. You’ll also be limited to uploading images up to 1 MB.
        <Box mt="2">Do you want to proceed?</Box>
      </AlertDialog>
    </>
  );
}
