export const getLocalStorageKey = (templateId: string, instanceId: number) => {
  return `hidden_columns_${instanceId}_${templateId}`;
};
export const saveHiddenColumns = (
  templateId: string,
  hiddenColumns: string[],
  instanceId: number
) => {
  const key = getLocalStorageKey(templateId, instanceId);
  localStorage.setItem(key, JSON.stringify(hiddenColumns));
};

export const getHiddenColumns = (templateId: string, instanceId: number) => {
  const key = getLocalStorageKey(templateId, instanceId);
  const storedColumns = localStorage.getItem(key);

  return storedColumns ? JSON.parse(storedColumns) : [];
};
