import React from 'react';
import { getColumns } from '../utils/getColumns';
import { getData } from '../utils/getTablesData';
import type { ColumnDef } from '@tanstack/react-table';
import { DataType } from '../index';
import { useItemsTableContext } from '../providers/ItemsTableProvider';
import { ApiTemplateResponse } from 'src/types/Template';
import { ApiItemListResponse } from 'src/types/Item';
import { ItemsLabelsMapType } from './useItemsLabels';
import { getOrderedTreeFromFlatData } from 'src/utils/getOrderedTreeFromFlatData';

type UseTemplateDataArg = {
  template?: ApiTemplateResponse;
  instanceId: number;
  itemsList?: ApiItemListResponse | null;
  itemsLabelsMap: ItemsLabelsMapType;
};

export function useTemplateData({
  template,
  itemsList,
  instanceId,
  itemsLabelsMap,
}: UseTemplateDataArg) {
  const { setTemplateTree } = useItemsTableContext();
  const [columns, setColumns] = React.useState<ColumnDef<DataType>[]>([]);
  const [data, setData] = React.useState<DataType[]>([]);
  React.useEffect(() => {
    const templateFields = template?.fields || [];

    const items = itemsList?.items;
    const templateId = template?.id;
    if (!items || !templateId) {
      return;
    }

    const tree = getOrderedTreeFromFlatData({ fields: templateFields });
    const tableColumns = getColumns({
      tree,
      templateId: templateId,
      instanceId,
      itemsLabelsMap,
    });

    // tableColumns.push({
    //   id: 'actions',
    //   enableHiding: false,
    //   cell: ({ row }) => (
    //     <>
    //       <TableDropdown
    //         itemId={row.original.id}
    //         templateId={params.templateId}
    //       />
    //     </>
    //   ),
    // });

    setColumns(tableColumns);
    setData(
      getData({
        items: items,
        templateTree: tree,
      })
    );
    setTemplateTree(tree);
  }, [template, itemsList, instanceId, itemsLabelsMap, setTemplateTree]);

  return { columns, data };
}
