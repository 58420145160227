import React from 'react';
import { Page__Content, Page__Header } from 'src/components/Page';
import { SubscriptionCancellation } from './components/SubscriptionCancellation';
import { Box, Flex } from '@radix-ui/themes';
import { useWorkspacesList } from 'src/hooks/api/useWorkspacesList';
import { WorkspacesSelect } from './components/WorkspacesSelect';
import { Callout } from 'src/components/Callout';
import { ErrorApi } from 'src/components/ErrorApi';
import { useUserContext } from 'src/providers/UserProvider';
import { getAvailableWorkspaces } from './utils/getAvailableWorkspaces';
import { ManagePaymentMethod } from './components/ManagePaymentMethod';
import { useSubscription } from 'src/hooks/api/useSubscription';
import { SUBSCRIPTION_STATUS } from 'src/constants/subscription';
import { AccessSubscription } from 'src/components/AccessSubscription';
import { useSubscriptionPolling } from './hooks/useSubscriptionPolling';
import { SuccessfulPaymentCallbackArgs } from './types';
import { PLANS } from 'src/constants/plans';
import { SubscriptionReactivation } from './components/SubscriptionReactivation';
import { SubscriptionInfoCard } from './components/SubscriptionInfoCard';
import { TeamsPlanBasicCard } from './components/TeamsPlanBasicCard';
import { StarterPlanBasicCard } from './components/StarterPlanBasicCard';
import { MembersAndProjectsSetup } from './components/MembersAndProjectsSetup';
import { CardList } from './components/CardList';

export function Billing() {
  const { workspacesRoleMap } = useUserContext();
  const [selectedWorkspace, setSelectedWorkspace] = React.useState('');
  const {
    data: workspacesList,
    error: workspacesListError,
    isLoading: isWorkspacesListLoading,
  } = useWorkspacesList();
  const {
    isSubscriptionPollingEnabled,
    enablePolling,
    isShowPollingNotification,
    setIsShowPollingNotification,
  } = useSubscriptionPolling({ workspaceId: selectedWorkspace });
  const {
    data: subscription,
    error: subscriptionError,
    isLoading: isSubscriptionLoading,
    refetch: refetchSubscription,
  } = useSubscription({
    workspaceId: selectedWorkspace,
    options: {
      refetchInterval: isSubscriptionPollingEnabled ? 4000 : false, // refetch subscription status every 4 sec
    },
  });

  const availableWorkspaces = getAvailableWorkspaces({
    workspaces: workspacesList?.workspaces || [],
    workspacesRoleMap,
  });

  React.useEffect(() => {
    if (selectedWorkspace) {
      refetchSubscription();
    }
  }, [refetchSubscription, selectedWorkspace]);

  React.useEffect(() => {
    if (!selectedWorkspace && availableWorkspaces.length === 1) {
      setSelectedWorkspace(availableWorkspaces[0].id.toString());
    }
  }, [
    availableWorkspaces,
    selectedWorkspace,
    workspacesList?.workspaces,
    workspacesRoleMap,
  ]);

  const handleSuccessfulPayment: SuccessfulPaymentCallbackArgs = ({
    plan,
    status,
    members,
    projects,
  }) => {
    const expectedSubscription = {
      plan: plan || subscription?.type,
      status: status || subscription?.status,
      members: members || subscription?.members,
      projects: projects || subscription?.projects,
    };
    setIsShowPollingNotification(true);
    setTimeout(() => {
      enablePolling({
        expectedSubscription,
      });
    }, 2000); // wait 2 sec before polling so there is a larger chance the subscription update is ready
  };

  if (isWorkspacesListLoading || isSubscriptionLoading) {
    return (
      <>
        <Page__Header title="Billing" />

        <Page__Content>
          <Flex gap="4" wrap="wrap">
            <Box maxWidth="340px">
              <StarterPlanBasicCard isLoading={true} />
            </Box>
            <Box maxWidth="340px">
              <TeamsPlanBasicCard isLoading={true} />
            </Box>
          </Flex>
        </Page__Content>
      </>
    );
  }

  if (workspacesListError || subscriptionError) {
    return (
      <>
        <Page__Header title="Billing"></Page__Header>

        <Page__Content>
          {!!workspacesListError && <ErrorApi error={workspacesListError} />}

          {!!subscriptionError && <ErrorApi error={subscriptionError} />}
        </Page__Content>
      </>
    );
  }

  if (!selectedWorkspace) {
    return (
      <>
        <Page__Header
          title={
            <Flex align="center" gap="4" wrap="wrap">
              Billing
              <WorkspacesSelect
                value={selectedWorkspace}
                onChange={setSelectedWorkspace}
                workspaces={availableWorkspaces}
              />
            </Flex>
          }
        ></Page__Header>

        <Page__Content>
          <Box maxWidth="600px">
            <Callout color="gold">
              Please select a workspace to view its billing information
            </Callout>
          </Box>
        </Page__Content>
      </>
    );
  }

  return (
    <>
      <Page__Header
        title={
          <Flex align="center" gap="4" wrap="wrap">
            Billing
            <WorkspacesSelect
              value={selectedWorkspace}
              onChange={setSelectedWorkspace}
              workspaces={availableWorkspaces}
            />
          </Flex>
        }
      >
        <AccessSubscription
          selectedWorkspaceId={selectedWorkspace}
          allowedStatuses={[SUBSCRIPTION_STATUS.CANCEL_REQUESTED]}
        >
          <SubscriptionReactivation
            workspaceId={selectedWorkspace}
            onSuccessfulPayment={handleSuccessfulPayment}
            subscription={subscription}
          />
        </AccessSubscription>

        <AccessSubscription
          plan={[PLANS.TEAMS, PLANS.STARTER]}
          selectedWorkspaceId={selectedWorkspace}
          allowedStatuses={[
            SUBSCRIPTION_STATUS.ACTIVE,
            SUBSCRIPTION_STATUS.PAST_DUE,
          ]}
        >
          <ManagePaymentMethod workspaceId={selectedWorkspace} />
        </AccessSubscription>

        <AccessSubscription
          plan={[PLANS.TEAMS, PLANS.STARTER]}
          selectedWorkspaceId={selectedWorkspace}
          allowedStatuses={[
            SUBSCRIPTION_STATUS.ACTIVE,
            SUBSCRIPTION_STATUS.PAST_DUE,
          ]}
        >
          <SubscriptionCancellation
            workspaceId={selectedWorkspace}
            onSuccessfulPayment={handleSuccessfulPayment}
            subscription={subscription}
          />
        </AccessSubscription>
      </Page__Header>

      <Page__Content>
        {isShowPollingNotification && (
          <Box mb="4" maxWidth="860px">
            <Callout color="plum">
              We're updating your subscription details. It usually doesn't take
              long, but sometimes things can take a bit more time.
            </Callout>
          </Box>
        )}

        <AccessSubscription
          allowedStatuses={[SUBSCRIPTION_STATUS.PAST_DUE]}
          selectedWorkspaceId={selectedWorkspace}
        >
          <Box mb="4" maxWidth="860px">
            <Callout color="tomato">
              Your subscription payment is overdue. Please update your payment
              method to avoid service interruption.
            </Callout>
          </Box>
        </AccessSubscription>

        <Box p="2">
          <Flex gap="4" wrap="wrap">
            <CardList
              selectedWorkspace={selectedWorkspace}
              subscription={subscription}
              onSuccessfulPayment={handleSuccessfulPayment}
            />

            <AccessSubscription
              plan={[PLANS.TEAMS, PLANS.STARTER]}
              selectedWorkspaceId={selectedWorkspace}
              allowedStatuses={[
                SUBSCRIPTION_STATUS.ACTIVE,
                SUBSCRIPTION_STATUS.PAST_DUE,
              ]}
            >
              <SubscriptionInfoCard subscription={subscription} />
            </AccessSubscription>

            <MembersAndProjectsSetup
              selectedWorkspace={selectedWorkspace}
              onSuccessfulPayment={handleSuccessfulPayment}
              subscription={subscription}
            />
          </Flex>
        </Box>
      </Page__Content>
    </>
  );
}
