import { IconButton, Inset, Popover } from '@radix-ui/themes';
import { MagnifyingGlassIcon } from '@radix-ui/react-icons';

type ImageMagnifyProps = {
  src: string;
  alt: string;
};

export function ImageMagnify({ src, alt }: ImageMagnifyProps) {
  return (
    <Popover.Root>
      <Popover.Trigger>
        <IconButton color="plum" size="1" title="Expand">
          <MagnifyingGlassIcon />
        </IconButton>
      </Popover.Trigger>
      <Popover.Content className="image-magnify__content">
        <Inset clip="padding-box">
          <img className="image-magnify__img" src={src} alt={alt} />
        </Inset>
      </Popover.Content>
    </Popover.Root>
  );
}
