import React from 'react';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { Badge, Table } from '@radix-ui/themes';
import './FieldsTable.css';
import { COMPONENTS_HEADLINES, FieldType } from 'src/constants/components';

export type FieldsTableType = {
  label: string;
  name: string;
  type: FieldType;
};

const columnHelper = createColumnHelper<FieldsTableType>();

type FieldsTableProps = {
  data: FieldsTableType[];
};

export function FieldsTable(props: FieldsTableProps) {
  const { data } = props;

  const columns = React.useMemo(
    () => [
      columnHelper.accessor('label', {
        cell: (info) => {
          const label = info.getValue();

          return <span>{label}</span>;
        },
        header: () => <span>Label</span>,
      }),
      columnHelper.accessor('name', {
        cell: (info) => {
          const name = info.getValue();

          return <span>{name}</span>;
        },
        header: () => <span>Name</span>,
      }),
      columnHelper.accessor('type', {
        cell: (info) => {
          const type = info.getValue();

          return <Badge color="gray">{COMPONENTS_HEADLINES[type].badge}</Badge>;
        },
        header: () => <span>Type</span>,
      }),
    ],
    []
  );
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <>
      <Table.Root variant="surface" className="fields-table">
        <Table.Header>
          {table.getHeaderGroups().map((headerGroup, headerGroupIndex) => (
            <Table.Row key={headerGroupIndex}>
              {headerGroup.headers.map((header, headerIndex) => (
                <Table.ColumnHeaderCell key={headerIndex}>
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                </Table.ColumnHeaderCell>
              ))}
            </Table.Row>
          ))}
        </Table.Header>
        <Table.Body>
          {table.getRowModel().rows?.length ? (
            table.getRowModel().rows.map((row, rowIndex) => (
              <Table.Row key={rowIndex}>
                {row.getVisibleCells().map((cell, cellIndex) => (
                  <Table.Cell key={cellIndex}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </Table.Cell>
                ))}
              </Table.Row>
            ))
          ) : (
            <Table.Row>
              <Table.Cell colSpan={columns.length}>No results</Table.Cell>
            </Table.Row>
          )}
        </Table.Body>
      </Table.Root>
    </>
  );
}
