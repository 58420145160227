import React from 'react';
import { Combobox } from 'src/components/Combobox';
import { Controller } from 'react-hook-form';
import { ErrorMessage } from 'src/components/ErrorMessage';
import { Box } from '@radix-ui/themes';
import { Control, FieldValues } from 'react-hook-form';
import { StringObject } from 'src/types/Common';
import { useItemsHeadersList } from 'src/hooks/api/useItemsHeadersList';
import { getFilteredOptions } from 'src/utils/getFilteredOptions';

type ControlType = Control<FieldValues, StringObject>;

type ItemsComboboxProps = {
  control: ControlType;
  errorMessage?: string | undefined;
  templateId: string;
  id: string;
  required?: boolean;
  label: string;
  onSelect?: (data: { id: string; label: string }) => void;
};

export function ItemsCombobox({
  control,
  errorMessage,
  templateId,
  id,
  required,
  label,
  onSelect,
}: ItemsComboboxProps) {
  const [query, setQuery] = React.useState('');
  const { data } = useItemsHeadersList({ templateId });

  const options = data?.items?.map((item) => ({
    id: item.id,
    label: item.internal_label,
  }));

  const getSelectedOption = (value: string | null) => {
    if (!options) {
      return null;
    }

    const selectedOption = options.find((option) => option.id === value);
    return selectedOption || null;
  };

  return (
    <Controller
      render={({ field: { value, onChange } }) => {
        return (
          <>
            <Combobox
              onChange={(value) => {
                onChange(value?.id);
                if (value) {
                  onSelect?.({ id: value.id.toString(), label: value.label });
                }
              }}
              onInputChange={setQuery}
              value={getSelectedOption(value)}
              inputValue={query}
              options={getFilteredOptions({
                value: query,
                options: options || [],
              })}
              label={label}
              placeholder="Search"
              nullable
              required={required}
            />

            {errorMessage && (
              <Box mt="2">
                <ErrorMessage>{errorMessage}</ErrorMessage>
              </Box>
            )}
          </>
        );
      }}
      control={control}
      name={id}
    />
  );
}
