import { ResponseItemField } from 'src/types/Item';

export function getItemMap({ data = [] }: { data: ResponseItemField[] }) {
  const map: Map<string, number | string | number[]> = new Map();
  data?.forEach((item) => {
    map.set(item.template_field_id, item.value);
  });

  return map;
}
