import React from 'react';
import { Cross1Icon, MixerVerticalIcon } from '@radix-ui/react-icons';
import { Button, Flex, IconButton } from '@radix-ui/themes';
import { FilterTag } from '../FilterTag';
import { FilterPopup } from '../FilterPopup';
import { FormData } from '../FilterPopup/types';
import { useItemsTableContext } from '../../providers/ItemsTableProvider';
import { v4 as uuidv4 } from 'uuid';
import type { Filter } from 'src/types/Filter';
import { getCustomFieldFilterById } from '../../utils/customFieldFilter';

export function Filter() {
  const { filter, setFilter, templateFields } = useItemsTableContext();

  const handleApply = (data: FormData) => {
    setFilter([
      ...filter,
      {
        internalId: uuidv4(),
        fieldId: data.fieldId,
        condition: data.conditionId,
        value: data.value,
      },
    ]);
  };

  const handleClearAllFilters = () => {
    setFilter([]);
  };

  const handleRemoveFilter = (value: string) => {
    const updatedFilter = filter.filter((item) => item.internalId !== value);
    setFilter([...updatedFilter]);
  };

  const templateFieldsMap = templateFields.reduce((map, item) => {
    map.set(item.fieldId, item);
    return map;
  }, new Map());

  const getTagLabel = (item: Filter) => {
    const tagLabel = templateFieldsMap.get(item.fieldId)?.label;

    if (tagLabel) {
      return tagLabel;
    }

    const customField = getCustomFieldFilterById({ fieldId: item.fieldId });
    const customFieldTag = customField?.label;

    return customFieldTag || '';
  };

  return (
    <Flex gap="6" justify="between" align="start">
      <Flex gap="3">
        <FilterPopup
          onSubmit={handleApply}
          trigger={
            <IconButton
              color="gold"
              variant="ghost"
              size="2"
              title="Add filter"
              mt="1"
            >
              <MixerVerticalIcon />
            </IconButton>
          }
        />

        <Flex gap="3" wrap="wrap">
          {filter.map((item, index) => (
            <FilterTag
              internalId={item.internalId}
              fieldId={item.fieldId}
              key={index}
              value={item.value}
              label={getTagLabel(item)}
              condition={item.condition}
              onRemove={handleRemoveFilter}
            />
          ))}
        </Flex>
      </Flex>

      {!!filter.length && (
        <Flex minHeight="27px" align="center" flexShrink="0" pr="6">
          <Button
            type="button"
            size="1"
            variant="ghost"
            color="gold"
            onClick={handleClearAllFilters}
          >
            <Cross1Icon width="12px" /> Clear filters
          </Button>
        </Flex>
      )}
    </Flex>
  );
}
