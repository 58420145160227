import jwtDecode, { type JwtPayload } from 'jwt-decode';
import Cookies from 'js-cookie';
import { handleUnauthorizedError, refreshToken } from './fetcher';

export const getAccessToken = () => {
  return Cookies.get('x-lootf-auth');
};

export const getRefreshToken = () => {
  return Cookies.get('x-lootf-refresh');
};

export const isNeedToRefreshToken = () => {
  const token = getAccessToken();
  if (!token) {
    return true;
  }

  const decodedToken: JwtPayload = jwtDecode(token);
  const expiration = decodedToken.exp;
  if (!expiration) {
    return true;
  }

  const currentTimestamp = Math.floor(Date.now() / 1000);
  const bufferTime = 300; // 5 minutes

  return expiration - currentTimestamp < bufferTime;
};

export const getTokenData = () => {
  const token = getAccessToken();

  return jwtDecode(token || '');
};

export const isAccessTokenExpired = () => {
  const token = getAccessToken();
  if (!token) {
    return true;
  }
  const decodedToken: JwtPayload = jwtDecode(token);
  const expiration = decodedToken.exp;
  if (!expiration) {
    return true;
  }

  // Get the current timestamp in seconds
  const currentTimestamp = Math.floor(Date.now() / 1000);

  return expiration < currentTimestamp;
};

export const isRefreshTokenExpired = () => {
  const token = getRefreshToken();
  if (!token) {
    return true;
  }
  const decodedToken: JwtPayload = jwtDecode(token);
  const expiration = decodedToken.exp;
  if (!expiration) {
    return true;
  }

  // Get the current timestamp in seconds
  const currentTimestamp = Math.floor(Date.now() / 1000);

  return expiration < currentTimestamp;
};

let isFetching = false;
let refreshPromise: Promise<unknown> | null = null;

export const checkToken = async (url: string) => {
  if (!isNeedToRefreshToken()) {
    return;
  }

  // if refresh is already in progress, wait for it to complete
  if (isFetching) {
    return refreshPromise;
  }

  try {
    isFetching = true;
    refreshPromise = refreshToken().then(async (result) => {
      const data = await result.json();

      if (result.status === 401) {
        handleUnauthorizedError(url, data?.error);
        return;
      }

      if (!result.ok) {
        throw data;
      }

      return data;
    });

    return await refreshPromise;
  } finally {
    // reset fetching flag and clear promise
    isFetching = false;
    refreshPromise = null;
  }
};
