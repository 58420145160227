import React, { FormEvent } from 'react';
import {
  Flex,
  Text,
  Card,
  Box,
  Progress,
  IconButton,
  Button,
} from '@radix-ui/themes';
import * as Label from '@radix-ui/react-label';
import { Input } from 'src/components/Input';
import { MinusIcon, PlusIcon } from '@radix-ui/react-icons';
import pluralize from 'pluralize';
import { PlanTypePaid, PRICES } from 'src/constants/plans';
import { useSubscriptionMembersUpdatePreview } from 'src/hooks/api/useSubscriptionMembersUpdatePreview';
import { MemberPricingDialog } from './MemberPricingDialog';
import { SuccessfulPaymentCallbackArgs } from '../types';

export const MEMBERS_INCLUDED_IN_PLAN = 1;

type MemberPricingProps = {
  paidSeats: number;
  usedSeats: number;
  plan: PlanTypePaid;
  workspaceId: string;
  onSuccessfulPayment: SuccessfulPaymentCallbackArgs;
};

export function MemberPricing({
  usedSeats,
  paidSeats,
  plan,
  workspaceId,
  onSuccessfulPayment,
}: MemberPricingProps) {
  const [isDialogOpen, setIsDialogOpen] = React.useState(false);
  const paidAdditionalSeats = Math.min(
    paidSeats,
    paidSeats - MEMBERS_INCLUDED_IN_PLAN
  );
  const [newAdditionalSeats, setNewAdditionalSeats] =
    React.useState(paidAdditionalSeats);

  const {
    mutate: membersPricePreview,
    data: membersPricePreviewData,
    isMutating: isMembersPricePreviewLoading,
    error: membersPricePreviewError,
  } = useSubscriptionMembersUpdatePreview();

  const handleIncreaseMembers = () => {
    setNewAdditionalSeats(newAdditionalSeats + 1);
  };

  const handleDecreaseMembers = () => {
    setNewAdditionalSeats(Math.max(newAdditionalSeats - 1, 0));
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    membersPricePreview({
      new_value: newAdditionalSeats,
      workspaceId,
    });
    setIsDialogOpen(true);
  };

  const handleSuccessfulUpdate = (membersValue: number) => {
    onSuccessfulPayment({ members: membersValue });
  };

  const membersDiff = newAdditionalSeats - paidAdditionalSeats;
  const planPrice = PRICES[plan];

  return (
    <>
      <MemberPricingDialog
        isLoading={isMembersPricePreviewLoading}
        membersPricePreview={membersPricePreviewData}
        workspaceId={workspaceId}
        membersDiff={membersDiff}
        isDialogOpen={isDialogOpen}
        setIsDialogOpen={setIsDialogOpen}
        newAdditionalSeats={newAdditionalSeats}
        subscriptionPreviewError={membersPricePreviewError}
        onSuccessfulUpdate={handleSuccessfulUpdate}
      />
      <Card variant="surface" size="3">
        <form onSubmit={handleSubmit} data-testid="paid-members-submit-form">
          <Flex mb="2" justify="between">
            <Text as="div" size="4" weight="bold">
              Members
            </Text>
          </Flex>

          <Box mt="4">
            <Text color="gray">
              You are using {usedSeats} out of {paidSeats}{' '}
              {pluralize('member', paidSeats)}
            </Text>

            <Progress
              mt="2"
              variant="soft"
              value={Math.round((usedSeats / paidSeats) * 100)}
            />
          </Box>

          <Flex mt="5" align="center" justify="between" gap="2">
            <Label.Root htmlFor="paidUsers">
              <Text>Paid additional members</Text>
            </Label.Root>

            <Box maxWidth="100px">
              <Input
                mods={{ align: 'center' }}
                value={newAdditionalSeats.toString()}
                onChange={(e) => setNewAdditionalSeats(Number(e.target.value))}
                type="number"
                id="paidUsers"
                data-testid="paid-members-input"
                leftSlot={
                  <IconButton
                    onClick={handleDecreaseMembers}
                    variant="ghost"
                    type="button"
                    title="Decrease members"
                    data-testid="paid-members-decrease"
                  >
                    {<MinusIcon />}
                  </IconButton>
                }
                rightSlot={
                  <IconButton
                    onClick={handleIncreaseMembers}
                    variant="ghost"
                    type="button"
                    title="Increase members"
                    data-testid="paid-members-increase"
                  >
                    {<PlusIcon />}
                  </IconButton>
                }
              />
            </Box>
          </Flex>

          {membersDiff === 0 ? (
            <Box mt="2" height="24px" />
          ) : (
            <Box mt="2">
              <Text size="3">
                <Flex align="center" justify="between">
                  {membersDiff > 0 ? (
                    <>
                      <span>
                        Bill increase{' '}
                        <Text size="1" color="gray">
                          (taxes may apply)
                        </Text>
                      </span>
                      <Text color="plum">+${membersDiff * planPrice}</Text>
                    </>
                  ) : (
                    <>
                      <span>
                        Bill decrease{' '}
                        <Text size="1" color="gray">
                          (taxes may apply)
                        </Text>
                      </span>{' '}
                      <Text color="plum">
                        -${Math.abs(membersDiff) * planPrice}
                      </Text>
                    </>
                  )}
                </Flex>
              </Text>
            </Box>
          )}

          <Flex align="center" justify="center" mt="6">
            <Button
              style={{ width: '100%' }}
              color="plum"
              variant="soft"
              size="3"
              type="submit"
              disabled={membersDiff === 0}
              data-testid="paid-members-submit"
            >
              Update team size
            </Button>
          </Flex>
        </form>
      </Card>
    </>
  );
}
