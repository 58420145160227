import React from 'react';
import { Box, IconButton } from '@radix-ui/themes';
import { TrashIcon } from '@radix-ui/react-icons';
import { ErrorMessage } from 'src/components/ErrorMessage';
import { AlertDialog } from 'src/components/AlertDialog';
import { useInstanceContext } from 'src/providers/InstanceProvider';
import { LazyImage } from 'src/components/LazyImage';
import { getImageUrl } from 'src/utils/getImageUrl';
import { ImagePreview } from 'src/components/ImagePreview';
import { PLANS } from 'src/constants/plans';
import { useActiveSubscription } from 'src/hooks/useActiveSubscription';
import { RequiredMark } from 'src/components/RequiredMark';
import {
  ErrorsMapType,
  ImageDropZone,
  ImageType,
} from './components/ImageDropZone';
import { getErrorsText } from './utils/getErrorsText';
import { MAX_SIZE_MB } from './utils/maxSize';

type ImageUploadProps = {
  value?: string;
  label: string;
  required?: boolean;
  onChange: (value: string) => void;
  alt: string;
};

export function ImageUpload({
  value,
  label,
  required,
  onChange,
  alt,
}: ImageUploadProps) {
  const [isPreview, setIsPreview] = React.useState(false);
  const { subscription } = useActiveSubscription({});
  const { instanceId } = useInstanceContext();
  const imageSrc = getImageUrl({ instanceId, imagePath: value });
  const [image, setImage] = React.useState<string | null | undefined>(imageSrc);
  const [errors, setErrors] = React.useState<string[] | null>(null);
  const [isDialogOpen, setIsDialogOpen] = React.useState(false);
  const subscriptionType = subscription?.type || PLANS.FREE;
  const maxSizeMB = MAX_SIZE_MB[subscriptionType];

  React.useEffect(() => {
    const previewElement = document.querySelector('[data-preview-item-form]');
    if (previewElement) {
      setIsPreview(true);
    }
  }, []);

  const handleRemoveConfirmation = () => {
    setImage(null);
    setIsDialogOpen(false);
    onChange('');
  };

  const handleChange = (images: ImageType[]) => {
    const base64 = images[0].src;
    setImage(base64);
    const imageData = base64.replace(/^data:image\/[a-zA-Z]+;base64,/, '');
    onChange(isPreview ? base64 : imageData);
  };

  const handleError = (errorsMap: ErrorsMapType) => {
    const errorsArray = [...errorsMap.values()];
    setErrors(errorsArray[0]);
  };

  const errorsMessages = getErrorsText({ maxSizeMB });

  return (
    <div className="image-upload">
      <div className="image-upload__label">
        {label} {required && <RequiredMark />}
      </div>
      {!image && (
        <ImageDropZone
          onChange={handleChange}
          onError={handleError}
          maxSizeMB={maxSizeMB}
          invalid={!!errors?.length}
        />
      )}

      {!!errors?.length && (
        <Box mt="2">
          {errors.map((error, index) => {
            const errorText = errorsMessages[error]
              ? errorsMessages[error]
              : error;
            return <ErrorMessage key={index}>{errorText}</ErrorMessage>;
          })}
        </Box>
      )}

      {!!image && (
        <ImagePreview
          image={
            <LazyImage
              mix="image-upload__img"
              src={isPreview ? value : image}
              alt={alt}
            />
          }
        >
          <AlertDialog
            onAction={handleRemoveConfirmation}
            onCancel={() => setIsDialogOpen(false)}
            open={isDialogOpen}
            color="tomato"
            actionText="Yes, remove image"
            trigger={
              <IconButton
                className="image-upload__button"
                size="1"
                title="Delete image"
                color="tomato"
                onClick={() => setIsDialogOpen(true)}
              >
                <TrashIcon />
              </IconButton>
            }
          >
            Are you sure you want to remove image?
          </AlertDialog>
        </ImagePreview>
      )}
    </div>
  );
}
