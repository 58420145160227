import React from 'react';
import { Container, Heading, Flex, Box, Button, Link } from '@radix-ui/themes';
import { useForm, useFormContext } from 'react-hook-form';
import * as Sentry from '@sentry/react';
import { useNavigate } from 'react-router-dom';
import { Sidebar } from '../Sidebar';
import { TemplateTree } from '../TemplateTree';
import { AlertDialog } from 'src/components/AlertDialog';
import { Page__Header, Page__Content } from 'src/components/Page';
import { Toast } from 'src/components/Toast';
import {
  Tabs,
  Tabs__List,
  Tabs__Item,
  Tabs__Content,
} from 'src/components/Tabs';
import { Preview } from '../Preview';
import { DetailsForm } from '../DetailsForm';
import { validateTemplate } from 'src/utils/validateTemplate';
import {
  ApiTemplateResponse,
  TemplateField,
  TreeNodeFlat,
} from 'src/types/Template';
import { useTemplateTreeContext } from 'src/providers/TemplateTreeProvider';
import { getFlatDataFromTemplateTree } from 'src/utils/getFlatDataFromTemplateTree';
import { invalidNodesToMap } from 'src/utils/invalidNodesToMap';
import { FIELD_TYPE } from 'src/constants/components';
import { ItemFormProvider } from 'src/providers/ItemFormProvider';
import { expandTemplate } from 'src/utils/expandTemplate';
import { Json } from '../Json';
import { DndWrapper } from 'src/components/DndWrapper';
import { TrashArea } from '../TrashArea';
import { ScrollArea } from 'src/components/ScrollArea';
import { appRoutes } from 'src/utils/routePaths';
import { ApiError } from 'src/types/Api';
import { ErrorApi } from 'src/components/ErrorApi';
import { Access } from 'src/components/Access';
import { ROLES_LIST } from 'src/constants/workspaceRoles';
import { PERMISSIONS } from 'src/constants/permissions';
import { useInstanceContext } from 'src/providers/InstanceProvider';
import { FieldsReview } from '../FieldsReview';
import { convertTreeToTemplateFields } from 'src/utils/convertTreeToTemplateFields';
import { addStatusesToTemplateFields } from '../../utils/addStatusesToTemplateFields';
import { useShowMobileSidebar } from 'src/hooks/useShowMobileSidebar';
import { Callout } from 'src/components/Callout';
import { isImageTemplate } from 'src/utils/isImageTemplate';
import { OpenInNewWindowIcon } from '@radix-ui/react-icons';

const TABS_KEYS = {
  FIELDS: 'fields',
  PREVIEW: 'formPreview',
  DETAILS: 'details',
  JSON: 'jsonPreview',
};

type TemplateFormValues = {
  templateLabel: string;
  templateId: string;
};

type TemplateProps = {
  onSaveTemplate: () => void;
  isAlertOpen: boolean;
  setAlertOpen: (isOpen: boolean) => void;
  isNew: boolean;
  error?: ApiError | null;
  isSaving: boolean;
  initialTemplateFields?: TemplateField[];
  template?: ApiTemplateResponse;
};

export function Template(props: TemplateProps) {
  const { isShowMobileSidebar } = useShowMobileSidebar();
  const { instanceId } = useInstanceContext();
  const navigate = useNavigate();
  // this is needed to display form error validation in a tab once the user switches from the details section
  const isOnlyDetailsTabVisited = React.useRef(true);
  const {
    trigger,
    formState: { errors },
    getValues,
  } = useFormContext<TemplateFormValues>();
  const {
    onSaveTemplate,
    isAlertOpen,
    setAlertOpen,
    isNew,
    isSaving,
    initialTemplateFields,
    template,
  } = props;
  const [selectedTab, setSelectedTab] = React.useState(
    isNew ? TABS_KEYS.DETAILS : TABS_KEYS.FIELDS
  );
  const {
    templateTree,
    setTemplateTreeInvalidNodes,
    setTemplateTree,
    templateTreeInvalidNodes,
    setIsFormSubmitted,
  } = useTemplateTreeContext();

  const previewFormMethods = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  });

  const validate = async () => {
    setIsFormSubmitted(true);
    const invalidFields = await validateTemplate(templateTree);
    // validate details form
    await trigger();
    // show error badge in details tab
    isOnlyDetailsTabVisited.current = false;
    if (invalidFields.length || Object.keys(errors).length) {
      const invalidNodesMap = invalidNodesToMap({ data: invalidFields });
      setTemplateTreeInvalidNodes(invalidNodesMap);
      showToastForInvalidNodes(invalidFields);

      // expand tree
      const expandedTemplateTree = expandTemplate({ templateTree });
      setTemplateTree(expandedTemplateTree);

      return;
    }

    setAlertOpen(true);
  };

  function showToastForInvalidNodes(invalidFields: TreeNodeFlat[]) {
    invalidFields.forEach((item) => {
      const settings = item.node.settings;
      const isDynamicRowGroup = settings.type === FIELD_TYPE.DYNAMIC_GROUP_ROW;
      if (isDynamicRowGroup) {
        // if DYNAMIC_GROUP_ROW component is invalid, smth really weird happened, show global error
        const flatTemplate = getFlatDataFromTemplateTree({ templateTree });
        const sentryEventId = Sentry.captureException({
          error: `${FIELD_TYPE.DYNAMIC_GROUP_ROW} validation error`,
          invalidFields: JSON.stringify(invalidFields),
          flatTemplate: JSON.stringify(flatTemplate),
        });
        navigate(appRoutes.error(), { state: { sentryEventId } });

        return;
      }
      Toast({
        text: `Field ${settings.name} is invalid`,
        variant: 'error',
      });
    });
  }

  const handleTabSelection = (value: string) => {
    if (isOnlyDetailsTabVisited.current) {
      isOnlyDetailsTabVisited.current = false;
    }
    trigger();
    setSelectedTab(value);
  };

  const getPreviewFields = () => {
    const templateData = convertTreeToTemplateFields({ templateTree });

    const fields = addStatusesToTemplateFields({
      initialData: initialTemplateFields || [],
      currentData: templateData,
    });

    return fields;
  };

  const detailsTabError =
    Object.keys(errors).length && !isOnlyDetailsTabVisited.current
      ? 'complete fields'
      : '';

  const fieldsTabError = templateTreeInvalidNodes.size ? 'check fields' : '';
  const templateLabel = getValues('templateLabel');
  const previewFields = getPreviewFields();

  return (
    <ItemFormProvider>
      <DndWrapper>
        <Page__Header
          headingSuffix={
            <Link
              color="blue"
              size="1"
              underline="always"
              href="https://docs.lootfabriq.io/guides/components/"
              rel="noopener noreferrer"
              target="_blank"
            >
              <Flex align="center" display="inline-flex" gap="1">
                Learn more about components <OpenInNewWindowIcon width="12px" />
              </Flex>
            </Link>
          }
          backButtonUrl={
            isNew
              ? '/'
              : appRoutes.items({
                  instanceId,
                  templateId: getValues('templateId'),
                })
          }
          title={`${
            isNew ? 'Create template' : `Edit template ${templateLabel}`
          }`}
          dataTestId="template-headline"
        >
          <Access
            roles={ROLES_LIST}
            permissionsGroups={[[PERMISSIONS.CREATE_TEMPLATE]]}
          >
            <AlertDialog
              open={isAlertOpen}
              title="Save template changes"
              trigger={
                <Button
                  variant="soft"
                  color="plum"
                  onClick={validate}
                  data-testid="save-template"
                  loading={isSaving}
                >
                  Save template
                </Button>
              }
              actionText="Yes, save changes"
              cancelText="No, keep editing"
              onCancel={() => setAlertOpen(false)}
              onAction={onSaveTemplate}
            >
              {previewFields.length ? (
                <>
                  Review the changes below carefully, especially any fields that
                  are being removed.
                  <Box mt="3" mb="5">
                    {isAlertOpen && <FieldsReview fields={previewFields} />}
                  </Box>
                  Any added, updated, or removed fields will impact all existing
                  items. Be sure to understand the changes before saving.
                </>
              ) : (
                <>Do you want to save the changes?</>
              )}
            </AlertDialog>
          </Access>

          {isShowMobileSidebar && (
            <Box>
              <Callout color="tomato">
                Creating templates requires drag-and-drop functionality, which
                isn't supported on mobile devices. Please use a desktop to
                create templates.
              </Callout>
            </Box>
          )}
        </Page__Header>
        <Page__Content
          mix={`template__content ${selectedTab === TABS_KEYS.FIELDS ? '' : 'template__content_overflow_hidden'}`}
          hasScrollArea={false}
        >
          <Tabs
            mix={`template__tabs ${selectedTab === TABS_KEYS.FIELDS ? '' : 'template__tabs_overflow_hidden'}`}
            value={selectedTab}
            onValueChange={handleTabSelection}
          >
            <Box overflow="auto">
              {!!props.error && (
                <Box mb="2">
                  <ErrorApi error={props.error} />
                </Box>
              )}

              {!!isImageTemplate(template) && (
                <Box mb="2" maxWidth="900px">
                  <Callout color="tomato">
                    This is&nbsp;a&nbsp;reserved template and cannot
                    be&nbsp;modified. Any changes to&nbsp;this template are
                    restricted to&nbsp;ensure its integrity.
                  </Callout>
                </Box>
              )}

              <Flex align="center" justify="between">
                <Tabs__List ariaLabel="Template settings">
                  <Tabs__Item
                    value={TABS_KEYS.DETAILS}
                    error={detailsTabError}
                    dataTestId="template-tab-details"
                  >
                    Details
                  </Tabs__Item>
                  <Tabs__Item
                    value={TABS_KEYS.FIELDS}
                    error={fieldsTabError}
                    dataTestId="template-tab-fields"
                  >
                    Fields
                  </Tabs__Item>
                  <Tabs__Item
                    value={TABS_KEYS.PREVIEW}
                    dataTestId="template-tab-preview"
                  >
                    Preview form
                  </Tabs__Item>
                  <Tabs__Item
                    value={TABS_KEYS.JSON}
                    dataTestId="template-tab-json"
                  >
                    JSON preview
                  </Tabs__Item>
                </Tabs__List>
              </Flex>
            </Box>

            <Tabs__Content
              value={TABS_KEYS.DETAILS}
              forceMount={true}
              hidden={selectedTab !== TABS_KEYS.DETAILS}
            >
              <Container size="1" display="initial" align="left" mt="4">
                <DetailsForm isNew={isNew} />
              </Container>
            </Tabs__Content>

            <Tabs__Content
              value={TABS_KEYS.FIELDS}
              asChild
              forceMount={true}
              hidden={selectedTab !== TABS_KEYS.FIELDS}
            >
              <div className="template__edit-tab">
                <aside className="template__aside">
                  <Box mt="4">
                    <Sidebar mix="template__sidebar" />
                  </Box>
                </aside>

                <div className="template__tree-container">
                  <TrashArea mix="template__trash-area" />
                  <TemplateTree />
                </div>
              </div>
            </Tabs__Content>

            <Tabs__Content
              value={TABS_KEYS.PREVIEW}
              mix="template__tab-content"
            >
              <ScrollArea>
                <Container mt="4" size="1" display="initial" align="left">
                  <Heading mb="4">{templateLabel}</Heading>
                  <Preview methods={previewFormMethods} />
                </Container>
              </ScrollArea>
            </Tabs__Content>

            <Tabs__Content value={TABS_KEYS.JSON} mix="template__tab-content">
              <ScrollArea>
                <Container size="2" display="initial" align="left" mt="4">
                  <Json formData={previewFormMethods.getValues()} />
                </Container>
              </ScrollArea>
            </Tabs__Content>
          </Tabs>
        </Page__Content>
      </DndWrapper>
    </ItemFormProvider>
  );
}
