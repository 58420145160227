import React from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import { useForm, FormProvider } from 'react-hook-form';
import { Template } from './components/Template';
import { useSaveTemplate } from 'src/hooks/api/useSaveTemplate';
import { Toast } from 'src/components/Toast';
import {
  TemplateTreeProvider,
  useTemplateTreeContext,
} from 'src/providers/TemplateTreeProvider';
import { GET_MENU_KEY } from 'src/hooks/api/useMenu';
import { useInstanceContext } from 'src/providers/InstanceProvider';
import { validationSchema } from './utils/newTemplateValidationSchema';
import { convertTreeToTemplateFields } from 'src/utils/convertTreeToTemplateFields';
import { addStatusesToTemplateFields } from 'src/pages/template/utils/addStatusesToTemplateFields';
import { getOrderedTreeFromFlatData } from 'src/utils/getOrderedTreeFromFlatData';
import { TemplateField } from 'src/types/Template';
import { appRoutes } from 'src/utils/routePaths';
import { TEMPLATE_BY_ID_KEY } from 'src/hooks/api/useTemplateById';

type TemplateNewProps = {
  initialFields?: TemplateField[];
};

function New({ initialFields }: TemplateNewProps) {
  const methods = useForm({
    criteriaMode: 'all',
    delayError: 250,
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
    reValidateMode: 'onChange',
  });
  const [isAlertOpen, setAlertOpen] = React.useState(false);
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const {
    mutate: createTemplate,
    error: templateSaveError,
    isMutating: isTemplateSaving,
  } = useSaveTemplate({
    onSuccess: onSuccessSave,
  });
  const { templateTree, setTemplateTree } = useTemplateTreeContext();
  const { instanceId } = useInstanceContext();
  const { trigger } = methods;

  // handle clone template on mount
  React.useEffect(() => {
    if (initialFields && !templateTree.length) {
      const orderedTree = getOrderedTreeFromFlatData({
        fields: initialFields,
      });
      setTemplateTree(orderedTree);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    // trigger validation on mount
    trigger('templateId');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSaveTemplate = async () => {
    setAlertOpen(false);
    const templateData = convertTreeToTemplateFields({ templateTree });

    const fields = addStatusesToTemplateFields({
      initialData: [],
      currentData: templateData,
    });

    const templateLabel = methods.getValues('templateLabel');
    const templateId = methods.getValues('templateId');

    createTemplate({
      fields,
      label: templateLabel,
      id: templateId,
      version: 0,
    });
  };

  function onSuccessSave() {
    const templateId = methods.getValues('templateId');
    // update navigation
    queryClient.invalidateQueries({ queryKey: [GET_MENU_KEY] });
    queryClient.invalidateQueries({
      queryKey: [TEMPLATE_BY_ID_KEY],
    });

    const url = appRoutes.items({ instanceId, templateId });
    navigate(url);

    Toast({ text: 'Template created', variant: 'success' });
  }

  return (
    <FormProvider {...methods}>
      <Template
        isNew={true}
        isAlertOpen={isAlertOpen}
        setAlertOpen={setAlertOpen}
        onSaveTemplate={handleSaveTemplate}
        error={templateSaveError}
        isSaving={isTemplateSaving}
      />
    </FormProvider>
  );
}

export function TemplateNew({ initialFields }: TemplateNewProps) {
  return (
    <TemplateTreeProvider>
      <New initialFields={initialFields} />
    </TemplateTreeProvider>
  );
}
