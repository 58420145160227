import * as yup from 'yup';
import { FieldType } from '../../../utils/getFieldsList';

export const validationSchema = yup.object().shape({
  fieldId: yup.string().required('required field'),
  conditionId: yup.string().required('required field'),
  value: yup
    .string()
    .required('Value is required')
    .when('fieldId', (fieldIds, schema, { context }) => {
      const selectedFieldId = fieldIds[0];
      const fields: FieldType[] = context?.fields || [];
      const selectedField = fields.find(
        (item: FieldType) => item.fieldId === selectedFieldId
      );

      if (selectedField) {
        switch (selectedField.type) {
          case 'integer':
            return yup
              .number()
              .typeError('should be a number')
              .integer('should be integer');
          case 'float':
            return yup
              .number()
              .typeError("should be a number with '.' as a decimal separator");
          default:
            return schema;
        }
      }

      return schema;
    }),
});
