import React from 'react';
import { PlusIcon } from '@radix-ui/react-icons';
import { ApiWorkspacesListResponse } from 'src/types/Workspace';
import { Box, Button, Card, Flex, Text } from '@radix-ui/themes';
import { WorkspaceCreation } from 'src/components/WorkspaceCreation';
import { ProjectCreation } from 'src/components/ProjectCreation';
import { Link as RouterLink } from 'react-router-dom';
import { appRoutes } from 'src/utils/routePaths';
import { useInstanceContext } from 'src/providers/InstanceProvider';

type OnboardingProps = {
  workspacesData?: ApiWorkspacesListResponse;
  isMember: boolean;
  hasProjects: boolean;
  hasWorkspaces: boolean;
  hasTemplates: boolean;
};

export function Onboarding({
  hasWorkspaces,
  isMember,
  hasProjects,
  hasTemplates,
}: OnboardingProps) {
  const { instanceId } = useInstanceContext();

  if (!hasWorkspaces) {
    return (
      <Box maxWidth="390px" width="100%">
        <Card variant="surface" size="2">
          <Box>
            <Flex align="center" gap="2">
              <Flex align="center" gap="2">
                <Text size="3" weight="medium">
                  Set up your workspace
                </Text>
              </Flex>
            </Flex>
            <Box mt="2">
              <Text size="3" color="gray">
                Start by creating a workspace to organize your work. Once it's
                ready, you can begin your first project.
              </Text>
            </Box>

            <Box mt="4">
              <WorkspaceCreation
                trigger={
                  <Button
                    variant="soft"
                    color="plum"
                    data-testid="create-project"
                  >
                    <PlusIcon /> Create workspace
                  </Button>
                }
              />
            </Box>
          </Box>
        </Card>
      </Box>
    );
  }

  if (hasWorkspaces && !isMember && !hasProjects) {
    return (
      <Box maxWidth="390px" width="100%">
        <Card variant="surface" size="2">
          <Box>
            <Flex align="center" gap="2">
              <Text size="3" weight="medium">
                Create your first project
              </Text>
            </Flex>
            <Box mt="2">
              <Text size="3" color="gray">
                Start by creating your first project to begin working with
                templates and items.
              </Text>
            </Box>

            <Box mt="4">
              <ProjectCreation />
            </Box>
          </Box>
        </Card>
      </Box>
    );
  }

  if (!hasTemplates && hasProjects) {
    return (
      <Box maxWidth="390px" width="100%">
        <Card variant="surface" size="2">
          <Box>
            <Flex align="center" gap="2">
              <Text size="3" weight="medium">
                Create your first template
              </Text>
            </Flex>
            <Box mt="2">
              <Text size="3" color="gray">
                Start by creating your first template to begin working with
                items.
              </Text>
            </Box>

            <Box mt="4">
              <Button variant="soft" color="plum" asChild>
                <RouterLink to={appRoutes.templateNew({ instanceId })}>
                  <PlusIcon /> Create template
                </RouterLink>
              </Button>
            </Box>
          </Box>
        </Card>
      </Box>
    );
  }

  return null;
}
