import React from 'react';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { Table, Box, Flex } from '@radix-ui/themes';
import { LazyImage } from 'src/components/LazyImage';
import { ImagePreview } from 'src/components/ImagePreview';
import { RenameImage } from './RenameImage';
import { RemoveImage } from './RemoveImage';
import { ApiError } from 'src/types/Api';
import { ErrorApi } from 'src/components/ErrorApi';

type ImagesListTableType = {
  id: string;
  name: string;
  src: string;
};

const columnHelper = createColumnHelper<ImagesListTableType>();

type ImagesListTableProps = {
  data: ImagesListTableType[];
  onRename: (props: { id: string; value: string }) => void;
  onRemove: (is: string) => void;
  errorsMap: Map<string, ApiError>;
};

export function ImagesListTable(props: ImagesListTableProps) {
  const { data, onRename, onRemove, errorsMap } = props;

  const columns = React.useMemo(
    () => [
      columnHelper.accessor('name', {
        cell: (info) => {
          const name = info.getValue();
          const id = info.row.original.id;

          return (
            <Flex align="center" gap="4">
              {name} <RenameImage id={id} name={name} onChange={onRename} />
            </Flex>
          );
        },
        header: () => <span>Item name</span>,
      }),
      columnHelper.accessor('src', {
        cell: (info) => {
          const src = info.getValue();
          const name = info.row.original.name;
          const id = info.row.original.id;
          const error = errorsMap.get(id);

          return (
            <Box maxWidth="400px">
              {!!error && (
                <Box mb="3">
                  <ErrorApi error={error} />
                </Box>
              )}
              <ImagePreview
                image={
                  <LazyImage mix="image-upload__img" src={src} alt={name} />
                }
              />
            </Box>
          );
        },
        header: () => <span>Image</span>,
      }),
      columnHelper.accessor('id', {
        cell: (info) => {
          const id = info.row.original.id;

          return (
            <Flex justify="end">
              <RemoveImage id={id} onRemove={onRemove} />
            </Flex>
          );
        },
        header: () => <span></span>,
      }),
    ],
    [errorsMap, onRemove, onRename]
  );
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <>
      <Table.Root variant="surface">
        <Table.Header>
          {table.getHeaderGroups().map((headerGroup, headerGroupIndex) => (
            <Table.Row key={headerGroupIndex}>
              {headerGroup.headers.map((header, headerIndex) => (
                <Table.ColumnHeaderCell
                  key={headerIndex}
                  {...(header.id === 'id' || header.id === 'created_at'
                    ? { width: `${header.getSize()}px` }
                    : {})}
                >
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                </Table.ColumnHeaderCell>
              ))}
            </Table.Row>
          ))}
        </Table.Header>
        <Table.Body>
          {table.getRowModel().rows?.length ? (
            table.getRowModel().rows.map((row, rowIndex) => (
              <Table.Row key={rowIndex}>
                {row.getVisibleCells().map((cell, cellIndex) => (
                  <Table.Cell key={cellIndex}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </Table.Cell>
                ))}
              </Table.Row>
            ))
          ) : (
            <Table.Row>
              <Table.Cell colSpan={columns.length}>No images</Table.Cell>
            </Table.Row>
          )}
        </Table.Body>
      </Table.Root>
    </>
  );
}
