import type { FileDropItem } from 'react-aria';

export const ACCEPTED_FILE_TYPE = ['image/png', 'image/jpeg'];

export const ERROR_TYPE = {
  SIZE: 'size',
  EXTENSION: 'extension',
};

export async function validateImage(
  file: FileDropItem | File,
  maxSizeMB: number
) {
  const errors: string[] = [];

  // Validate file type
  if (!validateType(file)) {
    errors.push(ERROR_TYPE.EXTENSION);
  }

  // Validate file size
  const isValidSize = await validateSize(file, maxSizeMB);
  if (!isValidSize) {
    errors.push(ERROR_TYPE.SIZE);
  }

  return errors;
}

export function validateType(file: FileDropItem | File) {
  return ACCEPTED_FILE_TYPE.includes(file.type);
}

export async function validateSize(
  item: FileDropItem | File,
  maxSizeMB: number
) {
  let size: number;

  if (item instanceof File) {
    size = item.size;
  } else {
    const file = await item.getFile();
    size = file.size;
  }

  return size <= maxSizeMB * 1024 * 1024;
}
