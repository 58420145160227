import React from 'react';
import { IconButton } from '@radix-ui/themes';
import { TrashIcon } from '@radix-ui/react-icons';
import { AlertDialog } from 'src/components/AlertDialog';

type RemoveImageProps = {
  id: string;
  onRemove: (id: string) => void;
};

export function RemoveImage({ id, onRemove }: RemoveImageProps) {
  const [isOpen, setIsOpen] = React.useState(false);

  return (
    <>
      <IconButton
        variant="ghost"
        color="tomato"
        title="Remove item"
        onClick={() => setIsOpen(true)}
      >
        <TrashIcon />
      </IconButton>
      <AlertDialog
        title="Remove image"
        onAction={() => onRemove(id)}
        onCancel={() => setIsOpen(false)}
        open={isOpen}
        color="tomato"
        actionText="Yes, remove image"
        cancelText="No, keep it"
      >
        Are you sure you want to remove this image?
      </AlertDialog>
    </>
  );
}
