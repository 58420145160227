import React from 'react';
import { useForm } from 'react-hook-form';
import { Page__Content, Page__Header } from 'src/components/Page';
import { ErrorApi } from 'src/components/ErrorApi';
import * as Sentry from '@sentry/react';
import { appRoutes } from 'src/utils/routePaths';
import { Navigate } from 'react-router-dom';
import { useInstanceChanges } from 'src/hooks/api/useInstanceChanges';
import { Callout } from 'src/components/Callout';
import { ComboboxMultipleField } from 'src/components/ComboboxMultipleField';
import { Badge, Box, Flex, Text } from '@radix-ui/themes';
import { ChangesTable } from './components/ChangesTable';
import { useInstanceMoveAllChanges } from 'src/hooks/api/useInstanceMoveAllChanges';
import { filterTemplates } from './utils/filterTemplates';
import { useInstanceContext } from 'src/providers/InstanceProvider';
import { Toast } from 'src/components/Toast';
import { useWorkspacesList } from 'src/hooks/api/useWorkspacesList';
import { getInstanceById } from 'src/utils/getInstanceById';
import { getInstanceSourceAndDestination } from './utils/getInstanceSourceAndDestination';
import { DevType, StageType } from 'src/types/Workspace';
import {
  Change,
  MoveChangesArgs,
  useInstanceMovePartialChanges,
} from 'src/hooks/api/useInstanceMovePartialChanges';
import { SyncAllChangesButton } from './components/SyncAllChangesButton';
import { SyncPartialChangesButton } from './components/SyncPartialChangesButton';
import { INSTANCE } from 'src/constants/instance';
import { TableLoading } from 'src/components/TableLoading';

const PAGE_TITLE = 'Instance sync';

const FORM_VALUES = {
  ITEMS: 'items',
  IS_TEMPLATE_INCLUDED: 'isTemplateIncluded',
};

export function InstanceSync() {
  const { instanceId } = useInstanceContext();
  const [isMovingAllChangesAlertOpen, setIsMovingAllChangesAlertOpen] =
    React.useState(false);
  const [isMovingPartialChangesAlertOpen, setIsMovingPartialChangesAlertOpen] =
    React.useState(false);
  const {
    data: workspacesData,
    isLoading: isWorkspacesDataLoading,
    error: workspacesDataError,
  } = useWorkspacesList();
  const {
    data: instanceData,
    isLoading: isInstanceDataLoading,
    error: instanceDataError,
    refetch,
  } = useInstanceChanges();
  const {
    mutate: moveAllChanges,
    isMutating: isMovingAllChanges,
    error: moveAllChangesError,
  } = useInstanceMoveAllChanges({
    onSuccess: () => {
      setIsMovingAllChangesAlertOpen(false);
      refetch();
      Toast({
        text: 'Your changes have been successfully synced',
        variant: 'success',
      });
    },
  });
  const {
    mutate: movePartialChanges,
    isMutating: isMovingPartialChanges,
    error: movePartialChangesError,
  } = useInstanceMovePartialChanges({
    onSuccess: () => {
      setIsMovingPartialChangesAlertOpen(false);
      refetch();
      Toast({
        text: 'Your selected changes have been successfully synced',
        variant: 'success',
      });
    },
  });

  const methods = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  });

  const { control, watch } = methods;

  const handlePartialChangesSync = () => {
    const changes: MoveChangesArgs['changes'] = [];
    const formData = methods.getValues();
    instanceData?.templates.forEach((template) => {
      const formValueTemplate = formData[template.template_id];
      const isTemplateIncluded =
        formValueTemplate[FORM_VALUES.IS_TEMPLATE_INCLUDED];
      const items: number[] = [];

      template.items.forEach((item) => {
        if (formValueTemplate[FORM_VALUES.ITEMS][`id-${item.id}`]) {
          items.push(item.id);
        }
      });
      const change: Change = {
        template_id: template.template_id,
        is_update_template: isTemplateIncluded,
        items,
      };

      if (!change.is_update_template && !items.length) {
        return;
      }

      changes.push(change);
    });
    movePartialChanges({ sourceInstanceId: instanceId, changes });
  };

  if (isInstanceDataLoading || isWorkspacesDataLoading) {
    return (
      <>
        <Page__Header title={PAGE_TITLE} backButtonUrl="/" />

        <Page__Content>
          <TableLoading headers={['ID', 'Name']} rowsAmount={2} />
        </Page__Content>
      </>
    );
  }

  if (instanceDataError || workspacesDataError) {
    return (
      <>
        <Page__Header title={PAGE_TITLE} backButtonUrl="/" />

        <Page__Content>
          {!!instanceDataError && <ErrorApi error={instanceDataError} />}
          {!!workspacesDataError && <ErrorApi error={workspacesDataError} />}
        </Page__Content>
      </>
    );
  }

  if (!instanceData) {
    const sentryEventId = Sentry.captureException({
      error: `Instance data not found`,
    });
    return <Navigate to={appRoutes.error()} state={{ sentryEventId }} />;
  }

  if (!workspacesData) {
    const sentryEventId = Sentry.captureException({
      error: `Workspaces data not found`,
    });
    return <Navigate to={appRoutes.error()} state={{ sentryEventId }} />;
  }

  const activeInstanceData = getInstanceById({
    workspaces: workspacesData.workspaces || [],
    instanceId,
  });

  if (activeInstanceData?.env === INSTANCE.PROD) {
    return (
      <>
        <Page__Header title={PAGE_TITLE} backButtonUrl="/"></Page__Header>

        <Page__Content>
          <Box maxWidth="600px">
            <Callout color="gold">
              Instance sync functionality is&nbsp;not available for the
              production instance. <br /> Please use the development
              or&nbsp;stage instance for syncing data.
            </Callout>
          </Box>
        </Page__Content>
      </>
    );
  }

  if (!activeInstanceData) {
    const sentryEventId = Sentry.captureException({
      error: `Instance data not found`,
    });
    return <Navigate to={appRoutes.error()} state={{ sentryEventId }} />;
  }

  if (!instanceData.templates.length) {
    return (
      <>
        <Page__Header title={PAGE_TITLE} backButtonUrl="/"></Page__Header>

        <Page__Content>
          <Callout color="gold">
            There are no changes to sync at this time
          </Callout>
        </Page__Content>
      </>
    );
  }

  const options = instanceData.templates.map((item) => ({
    id: item.template_id,
    label: item.name,
  }));

  const filterValues = watch('filter');
  const filteredTemplates = filterTemplates({ instanceData, filterValues });

  const badgeData = getInstanceSourceAndDestination(
    activeInstanceData.env as DevType | StageType // Instance sync not available on Prod instance
  );

  return (
    <>
      <Page__Header
        backButtonUrl="/"
        title={
          <Flex gap="4" align="center">
            {PAGE_TITLE}
          </Flex>
        }
      >
        <Flex gap="2" align="center">
          <SyncPartialChangesButton
            onSubmit={handlePartialChangesSync}
            isLoading={isMovingPartialChanges}
            error={movePartialChangesError}
            isAlertOpen={isMovingPartialChangesAlertOpen}
            onAlertOpen={setIsMovingPartialChangesAlertOpen}
            methods={methods}
            templates={instanceData.templates}
          />

          <SyncAllChangesButton
            onSubmit={() => moveAllChanges({ sourceInstanceId: instanceId })}
            isLoading={isMovingAllChanges}
            error={moveAllChangesError}
            isAlertOpen={isMovingAllChangesAlertOpen}
            onAlertOpen={setIsMovingAllChangesAlertOpen}
          />
        </Flex>
      </Page__Header>

      <Page__Content>
        <Box mb="5">
          <Text size="3">
            Transfer changes from the active{' '}
            <Badge color={badgeData.source.color}>
              {badgeData.source.label}
            </Badge>{' '}
            instance to{' '}
            <Badge color={badgeData.destination.color}>
              {badgeData.destination.label}
            </Badge>
          </Text>
        </Box>

        {!!moveAllChangesError && (
          <Box my="4">
            <ErrorApi error={moveAllChangesError} />
          </Box>
        )}
        <Box maxWidth="400px">
          <ComboboxMultipleField
            control={control}
            name="filter"
            options={options}
            hideMeta={true}
            label="Search changes by template"
          />
        </Box>

        {filteredTemplates.map((template) => {
          const tableData = template.items.map((item) => ({
            id: item.id,
            name: item.name,
            checked: false,
          }));

          return (
            <Box key={template.template_id} my="6" maxWidth="800px">
              <ChangesTable
                methods={methods}
                templateId={template.template_id}
                templateName={template.name}
                isTemplateUpdated={template.is_changed}
                data={tableData}
              />
            </Box>
          );
        })}
      </Page__Content>
    </>
  );
}
