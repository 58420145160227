import React from 'react';
import { TreeNode } from 'src/types/Template';
import { ItemsTable } from './components/ItemsTable';
import { Page__Content, Page__Header } from 'src/components/Page';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import {
  Button,
  Flex,
  Box,
  IconButton,
  Skeleton,
  Popover,
} from '@radix-ui/themes';
import { CopyIcon } from '@radix-ui/react-icons';
import { useTemplateById } from 'src/hooks/api/useTemplateById';
import { useItemsList } from 'src/hooks/api/useItemsList';
import { ResponseItemField } from 'src/types/Item';
// import { TableList } from './components/TableList';
import { useInstanceContext } from 'src/providers/InstanceProvider';
import './index.css';
import { ErrorApi } from 'src/components/ErrorApi';
import { TableLoading } from 'src/components/TableLoading';
import { appRoutes } from 'src/utils/routePaths';
import { Access } from 'src/components/Access';
import { ROLES_LIST } from 'src/constants/workspaceRoles';
import { PERMISSIONS } from 'src/constants/permissions';
import { INSTANCE } from 'src/constants/instance';
import { AccessInstance } from 'src/components/AccessInstance';
import { Callout } from 'src/components/Callout';
import { isImageTemplate } from 'src/utils/isImageTemplate';
import {
  ItemsTableProvider,
  useItemsTableContext,
} from './providers/ItemsTableProvider';
import { useItemsLabels } from './hooks/useItemsLabels';
import { useTemplateData } from './hooks/useTemplateData';
import { useTemplateItemLinks } from './hooks/useTemplateItemLinks';

export type DataType = {
  id: string;
} & {
  internalLabel: string;
} & {
  [key: string]: {
    item: ResponseItemField[];
    node: TreeNode;
  };
};

function Management() {
  const { setTemplateId, filter, isFilterLoaded } = useItemsTableContext();
  const navigate = useNavigate();
  const { instanceId } = useInstanceContext();
  const params = useParams();
  const {
    data: template,
    isLoading: isTemplateLoading,
    error: templateError,
  } = useTemplateById({
    id: params?.templateId,
  });
  const itemFilters = filter.map((item) => ({
    template_field_id: item.fieldId,
    op: Number(item.condition),
    value: item.value.toString(),
  }));
  const {
    data: itemsList,
    isLoading: isItemsLoading,
    isFetching: isItemsFetching,
    error: itemsListError,
    refetch: refetchItems,
  } = useItemsList({
    templateId: template?.id,
    filters: itemFilters,
    options: { enabled: false },
  });
  const { templateItemLinksMap } = useTemplateItemLinks({ template });
  const { itemsLabelsMap, error: itemsLabelsByIdsError } = useItemsLabels({
    templateItemLinksMap,
    itemsList,
  });
  const { data, columns } = useTemplateData({
    template,
    itemsLabelsMap,
    itemsList,
    instanceId,
  });

  React.useEffect(() => {
    if (!template) {
      return;
    }
    setTemplateId(template.id);
  }, [setTemplateId, template]);

  React.useEffect(() => {
    if (!template || !isFilterLoaded) {
      return;
    }
    refetchItems(); // refetch on filter update
  }, [refetchItems, filter, template, isFilterLoaded]);

  const handleCopyTemplate = () => {
    const url = appRoutes.templateCopy({
      instanceId,
      templateId: params.templateId || '',
    });
    navigate(url);
  };

  if (templateError) {
    return (
      <>
        <Page__Header title="Items list" />

        <Page__Content>
          {templateError && <ErrorApi error={templateError} />}
        </Page__Content>
      </>
    );
  }

  if ((isTemplateLoading && !template) || (isItemsLoading && !itemsList)) {
    return (
      <>
        <Page__Header title={<Skeleton width="140px" height="20px" />} />
        <Page__Content>
          <TableLoading rowsAmount={3} headers={['', '', '']} />
        </Page__Content>
      </>
    );
  }

  return (
    <>
      <Page__Header
        title={template?.header?.label}
        dataTestId="items-list-title"
        headingSuffix={
          <IconButton
            style={{ display: 'none' }}
            color="gold"
            variant="ghost"
            title="Clone template"
            onClick={handleCopyTemplate}
          >
            <CopyIcon />
          </IconButton>
        }
      >
        <Flex gap="4" align="center" justify="end">
          <Access
            roles={ROLES_LIST}
            permissionsGroups={[[PERMISSIONS.CREATE_ITEM]]}
          >
            <AccessInstance allowedInstances={[INSTANCE.DEV]}>
              <Button variant="soft" color="plum" asChild>
                <RouterLink
                  to={appRoutes.itemNew({
                    instanceId,
                    templateId: params.templateId || '',
                  })}
                  data-testid="create-item"
                >
                  {isImageTemplate(template) ? 'Create image' : 'Create item'}
                </RouterLink>
              </Button>
            </AccessInstance>

            {isImageTemplate(template) && (
              <AccessInstance allowedInstances={[INSTANCE.DEV]}>
                <Button variant="soft" color="gold" asChild>
                  <RouterLink
                    to={appRoutes.itemImagesUpload({
                      instanceId,
                    })}
                  >
                    Bulk upload
                  </RouterLink>
                </Button>
              </AccessInstance>
            )}

            <AccessInstance allowedInstances={[INSTANCE.STAGE, INSTANCE.PROD]}>
              <Popover.Root>
                <Popover.Trigger>
                  <Button variant="soft" color="plum">
                    Create item
                  </Button>
                </Popover.Trigger>
                <Popover.Content>
                  <Box maxWidth="90vw">
                    <Callout color="tomato">
                      You can only create items in the development environment.
                      Here, you can review or manage existing items.
                    </Callout>
                  </Box>
                </Popover.Content>
              </Popover.Root>
            </AccessInstance>
          </Access>

          {!isImageTemplate(template) && (
            <Access roles={ROLES_LIST} ignorePermissions>
              {({ userPermissions }) => (
                <Button variant="soft" color="gold" asChild>
                  <RouterLink
                    to={appRoutes.templateEdit({
                      instanceId,
                      templateId: params.templateId || '',
                    })}
                  >
                    {!userPermissions.includes(PERMISSIONS.CREATE_TEMPLATE)
                      ? 'View template'
                      : 'Edit template'}
                  </RouterLink>
                </Button>
              )}
            </Access>
          )}
        </Flex>
      </Page__Header>

      <Page__Content hasScrollArea={false} mix="items-list__body">
        {!!itemsListError && (
          <Box mb="6">
            <ErrorApi error={itemsListError} />
          </Box>
        )}

        {!!itemsLabelsByIdsError && (
          <Box mb="6">
            <ErrorApi error={itemsLabelsByIdsError} />
          </Box>
        )}
        {!itemsListError && (
          <ItemsTable
            columns={columns}
            data={data}
            templateId={params.templateId || ''}
            isTableLoading={isItemsFetching}
          />
        )}
      </Page__Content>
    </>
  );
}

export default function TemplateManagement() {
  return (
    <ItemsTableProvider>
      <Management />
    </ItemsTableProvider>
  );
}
