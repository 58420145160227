import React from 'react';
import { ChevronDownIcon } from '@radix-ui/react-icons';
import {
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import b from 'bem-react-helper';
import { Table, DropdownMenu, Button, Flex, Skeleton } from '@radix-ui/themes';
import type { ColumnDef } from '@tanstack/react-table';
import type { DataType } from '../../index';
import { BooleanObject } from 'src/types/Common';
import {
  getHiddenColumns,
  saveHiddenColumns,
} from '../../utils/getColumnLocalStorage';
import { Filter } from '../Filter';
import { useInstanceContext } from 'src/providers/InstanceProvider';

type Column = {
  id: string;
  getCanHide: () => boolean;
  getIsVisible: () => boolean;
  toggleVisibility: (value: boolean) => void;
  columnDef: {
    header: React.ReactNode;
  };
};

type DataTableDemoProps = {
  data: DataType[];
  columns: ColumnDef<DataType>[];
  templateId: string;
  isTableLoading: boolean;
};

// https://dev.to/esponges/create-a-reusable-react-table-component-with-typescript-56d4
export function ItemsTable(props: DataTableDemoProps) {
  const { instanceId } = useInstanceContext();
  const { columns, data, templateId, isTableLoading } = props;
  const [columnVisibility, setColumnVisibility] = React.useState<BooleanObject>(
    {}
  );
  const [rowSelection, setRowSelection] = React.useState({});
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    onRowSelectionChange: setRowSelection,
    manualPagination: true,
    state: {
      columnVisibility,
      rowSelection,
    },
  });

  React.useEffect(() => {
    const hiddenColumns = getHiddenColumns(templateId, instanceId);

    const initialVisibility = columns.reduce(
      (acc: BooleanObject, column: ColumnDef<DataType>) => {
        // @ts-expect-error: will fix one day
        acc[column.accessorKey] = !hiddenColumns.includes(column.accessorKey);

        return acc;
      },
      {}
    );

    setColumnVisibility(initialVisibility);
  }, [columns, instanceId, templateId]);

  const handleVisibilityChange = (column: Column, value: boolean): void => {
    setColumnVisibility((prevVisibility) => {
      const newVisibility = {
        ...prevVisibility,
        [column.id]: value,
      };

      const hiddenColumns = Object.keys(newVisibility).filter(
        (key) => !newVisibility[key]
      );

      saveHiddenColumns(templateId, hiddenColumns, instanceId);

      return newVisibility;
    });

    column.toggleVisibility(value);
  };

  return (
    <div className="items-table">
      <Flex justify="between" mb="4">
        <Filter />
        <DropdownMenu.Root>
          <DropdownMenu.Trigger>
            <Button variant="outline" color="gray" className="ml-auto">
              Columns <ChevronDownIcon className="ml-2 h-4 w-4" />
            </Button>
          </DropdownMenu.Trigger>
          <DropdownMenu.Content align="end" variant="soft">
            {table
              .getAllColumns()
              .filter((column) => column.getCanHide())
              .map((column) => (
                <DropdownMenu.CheckboxItem
                  key={column.id}
                  className="capitalize"
                  checked={column.getIsVisible()}
                  onCheckedChange={(value) =>
                    // @ts-expect-error: will fix one day
                    handleVisibilityChange(column, value)
                  }
                  onSelect={(e) => e.preventDefault()} // prevent the dropdown menu from closing when selecting that item
                >
                  {column.columnDef.header as React.ReactNode}
                </DropdownMenu.CheckboxItem>
              ))}
          </DropdownMenu.Content>
        </DropdownMenu.Root>
      </Flex>

      <Table.Root className="items-table__root">
        <Table.Header>
          {table.getHeaderGroups().map((headerGroup) => (
            <Table.Row key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <Table.ColumnHeaderCell
                  key={header.id}
                  className={b(
                    header?.column?.columnDef?.meta?.className || '',
                    {
                      mix: 'items-table__header_sticky',
                    }
                  )}
                >
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                </Table.ColumnHeaderCell>
              ))}
            </Table.Row>
          ))}
        </Table.Header>
        <Table.Body>
          {table.getRowModel().rows?.length ? (
            <>
              {isTableLoading && (
                <Table.Row key="skeleton-row">
                  {table
                    .getRowModel()
                    .rows[0].getVisibleCells()
                    .map((cell, index) => (
                      <Table.Cell key={`skeleton-cell-${index}`}>
                        <Skeleton loading={true} width="80%" height="16px" />
                      </Table.Cell>
                    ))}
                </Table.Row>
              )}
              {table.getRowModel().rows.map((row) => (
                <Table.Row
                  key={row.id}
                  data-state={row.getIsSelected() && 'selected'}
                >
                  {row.getVisibleCells().map((cell) => (
                    <Table.Cell
                      key={cell.id}
                      className={cell?.column?.columnDef?.meta?.className}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </Table.Cell>
                  ))}
                </Table.Row>
              ))}
            </>
          ) : (
            <Table.Row>
              <Table.Cell colSpan={columns.length}>No results</Table.Cell>
            </Table.Row>
          )}
        </Table.Body>
      </Table.Root>
    </div>
  );
}
