import React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Box, Flex, Popover, Heading, Button } from '@radix-ui/themes';
import { PaperPlaneIcon } from '@radix-ui/react-icons';
import { TextArea } from 'src/components/TextArea';
import { Callout } from 'src/components/Callout';
import { Toast } from 'src/components/Toast';
import { useSendFeedback } from './hooks/useSendFeedback';
import { Input } from 'src/components/Input';

type FormData = {
  message: string;
  name?: string;
  email?: string;
};

const validationSchema = yup.object().shape({
  message: yup
    .string()
    .required('Required field')
    .trim()
    .min(3, 'Must be at least 3 characters'),
  name: yup.string(),
  email: yup.string().email(),
});

export function SendFeedbackPopup() {
  const [isPopoverOpen, setIsPopoverOpen] = React.useState(false);
  const { sendFeedback, isLoading, error } = useSendFeedback({
    onSuccess: () => {
      setIsPopoverOpen(false);
      Toast({
        text: 'Thank you for your feedback!',
        variant: 'success',
      });
    },
  });
  const methods = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  });

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = methods;

  const handleFeedback: SubmitHandler<FormData> = async (
    data: FormData,
    e?: React.BaseSyntheticEvent
  ) => {
    e?.preventDefault();
    sendFeedback({ message: data.message, name: data.name, email: data.email });
  };

  return (
    <>
      <Popover.Root open={isPopoverOpen} onOpenChange={setIsPopoverOpen}>
        <Popover.Trigger>
          <Button size="1" variant="soft" color="gold">
            <PaperPlaneIcon width="11px" /> Send feedback
          </Button>
        </Popover.Trigger>
        <Popover.Content
          style={{
            width: '90vw',
            maxWidth: '480px',
            background: 'var(mauve-1)',
          }}
        >
          <form onSubmit={handleSubmit(handleFeedback)}>
            <Box p="2">
              <Heading size="4" mb="3">
                We'd love to hear your feedback
              </Heading>

              {error && (
                <Box mb="2">
                  <Callout color="tomato">{error}</Callout>
                </Box>
              )}

              <Box mb="3">
                <Input
                  label="Your name"
                  size="2"
                  placeholder="Enter your name"
                  errorMessage={errors.name?.message}
                  {...register('name')}
                />
              </Box>

              <Box mb="3">
                <Input
                  label="Your email"
                  size="2"
                  placeholder="Enter your email"
                  errorMessage={errors.email?.message}
                  {...register('email')}
                />
              </Box>

              <Box mb="4">
                <TextArea
                  required
                  label="Your feedback"
                  size="2"
                  rows={18}
                  placeholder="Share your thoughts with us"
                  errorMessage={errors.message?.message}
                  {...register('message')}
                />
              </Box>
              <Flex justify="end">
                <Button
                  variant="soft"
                  color="plum"
                  type="submit"
                  loading={isLoading}
                >
                  Send feedback
                </Button>
              </Flex>
            </Box>
          </form>
        </Popover.Content>
      </Popover.Root>
    </>
  );
}
