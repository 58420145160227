import React from 'react';
import { AccessSubscription } from 'src/components/AccessSubscription';
import { PLANS } from 'src/constants/plans';
import { SUBSCRIPTION_STATUS } from 'src/constants/subscription';
import { Box } from '@radix-ui/themes';
import { MemberPricing } from './MemberPricing';
import { ProjectPricing } from './ProjectPricing';
import { SuccessfulPaymentCallbackArgs } from '../types';
import { Subscription } from 'src/types/Subscription';

type MembersAndProjectsSetupProps = {
  onSuccessfulPayment: SuccessfulPaymentCallbackArgs;
  selectedWorkspace: string;
  subscription?: Subscription;
};

export function MembersAndProjectsSetup({
  selectedWorkspace,
  onSuccessfulPayment,
  subscription,
}: MembersAndProjectsSetupProps) {
  if (!subscription || subscription?.type === PLANS.FREE) {
    return null;
  }

  return (
    <AccessSubscription
      plan={[PLANS.TEAMS, PLANS.STARTER]}
      allowedStatuses={[SUBSCRIPTION_STATUS.ACTIVE]}
      selectedWorkspaceId={selectedWorkspace}
    >
      <Box maxWidth="340px" width="100%">
        <MemberPricing
          onSuccessfulPayment={onSuccessfulPayment}
          workspaceId={selectedWorkspace}
          paidSeats={subscription?.members || 0}
          usedSeats={subscription?.used_members || 0}
          plan={subscription?.type}
        />
      </Box>

      <Box maxWidth="340px" width="100%">
        <ProjectPricing
          onSuccessfulPayment={onSuccessfulPayment}
          workspaceId={selectedWorkspace}
          paidProjects={subscription?.projects || 0}
          usedProjects={subscription?.used_projects || 0}
        />
      </Box>
    </AccessSubscription>
  );
}
