import { Filter } from 'src/types/Filter';

type GetKey = {
  instanceId: number;
  templateId: string;
};

function getKey({ instanceId, templateId }: GetKey) {
  return `filters_${instanceId}_${templateId}`;
}

type SaveFiltersToLocalStorage = {
  instanceId: number;
  templateId: string;
  filters: (Filter & { itemLabel: string })[];
};

export function saveFiltersToLocalStorage({
  instanceId,
  templateId,
  filters,
}: SaveFiltersToLocalStorage) {
  const key = getKey({ instanceId, templateId });

  localStorage.setItem(key, JSON.stringify(filters));
}

type GetFiltersFromLocalStorage = {
  instanceId: number;
  templateId: string;
};

export function getFiltersFromLocalStorage({
  instanceId,
  templateId,
}: GetFiltersFromLocalStorage) {
  const key = getKey({ instanceId, templateId });
  const filters = localStorage.getItem(key);

  return filters ? JSON.parse(filters) : [];
}
