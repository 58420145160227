import React from 'react';
import {
  Button as AriaButton,
  DropZone,
  FileTrigger,
} from 'react-aria-components';
import b from 'bem-react-helper';
import { Box, Link, Text } from '@radix-ui/themes';
import { ACCEPTED_FILE_TYPE, validateImage } from '../utils/validateImage';
import type { DropEvent, FileDropItem } from 'react-aria';
import { convertToBase64 } from '../utils/fileToBase64';
import { formatError } from 'src/utils/formatError';

const removeExtension = (fileName: string): string => {
  return fileName.substring(0, fileName.lastIndexOf('.')) || fileName;
};

export type ErrorsMapType = Map<string, string[]>;
export type ImageType = {
  src: string;
  name: string;
};

type ImageDropZoneProps = {
  maxSizeMB: number;
  invalid: boolean;
  onChange: (value: ImageType[]) => void;
  onError: (errorsMap: ErrorsMapType) => void;
  multiple?: boolean;
};

export function ImageDropZone({
  maxSizeMB,
  invalid,
  onChange,
  onError,
  multiple,
}: ImageDropZoneProps) {
  const handleImage = async (file: File | FileDropItem) => {
    try {
      const errorsList = await validateImage(file, maxSizeMB);
      if (errorsList.length) {
        throw errorsList;
      }
      const base64 = await convertToBase64(file);
      return {
        src: base64,
        name: removeExtension(file?.name || ''),
      };
    } catch (error) {
      if (Array.isArray(error)) {
        throw error;
      }
      const formattedError = formatError(error);
      throw formattedError;
    }
  };

  const handleImages = async (files: (File | FileDropItem)[]) => {
    const images = multiple ? files : [files[0]];
    const validImages: ImageType[] = [];
    const errors: ErrorsMapType = new Map();

    for (const image of images) {
      try {
        const base64 = await handleImage(image);
        validImages.push(base64);
      } catch (error) {
        if (Array.isArray(error)) {
          errors.set(image.name, error);
        } else {
          errors.set(image.name, [formatError(error)]);
        }
      }
    }

    onChange(validImages);
    onError(errors);
  };

  const handleDrop = async (e: DropEvent) => {
    const files = e.items.filter((file) => {
      return file.kind === 'file';
    }) as FileDropItem[];

    handleImages(files);
  };

  const handleSelect = async (filesList: FileList | null) => {
    if (!filesList) {
      return;
    }

    const files = Array.from(filesList);
    handleImages(files);
  };
  return (
    <DropZone onDrop={handleDrop} className="image-upload__drop-zone">
      {({ isDropTarget }) => (
        <div
          className={b(
            'image-upload__body',
            {},
            { hovered: isDropTarget, invalid }
          )}
        >
          {isDropTarget && <Text color="plum">Drop it</Text>}

          {!isDropTarget && (
            <>
              <Box>
                <Text color="gray">
                  Drop and drop {multiple ? 'images' : 'image'} here or{' '}
                </Text>
                <FileTrigger
                  allowsMultiple={multiple}
                  onSelect={handleSelect}
                  acceptedFileTypes={ACCEPTED_FILE_TYPE}
                >
                  <Link color="plum" asChild highContrast>
                    <AriaButton>Click to browse</AriaButton>
                  </Link>
                </FileTrigger>
              </Box>
              <Box mt="2">
                <Text color="gray" size="2">
                  Max file size {maxSizeMB}MB
                </Text>
              </Box>
            </>
          )}
        </div>
      )}
    </DropZone>
  );
}
