import React from 'react';
import { Box, IconButton } from '@radix-ui/themes';
import { ErrorApi } from 'src/components/ErrorApi';
import { AlertDialog } from 'src/components/AlertDialog';
import { UpdateIcon } from '@radix-ui/react-icons';
import { useApiTokenUpdate } from 'src/hooks/api/useApiTokenUpdate';
import { useInstanceContext } from 'src/providers/InstanceProvider';
import { useApiToken } from 'src/hooks/api/useApiToken';
import { Toast } from 'src/components/Toast';

export function RegenerateToken() {
  const { instanceId } = useInstanceContext();
  const [isDialogOpen, setIsDialogOpen] = React.useState(false);
  const { refetch } = useApiToken();
  const {
    mutate: updateToken,
    isMutating: isTokenUpdating,
    error: updateTokenError,
  } = useApiTokenUpdate({
    onSuccess: () => {
      refetch();
      setIsDialogOpen(false);
      Toast({
        variant: 'success',
        text: 'Token updated successfully',
      });
    },
  });

  return (
    <>
      <AlertDialog
        title="Confirm token update"
        onAction={() => updateToken({ instanceId })}
        onCancel={() => setIsDialogOpen(false)}
        open={isDialogOpen}
        color="tomato"
        actionText="Yes, regenerate token"
        cancelText="No, keep current token"
        loading={isTokenUpdating}
        trigger={
          <IconButton
            size="2"
            variant="ghost"
            color="gold"
            title="Regenerate token"
            onClick={() => setIsDialogOpen(true)}
          >
            <UpdateIcon height="16" width="16" />
          </IconButton>
        }
      >
        {!!updateTokenError && (
          <Box mb="4">
            <ErrorApi error={updateTokenError} />
          </Box>
        )}
        Updating the token might affect any services using it.
        <Box mt="2">Do you want to continue?</Box>
      </AlertDialog>
    </>
  );
}
