import React from 'react';
import { FREE_FEATURES, PLANS } from 'src/constants/plans';
import { PlanCard } from './PlanCard';
import { Subscription } from 'src/types/Subscription';

type FreePlanBasicCardProps = {
  subscription?: Subscription;
  onPlanSelect?: () => void;
  isLoading?: boolean;
};

export function FreePlanBasicCard({
  subscription,
  onPlanSelect,
  isLoading,
}: FreePlanBasicCardProps) {
  return (
    <PlanCard
      plan={PLANS.FREE}
      subscription={subscription}
      features={FREE_FEATURES}
      featuresDescription="Core features:"
      name="Free plan"
      description="Optimal for solo developers starting out"
      price="Free"
      onPlanSelect={onPlanSelect}
      isLoading={isLoading}
      hideTaxInfo={true}
      hideProjectPrice={true}
    />
  );
}
