import React, { createContext, useContext, useState } from 'react';
import { TreeNode } from 'src/types/Template';
import { Filter } from 'src/types/Filter';
import { FieldType, getFieldsList } from '../utils/getFieldsList';
import {
  getFiltersFromLocalStorage,
  saveFiltersToLocalStorage,
} from './localStorageFilters';
import { useInstanceContext } from 'src/providers/InstanceProvider';

type ItemsTableContextProps = {
  templateId: string;
  setTemplateId: (value: string) => void;
  templateTree: TreeNode[];
  setTemplateTree: (data: TreeNode[]) => void;
  filter: Filter[];
  setFilter: (data: Filter[]) => void;
  templateFields: FieldType[];
  itemLabelsMap: Map<string, string>;
  isFilterLoaded: boolean;
};

const ItemsTableContext = createContext<ItemsTableContextProps>({
  templateId: '',
  setTemplateId: () => {},
  templateTree: [],
  setTemplateTree: () => {},
  filter: [],
  setFilter: () => {},
  templateFields: [],
  itemLabelsMap: new Map(),
  isFilterLoaded: false,
});

type ItemsTableProviderProps = {
  children: React.ReactNode;
};
export const ItemsTableProvider = ({ children }: ItemsTableProviderProps) => {
  const { instanceId } = useInstanceContext();
  const [templateId, setTemplateId] = React.useState('');
  const [templateTree, setTemplateTree] = useState<TreeNode[]>([]);
  const [filter, setFilter] = useState<Filter[]>([]);
  const [isFilterLoaded, setIsFilterLoaded] = React.useState(false);

  const itemLabelsMap = React.useRef<Map<string, string>>(new Map());
  const templateFields = React.useMemo(
    () => getFieldsList({ tree: templateTree }),
    [templateTree]
  );

  const handleFilterSet = React.useCallback(
    (filter: Filter[]) => {
      setFilter(filter);
      const filterWithLabels = filter.map((item) => ({
        ...item,
        itemLabel: itemLabelsMap.current.get(item.internalId) || '',
      }));
      saveFiltersToLocalStorage({
        templateId,
        filters: filterWithLabels,
        instanceId,
      });
    },
    [templateId, instanceId]
  );

  const handleTemplateIdSet = React.useCallback(
    (value: string) => {
      if (templateId) {
        return;
      }
      const defaultFilter = getFiltersFromLocalStorage({
        templateId: value,
        instanceId,
      });
      setFilter(defaultFilter);
      setTemplateId(value);
      setIsFilterLoaded(true);
    },
    [instanceId, templateId]
  );

  return (
    <ItemsTableContext.Provider
      value={{
        templateId,
        setTemplateId: handleTemplateIdSet,
        templateTree,
        setTemplateTree,
        filter,
        setFilter: handleFilterSet,
        templateFields,
        itemLabelsMap: itemLabelsMap.current,
        isFilterLoaded,
      }}
    >
      {children}
    </ItemsTableContext.Provider>
  );
};

export const useItemsTableContext = () => {
  const context = useContext(ItemsTableContext);
  if (!context) {
    throw new Error(
      'useItemsTableContext must be used within a ItemsTableContext'
    );
  }
  return context;
};
