import { SubscriptionState } from './getSubscriptionState';
import { PLANS, PlanType } from 'src/constants/plans';

type GetButtonTextArgs = {
  subscriptionState: SubscriptionState;
  activePlan?: PlanType;
  plan: PlanType;
};

export const getPlanCardButtonText = ({
  subscriptionState,
  activePlan,
  plan,
}: GetButtonTextArgs) => {
  const isPlanSelected = activePlan === plan;

  if (
    isPlanSelected &&
    (subscriptionState.active ||
      subscriptionState.cancelRequested ||
      subscriptionState.pastDue)
  ) {
    return 'Current plan';
  }

  if (subscriptionState.active) {
    if (activePlan === PLANS.TEAMS) {
      return 'Downgrade now';
    }
    if (activePlan === PLANS.STARTER) {
      return plan === PLANS.TEAMS ? 'Upgrade now' : 'Downgrade now';
    }
  }

  return 'Select plan';
};
