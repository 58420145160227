import React from 'react';
import { Navigate, NavLink as RouterNavLink } from 'react-router-dom';
import { PlanType } from 'src/constants/plans';
import { appRoutes } from 'src/utils/routePaths';
import { useActiveSubscription } from 'src/hooks/useActiveSubscription';
import { Badge, Link } from '@radix-ui/themes';
import { hasSubscription } from 'src/utils/hasSubscription';

type AccessSubscriptionProps = {
  plan?: PlanType[];
  children: React.ReactNode;
  redirectIfFailed?: boolean;
  allowedStatuses?: string[];
  selectedWorkspaceId?: string;
  showUpgradeBadge?: boolean;
  badgeText?: string;
};

export function AccessSubscription({
  plan,
  children,
  redirectIfFailed,
  allowedStatuses = [],
  selectedWorkspaceId,
  showUpgradeBadge,
  badgeText,
}: AccessSubscriptionProps) {
  const { subscription, isLoading } = useActiveSubscription({
    selectedWorkspaceId,
  });

  if (isLoading) {
    return null;
  }

  if (!subscription) {
    return null;
  }

  if (hasSubscription({ plan, allowedStatuses, subscription })) {
    return <>{children}</>;
  }

  if (redirectIfFailed && subscription) {
    return <Navigate to={appRoutes.accessDenied()} />;
  }

  if (showUpgradeBadge && badgeText) {
    return <UpgradeBadge text={badgeText} />;
  }

  return null;
}

type UpgradeBadgeProps = {
  text: string;
};

function UpgradeBadge({ text }: UpgradeBadgeProps) {
  return (
    <Badge color="blue" size="2">
      <Link asChild color="blue">
        <RouterNavLink to={appRoutes.settingsBilling()}>Upgrade</RouterNavLink>
      </Link>{' '}
      {text}
    </Badge>
  );
}
