import React from 'react';
import { TrashIcon } from '@radix-ui/react-icons';
import { Code, Button } from '@radix-ui/themes';
import { AlertDialog } from 'src/components/AlertDialog';
import { TreeNode } from 'src/types/Template';
import { removeNodeAtPath } from '@nosferatu500/react-sortable-tree';
import { getNodeKey } from 'src/utils/getNodeKey';
import { useTemplateTreeContext } from 'src/providers/TemplateTreeProvider';
import { Toast } from 'src/components/Toast';
import * as Sentry from '@sentry/react';

export function RemoveField() {
  const {
    templateTree,
    templateTreeActiveField,
    setTemplateTree,
    setTemplateTreeActiveField,
  } = useTemplateTreeContext();
  const [isDialogOpen, setIsDialogOpen] = React.useState(false);
  const [error, setError] = React.useState('');

  const removeField = () => {
    if (!templateTreeActiveField) {
      return;
    }
    const { path } = templateTreeActiveField;
    let updatedTree: TreeNode[] = [];

    try {
      updatedTree = removeNodeAtPath({
        treeData: templateTree,
        // @ts-expect-error: library error path type depends on getNodeKey, in our case it's string[]
        path,
        getNodeKey,
        ignoreCollapsed: false,
      }) as TreeNode[];
      setTemplateTree(updatedTree);
      setIsDialogOpen(false);
      setTemplateTreeActiveField(null);
      setError('');
      Toast({
        text: `Field removed`,
        variant: 'success',
      });
    } catch (err) {
      const sentryEventId = Sentry.captureException({
        error: 'Cannot remove field from settings',
        activeField: JSON.stringify(templateTreeActiveField),
        templateTree: JSON.stringify(templateTree),
      });
      setError(sentryEventId);
    }
  };

  const closeErrorDialog = () => {
    setIsDialogOpen(false);
    setError('');
  };

  if (error) {
    return (
      <AlertDialog
        title="Error removing field"
        onAction={closeErrorDialog}
        onCancel={closeErrorDialog}
        open={true}
        color="tomato"
        actionText="Ok"
      >
        An error occurred while attempting to remove the field.
        <br />
        Please try again later. If the problem persists, reach out to our
        support team for assistance with error id:{' '}
        <Code color="tomato">{error}</Code>.
      </AlertDialog>
    );
  }

  return (
    <AlertDialog
      onAction={removeField}
      onCancel={() => setIsDialogOpen(false)}
      open={isDialogOpen}
      color="tomato"
      actionText="Yes, remove field"
      trigger={
        <Button
          color="gray"
          variant="ghost"
          onClick={() => setIsDialogOpen(true)}
        >
          <TrashIcon /> Remove field
        </Button>
      }
    >
      Are you sure you want to remove this field?
    </AlertDialog>
  );
}
