import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Input } from 'src/components/Input';
import { FilterFieldType } from 'src/types/Filter';
import { FIELD_TYPE } from 'src/constants/components';
import { FormData } from '../types';
import { ItemsCombobox } from 'src/fields/components/ItemsCombobox';
import { useItemsTableContext } from '../../../providers/ItemsTableProvider';
import { SelectField } from 'src/components/SelectField';

type ValueFieldProps = {
  templateId: string;
  fieldType: FilterFieldType;
};

export function ValueField({ templateId, fieldType }: ValueFieldProps) {
  const { itemLabelsMap } = useItemsTableContext();
  const methods = useFormContext<FormData>();
  const {
    register,
    formState: { errors },
    control,
  } = methods;

  const handleItemSelect = (data: { id: string; label: string }) => {
    itemLabelsMap.set(data.id, data.label);
  };

  const name = 'value';
  const label = 'Value';
  const placeholder = 'Enter value';

  if (
    fieldType === FIELD_TYPE.ITEM_LINK ||
    fieldType === FIELD_TYPE.ITEM_LINK_LIST
  ) {
    return (
      <ItemsCombobox
        id="value"
        label={label}
        // @ts-expect-error: fix later
        control={control}
        errorMessage={errors[name]?.message}
        templateId={templateId}
        onSelect={handleItemSelect}
      />
    );
  }

  if (fieldType === FIELD_TYPE.BOOLEAN) {
    const booleanOptions = [
      {
        id: 'true',
        label: 'True',
      },
      {
        id: 'false',
        label: 'False',
      },
    ];

    return (
      <Controller
        control={control}
        name={name}
        render={({ field: { value, onChange } }) => {
          return (
            <SelectField
              errorMessage={errors[name]?.message}
              options={booleanOptions}
              value={value}
              onChange={onChange}
              label={label}
              placeholder={placeholder}
            />
          );
        }}
      />
    );
  }

  return (
    <Input
      mods={{ type: 'primary' }}
      label={label}
      placeholder={placeholder}
      errorMessage={errors[name]?.message}
      {...register(name)}
    />
  );
}
