import React from 'react';
import { Box, Card, Flex, Grid, Text } from '@radix-ui/themes';
import { CopyCode } from 'src/components/CopyCode';

type CurrentInstanceCardProps = {
  instanceId: number;
};

export function CurrentInstanceCard({ instanceId }: CurrentInstanceCardProps) {
  return (
    <Card variant="surface" size="2">
      <Box>
        <Flex align="center" gap="2">
          <Flex align="center" gap="2">
            <Text size="3" weight="medium">
              Current instance
            </Text>
          </Flex>
        </Flex>
        <Box mt="2">
          <Grid gap="2" align="center" columns="auto 1fr">
            <Text>ID</Text>
            <CopyCode code={instanceId.toString()} language={'JavaScript'} />
          </Grid>
        </Box>
      </Box>
    </Card>
  );
}
