import React from 'react';
import { Logo } from 'src/components/Logo';
import b, { type Mods, type Mix } from 'bem-react-helper';
import { IconButton, Flex, Skeleton } from '@radix-ui/themes';
import { SunIcon, MoonIcon } from '@radix-ui/react-icons';
import { useTheme } from 'src/providers/ThemeProvider';
import { InstanceActivatePopup } from './InstanceActivatePopup';
import { useInstanceContext } from 'src/providers/InstanceProvider';
import { useWorkspacesList } from 'src/hooks/api/useWorkspacesList';
import { getInstanceById } from 'src/utils/getInstanceById';
import { Access } from 'src/components/Access';
import { ROLES_LIST } from 'src/constants/workspaceRoles';
import { PERMISSIONS } from 'src/constants/permissions';
import { hasProjects } from 'src/utils/hasProjects';
import { getProjectByInstanceId } from 'src/utils/getProjectByInstanceId';
import { ExportItems } from 'src/components/ExportItems/';
import { Nav__SubscriptionInfo } from './components/Nav__SubscriptionInfo';

type HeaderProps = {
  mods?: Mods;
  mix?: Mix;
  children?: React.ReactNode;
  loading?: boolean;
};

export function Nav(props: HeaderProps) {
  const { instanceId } = useInstanceContext();
  const {
    data: workspacesData,
    error: workspacesDataError,
    isLoading: isWorkspacesDataLoading,
  } = useWorkspacesList();
  const { theme, setTheme } = useTheme();

  const activeInstanceData = getInstanceById({
    workspaces: workspacesData?.workspaces || [],
    instanceId: instanceId,
  });

  const activeProjectData = getProjectByInstanceId({
    workspaces: workspacesData?.workspaces || [],
    instanceId,
  });

  if (workspacesDataError) {
    return null;
  }

  if (props.loading || isWorkspacesDataLoading) {
    return (
      <nav className={b('nav', props)}>
        <header className="nav__header">
          <Logo />
        </header>

        <ul className="nav__list">
          {new Array(3).fill(0).map((_, i) => (
            <li key={i}>
              <Skeleton width="259px" height="32px" mb="2" />
            </li>
          ))}
        </ul>
      </nav>
    );
  }

  return (
    <nav className={b('nav', props)}>
      <header className="nav__header">
        <Logo />

        {workspacesData && hasProjects(workspacesData?.workspaces) && (
          <InstanceActivatePopup
            workspacesData={workspacesData}
            activeInstanceData={activeInstanceData}
            activeProjectId={activeProjectData?.id}
          />
        )}
      </header>

      {props.children}

      <footer className="nav__footer">
        <Nav__SubscriptionInfo />

        <Flex align="center" justify="between">
          <Flex gap="5" align="center">
            {theme === 'dark' && (
              <IconButton
                variant="ghost"
                color="gold"
                title="Toggle theme"
                radius="full"
                onClick={() => setTheme('light')}
              >
                <SunIcon />
              </IconButton>
            )}

            {theme === 'light' && (
              <IconButton
                variant="ghost"
                color="gold"
                title="Toggle theme"
                radius="full"
                onClick={() => setTheme('dark')}
              >
                <MoonIcon />
              </IconButton>
            )}
          </Flex>

          {!!instanceId && (
            <Access
              roles={ROLES_LIST}
              permissionsGroups={[[PERMISSIONS.EXPORT]]}
            >
              <ExportItems />
            </Access>
          )}
        </Flex>
      </footer>
    </nav>
  );
}
