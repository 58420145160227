import React from 'react';
import {
  Tabs,
  Tabs__Content,
  Tabs__Item,
  Tabs__List,
} from 'src/components/Tabs';
import { Box, Flex } from '@radix-ui/themes';
import { FieldsTable, FieldsTableType } from './FieldsTable';
import { TemplateField } from 'src/types/Template';
import { FIELD_TYPE, TEMPLATE_FIELD_STATUS } from 'src/constants/components';

const TAB_KEYS = {
  ADDED: 'added',
  UPDATED: 'updated',
  REMOVED: 'removed',
};

type FieldsReviewProps = {
  fields: TemplateField[];
};

export function FieldsReview({ fields }: FieldsReviewProps) {
  const getData = (fields: TemplateField[]) => {
    const added: FieldsTableType[] = [];
    const updated: FieldsTableType[] = [];
    const removed: FieldsTableType[] = [];

    fields.forEach((field) => {
      if (field.settings.type === FIELD_TYPE.DYNAMIC_GROUP_ROW) {
        return;
      }

      if (field.status === TEMPLATE_FIELD_STATUS.ADDED) {
        added.push({
          label: field.settings.label,
          name: field.settings.name,
          type: field.settings.type,
        });
      }

      if (field.status === TEMPLATE_FIELD_STATUS.REMOVED) {
        removed.push({
          label: field.settings.label,
          name: field.settings.name,
          type: field.settings.type,
        });
      }

      if (field.status === TEMPLATE_FIELD_STATUS.UPDATED) {
        updated.push({
          label: field.settings.label,
          name: field.settings.name,
          type: field.settings.type,
        });
      }
    });

    return { added, removed, updated };
  };

  const data = getData(fields);
  const defaultTabKey = (Object.keys(data) as Array<keyof typeof data>).find(
    (key) => !!data[key].length
  );

  return (
    <div className="report-table">
      <Tabs defaultValue={defaultTabKey}>
        <Flex>
          <Tabs__List>
            {!!data.removed.length && (
              <Tabs__Item value={TAB_KEYS.REMOVED}>Removed fields</Tabs__Item>
            )}

            {!!data.updated.length && (
              <Tabs__Item value={TAB_KEYS.UPDATED}>Updated fields</Tabs__Item>
            )}

            {!!data.added.length && (
              <Tabs__Item value={TAB_KEYS.ADDED}>Added fields</Tabs__Item>
            )}
          </Tabs__List>
        </Flex>

        <Box pt="3">
          {!!data.removed.length && (
            <Tabs__Content value={TAB_KEYS.REMOVED}>
              <FieldsTable data={data.removed} />
            </Tabs__Content>
          )}

          {!!data.updated.length && (
            <Tabs__Content value={TAB_KEYS.UPDATED}>
              <FieldsTable data={data.updated} />
            </Tabs__Content>
          )}

          {!!data.added.length && (
            <Tabs__Content value={TAB_KEYS.ADDED}>
              <FieldsTable data={data.added} />
            </Tabs__Content>
          )}
        </Box>
      </Tabs>
    </div>
  );
}
