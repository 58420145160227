import { ERROR_TYPE } from './validateImage';

export function getErrorsText({ maxSizeMB }: { maxSizeMB: number }) {
  const errors = {
    [ERROR_TYPE.SIZE]: `Please upload an image smaller than ${maxSizeMB}MB`,
    [ERROR_TYPE.EXTENSION]: 'Please upload a file of image type (JPG, PNG)',
  };

  return errors;
}
